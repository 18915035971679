import { TYPES } from "../actions/actionTypes";
import { CreneauxAction, CreneauxState, ICreneau } from "../Interfaces/typeCreneaux";

const initialState : CreneauxState = []

const creneauxReducer = (state : CreneauxState = initialState, action: CreneauxAction) => {
  switch (action.type) {
    case TYPES.CRENEAUX.GET:
      return action.creneaux 
    case TYPES.CRENEAUX.GET_CRENEAUX_FOR_USER:
      return action.creneaux 
    case TYPES.CRENEAUX.ADD:
      return { ...state, action }
    case TYPES.CRENEAUX.UPDATE:
      return state.map((creneau : ICreneau) => {
          if(action.creneaux.id === creneau.id ){
            return action.creneaux
          }
        return creneau
      })
    default:
      return state
  }
}

export default creneauxReducer