import { Alert, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DropZoneComponent from "../../components/dropzone_component/DropZoneComponent";

function ImportCsvLieu() {
	const { t } = useTranslation();
	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<h1 className="h1-title">Création de lieu par upload</h1>
			<div
				style={{
					background: "white",
					width: "800px",
					padding: "20px 30px",
					marginBottom: "20px",
					borderRadius: "10px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<p style={{ textAlign: "center" }}>
					{t("IMPORT_FILE.template_text")}
				</p>
				<Alert
					severity="warning"
					sx={{
						margin: "10px",
						width: "100%",
					}}
				>
					Attention, tous les champs sont obligatoires
				</Alert>
				<Button variant="contained">
					<Link
						to="/template_lieu_client.csv"
						target="_blank"
						download
						style={{ color: "inherit", textDecoration: "inherit" }}
					>
						{t("IMPORT_FILE.template_button")}
					</Link>
				</Button>
			</div>
			<DropZoneComponent type="lieu" />
		</div>
	);
}

export default ImportCsvLieu;
