import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import PlanningRdvByLieu from "../../components/planningPattern/PlanningRdvByLieu";
import StepperPattern from "../../components/Stepper/StepperPattern";
import { crenauxActions } from "../../redux/actions/creneauxActions";
import { lieuxActions } from "../../redux/actions/lieuxActions";
import { periodesInterventionActions } from "../../redux/actions/periodesInterventionsActions";
import { IRedux } from "../../redux/reducers";
import { calculDuree } from "../../services/CalculDebutFinJournee";

function SuperviseurClientRdvLieu() {
	const dispatch = useDispatch();
	const { getLieux } = bindActionCreators(lieuxActions, dispatch);
	const { getPeriodesInterventionByLieu } = bindActionCreators(
		periodesInterventionActions,
		dispatch
	);
	const currentClientID = useSelector(
		(state: IRedux) => state?.currentClient.id
	);

	useEffect(() => {
		getLieux(currentClientID);
	}, []);
	const lieux = useSelector((state: IRedux) => state.lieuxReduxState);

	const { getCreneauxByLieuAndRdvNotNull } = bindActionCreators(
		crenauxActions,
		dispatch
	);
	const periodeIntervention = useSelector(
		(state: IRedux) => state.periodesInterventionReduxState
	);

	//DATA ON CHANGE AUTOCOMPLETE
	const [dataOnChange, setDataOnChange] = React.useState<any>({});
	useEffect(() => {
		dataOnChange?.id_lieu &&
			getPeriodesInterventionByLieu(dataOnChange?.id_lieu);
		dataOnChange?.id_lieu &&
			dataOnChange?.id_periode &&
			getCreneauxByLieuAndRdvNotNull(dataOnChange);
	}, [dataOnChange]);

	const contenuSepper = {
		first: () => {
			return (
				<Autocomplete
					options={lieux}
					noOptionsText="Aucune option disponible"
					sx={{ width: 300 }}
					renderOption={(props, option: any) => {
						return (
							<li {...props} key={option.id}>
								{option.nom}
							</li>
						);
					}}
					getOptionLabel={(option) => option.nom}
					renderInput={(params) => {
						return (
							<TextField
								{...params}
								label="Choix du lieu"
								variant="standard"
							/>
						);
					}}
					onChange={(event: any, option: any) => {
						setDataOnChange({
							...dataOnChange,
							id_lieu: option?.id,
						});
					}}
				/>
			);
		},
		second: () => {
			return (
				<Autocomplete
					options={periodeIntervention}
					noOptionsText="Aucune option disponible"
					sx={{ width: 300 }}
					renderOption={(props, option: any) => {
						return (
							<li {...props} key={option.id}>
								{option?.lieu?.nom +
									" | commande - " +
									option?.commande?.numero +
									" - " +
									calculDuree(
										option?.typeIntervention?.duree
									)}
							</li>
						);
					}}
					getOptionLabel={(option) =>
						option?.lieu?.nom +
						" | commande - " +
						option?.commande?.numero +
						" - " +
						calculDuree(option?.typeIntervention?.duree)
					}
					renderInput={(params) => {
						return (
							<TextField
								{...params}
								label="Choix de la période d'intervention"
								variant="standard"
							/>
						);
					}}
					onChange={(event: any, option: any) => {
						setDataOnChange({
							...dataOnChange,
							id_periode: option?.id,
						});
					}}
				/>
			);
		},
		third: () => {
			return (
				<>
					<PlanningRdvByLieu />
				</>
			);
		},
	};

	return (
		<div className="container-main">
			<h1 className="h1-title">Liste des rendez-vous par lieu</h1>
			<div className="container-white">
				<StepperPattern
					steps={[
						"Choix du lieu",
						"Choix de la période d'intervention",
						"Planning des rendez-vous",
					]}
					render={contenuSepper}
					dataOnChange={dataOnChange}
				/>
			</div>
		</div>
	);
}

export default SuperviseurClientRdvLieu;
