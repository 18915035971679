import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Grid,
	Typography,
} from "@mui/material";
import React from "react";

interface ICardWithButton {
	data: any;
	buttons: {
		title: any;
		onClick: Function;
	}[];
}

function CardWithButton({ data, buttons }: ICardWithButton) {
	return (
		<>
			<Card sx={{ minWidth: 1000 }} className="card">
				<Grid container spacing={2} className="card-grid">
					<Grid xs={8}>
						<CardContent>
							<Typography>
								{data.nom ? data.nom : data.type}
							</Typography>
							{data.prenom && (
								<>
									<Typography>{data.prenom}</Typography>
									<Typography>{data.email}</Typography>
								</>
							)}
						</CardContent>
					</Grid>
					<Grid xs={4}>
						<CardActions>
							{buttons.map((button: any) => (
								<Button
									size="small"
									onClick={() => {
										button.onClick();
									}}
								>
									{button.title}
								</Button>
							))}
						</CardActions>
					</Grid>
				</Grid>
			</Card>
			<Divider />
		</>
	);
}

export default CardWithButton;
