import { TYPES } from "../actions/actionTypes";
import { ErrorAction, ErrorState } from "../Interfaces/typeError";
import { LoadingAction, LoadingState } from "../Interfaces/typeLoading";

const initialState : ErrorState = {
  error : "",
  status : 0
}

const errorReducer = (state : ErrorState = initialState, action: ErrorAction) => {
  switch (action.type) {
    case TYPES.ERROR.SET:
      return action.error
    case TYPES.ERROR.DELETE:
      return initialState
    default:
      return state
  }
}

export default errorReducer