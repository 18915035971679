import React from "react";

const drawerWidth = 280;

const widthScreen = window.screen.width;

function Footer() {
	let frontUrl = process.env.REACT_APP_URL_FRONT;

	return (
		<footer
			style={{
				zIndex: 10,
				bottom: "0",
				background: "lightgray",
				width: "100%",
				height: "5vh",
				padding: "0",
				paddingLeft:
					window.location.href === frontUrl + "connexion"
						? "0"
						: widthScreen < 600
						? "0"
						: `${drawerWidth}px`,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				position: "fixed",
			}}
		>
			REEL IT - contact@reelit.fr
		</footer>
	);
}

export default Footer;
