import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { TypeInterventionToSend } from "../redux/Interfaces/typeIntervention";

interface IModaleTypeInter {
  title: string;
  openModale: boolean;
  fonctionOpenModale: Function;
  typeInterIdSelectionne: number;
  listeTypeIntervention: any;
  addTypeIntervention: Function;
  editTypeIntervention: Function;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 20,
  p: 4,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
};

const inputStyle = {
  width: "100%",
  mb: 2,
};

function ModaleTypeInter({
  title,
  openModale,
  fonctionOpenModale,
  typeInterIdSelectionne,
  listeTypeIntervention,
  addTypeIntervention,
  editTypeIntervention,
}: IModaleTypeInter) {

  //Création d'un useRef pour éviter le first render du UseEffect au chargement de la page
  const [nomTypeInter, setnomTypeInter] = useState("");
  const [dureeInter, setdureeInter] = useState("");

  //Pour un edit, on va récupérer directement les données présentes pour le type intervention en question   
  useEffect(() => {
	if (openModale === true && typeInterIdSelectionne !== 0) {
		listeTypeIntervention?.forEach(
			(typeIntervention: any) => {
				if (typeIntervention.id === typeInterIdSelectionne) {
					setnomTypeInter(typeIntervention.type)
					setdureeInter(typeIntervention.duree)
				}
			}
	    );
    } else {
		setnomTypeInter("");
		setdureeInter("");
	}		

	}, [openModale]);


  const handlenomTypeInterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setnomTypeInter(event.target.value);
  };

  const handledureeInterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setdureeInter((event.target.value));
  };

  const handleConfirmerClick = () => {
	if (typeInterIdSelectionne !== 0) {
		let typeInter: TypeInterventionToSend = { duree: parseInt(dureeInter), type: nomTypeInter , id: typeInterIdSelectionne}
		editTypeIntervention(typeInter)
	} else {
		let typeInter: TypeInterventionToSend = { duree: parseInt(dureeInter), type: nomTypeInter }
		addTypeIntervention(typeInter)
	}
	fonctionOpenModale(false)
  };

  
  return (
    <div>
      <Modal
        open={openModale}
        onClose={() => fonctionOpenModale(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mb: 4}}>
            {title}
          </Typography>
          <TextField
            label="Nom du type d'intervention"
            value={nomTypeInter}
            onChange={handlenomTypeInterChange}
            sx={inputStyle}
          />
          <TextField
            label="Durée de l'intervention (en minutes)"
            value={dureeInter}
            onChange={handledureeInterChange}
            sx={inputStyle}
          />
		  <div>
		  	<Button
				sx={{ m: 2 }}
				variant="contained"
				onClick={() => fonctionOpenModale(false)}
			>
				Annuler
			</Button>
			<Button
				sx={{ m: 2 }}
				variant="contained"
				onClick={handleConfirmerClick}
			>
				Confirmer
			</Button>
		  </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ModaleTypeInter;
