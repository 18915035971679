import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IRedux } from "../../redux/reducers";
import { IRdv } from "../../redux/Interfaces/typeRdv";
import { ICreneau } from "../../redux/Interfaces/typeCreneaux";

function PlanningRdvByLieu() {
	const [plageHoraire, setPlageHoraire] = useState<any>({
		heureDebut: "06:00",
		heureFin: "18:00",
	});
	const [slotDuration, setSlotDuration] = useState<any>("00:30:00");

	//INIT
	const creneauxReduxState = useSelector(
		(state: IRedux) => state.creneauxReduxState
	);
	const initialEvents = creneauxReduxState?.map((creneau: ICreneau) => {
		let title = "";
		creneau?.rdvs?.forEach((rdv: IRdv) => {
			title =
				title + rdv.userClient.nom + " " + rdv.userClient.prenom + " ";
		});
		return {
			title: title,
			start: creneau.dateDebut,
			end: creneau.dateFin,
		};
	});

	//ON CLICK
	function handleEventClick(clickInfo: any) {
		console.log("click");
	}

	return (
		<>
			<div>
				<FullCalendar
					plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
					initialView="timeGridWeek"
					selectable={false}
					editable={false}
					weekends={false}
					height={1000}
					locale={"FR"}
					expandRows={true}
					allDaySlot={false}
					dayHeaderFormat={{
						week: "long",
						day: "numeric",
						month: "long",
					}}
					headerToolbar={{
						left: "",
						center: "title",
						right: "prev,next",
					}}
					buttonText={{
						prev: "Précédent",
						next: "Suivant",
					}}
					slotMinTime={plageHoraire.heureDebut}
					slotMaxTime={plageHoraire.heureFin}
					slotDuration={slotDuration}
					events={initialEvents}
					eventClick={handleEventClick}
				/>
			</div>
		</>
	);
}

export default PlanningRdvByLieu;
