import React from "react";
import { Line } from "react-chartjs-2";
import { Box, Card, CardContent, CardHeader, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { IRedux } from "../../redux/reducers";
import { toDateString } from "../../services/FormatDate";

function LineChartNbRdvPrisParJour() {
	const stat = useSelector(
		(state: IRedux) =>
			state.statistiquesState.tableaudeBord.nbRdvPrisParJour
	);

	const labels = stat?.map((s: any) => {
		return toDateString(s.date);
	});
	const dataStat = stat?.map((s: any) => {
		return s.nbRdv;
	});

	const data = {
		labels: labels?.reverse(),
		datasets: [
			{
				label: "Nombre de rdv",
				data: dataStat?.reverse(),
				fill: false,
				borderColor: "rgb(75, 192, 192)",
				tension: 0.1,
			},
		],
	};

	const options = {
		maintainAspectRatio: false,
		responsive: true,
		plugins: {
			legend: {
				position: "bottom" as const,
			},
		},
	};

	return (
		<Card sx={{ height: "100%" }}>
			<CardHeader title="Nombre de rendez-vous pris sur les 7 derniers jours" />
			<Divider />
			<CardContent>
				<Box
					sx={{
						height: 400,
						position: "relative",
					}}
				>
					<Line options={options} data={data} />
				</Box>
			</CardContent>
		</Card>
	);
}

export default LineChartNbRdvPrisParJour;
