import CustomAxios from "../../services/Interceptor/CustomAxios";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"
import { DispatchRole, RoleAction } from "../Interfaces/typeRole";

let baseUrl = process.env.REACT_APP_URL_BACK;
const getRoles = () => {
  return (dispatch: DispatchRole) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_ROLE)
      .then(function (response) {
        if (response.status === 200) {
          const action: RoleAction = {
            type: TYPES.ROLE.GET,
            role: response.data
          }
          dispatch(action)
        }
      })
  }
}


export const roleAction = {
  getRoles
} 