import { TYPES } from "../actions/actionTypes";
import { Client, ClientAction, ClientState } from "../Interfaces/typeClient";

const initialState: ClientState = []

const clientReducer = (
  state : ClientState = initialState,
  action: ClientAction
) => {
  switch (action.type) {
    case TYPES.CLIENT.GET:
      return action.client
  }
  return state
}

export default clientReducer
