import { Button } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { userActions } from "../../../redux/actions/userActions";
import TextfieldPattern from "../textfieldPattern/TextfieldPattern";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface IpasswordFormPattern {
	type: "activation" | "changement";
	id?: number;
}

interface IFormInput {
	password: string;
	confirmedPassword: string;
}

const defaultValues = {
	password: "",
	confirmedPassword: "",
};

function PasswordFormPattern({ type, id }: IpasswordFormPattern) {
	const params = useParams();
	const dispatch = useDispatch();
	const { activateUser, modifyMdp } = bindActionCreators(
		userActions,
		dispatch
	);

	const apiResponse = useSelector((state: any) => state.userReduxState);

	const YupSchema = yup.object().shape({
		password: yup
			.string()
			.required("Un mot de passe est requis !")
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				"Le mot de passe doit contenir 8 caractères minimum, une majuscule, une minuscule, un nombre et un caractère spécial"
			),
		confirmedPassword: yup
			.string()
			.required("Un mot de passe est requis !")
			.oneOf(
				[yup.ref("password")],
				"Les mots de passe ne sont pas identiques"
			),
	});

	const methods = useForm<IFormInput>({
		resolver: yupResolver(YupSchema),
		defaultValues: defaultValues,
	});

	const {
		reset,
		control,
		formState: { errors },
	} = methods;

	const [success, setSuccess] = React.useState(false);
	const onSubmit = (data: IFormInput) => {
		setSuccess(false);
		if (data.password === data.confirmedPassword) {
			if (type === "activation") {
				const infoToSend = {
					password: data.password,
					uuid: params.id,
				};
				activateUser(infoToSend);
				if (apiResponse?.action?.user === "Le user a bien été activé") {
					setSuccess(true);
				}
			}
			if (type === "changement") {
				const infoToSend = {
					password: data.password,
					id: id,
				};
				modifyMdp(infoToSend);
			}
		} else {
			setSuccess(false);
		}
	};

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className="form-container"
			>
				<TextfieldPattern
					type="password"
					name="password"
					control={control}
					label="Mot de passe"
					fullWidth={true}
					error={!!errors.password}
					helperText={errors.password && errors?.password?.message}
				/>
				<TextfieldPattern
					type="password"
					name="confirmedPassword"
					control={control}
					label="Confirmation du mot de passe"
					fullWidth={true}
					error={!!errors.confirmedPassword}
					helperText={
						errors.confirmedPassword &&
						errors?.confirmedPassword?.message
					}
				/>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						gap: "10px",
						marginTop: "10px",
					}}
				>
					<Button type="submit" variant={"contained"}>
						Envoyer
					</Button>
					<Button
						type="reset"
						onClick={() => {
							reset();
							setSuccess(false);
						}}
						variant={"outlined"}
					>
						Réinitialiser
					</Button>
				</div>
			</form>
			<p>NB. Le mot de passe doit contenir 8 caractères minimum, une majuscule, une minuscule, un nombre et un caractère spécial.</p>
		</FormProvider>
	);
}

export default PasswordFormPattern;
