import CustomAxios from "../../services/Interceptor/CustomAxios";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"
import { store } from "../../redux/store";

let baseUrl = process.env.REACT_APP_URL_BACK;


const get = (type: string) => {

  let url = ""

  switch (type) {
    case 'client':
      url = routesApi.GET_CLIENT
      break;
    case 'technicien':
      url = routesApi.GET_TECHNICIENS
      break;
    case 'user-com':
      url = routesApi.GET_USER_COM
      break;
    default:
      console.log("erreur");
  }

  return (dispatch: CrudDispatch) => {
    setTimeout(() => {
      //APPEL API
      CustomAxios.get(baseUrl + url).then((response) => {
        //SET ACTION
        const action: CrudAction = {
            // TODO : Changer User et mettre client
          type: TYPES.CRUD.GET,
          crud: response.data,
        }
        //DISPATCH ACTION
        dispatch(action)
      });
    }, 500)
  }
};

const searchUserClient = (search: string, id_client : number) => {

  return (dispatch: CrudDispatch) => {
    setTimeout(() => {
      //APPEL API
      CustomAxios.get(baseUrl + routesApi.SEARCH_USER_CLIENT + "?search=" + search + "&id_client=" + id_client).then((response) => {
        //SET ACTION
        const action: CrudAction = {
            // TODO : Changer User et mettre client
          type: TYPES.CRUD.GET,
          crud: response.data,
        }
        //DISPATCH ACTION
        dispatch(action)
      });
    }, 500)
  }
};


const add = (data: any, type : string) => {

  let url = ""
  switch (type) {
    case 'client':
      url = routesApi.UPDATE_CLIENT
      break;
    case 'technicien':
      url = routesApi.UPDATE_TECHNICIEN
      break;
    case 'user-com':
      url = routesApi.UPDATE_USERCOM
      break;
    default:
      console.log("erreur");
  }

    return (dispatch: CrudDispatch) => {
      setTimeout(() => {
        //APPEL API
        CustomAxios.put(baseUrl + url, data).then((response) => {
          if(!response.data.statusCode){
          //SET ACTION
          const action: CrudAction = {
            type: TYPES.CRUD.ADD,
            crud: response.data,
          }
          //DISPATCH ACTION
          dispatch(action)
          }

        });
      }, 500)
    }
};

const deleteOne = (id: number, type : string) => {
  let url = ""
  switch (type) {
    case 'client':
      url = routesApi.UPDATE_CLIENT
      break;
    case 'technicien':
      url = routesApi.UPDATE_TECHNICIEN
      break;
    case 'user-com':
      url = routesApi.UPDATE_USERCOM
      break;
    default:
      console.log("erreur");
  }

    return (dispatch: CrudDispatch) => {
      setTimeout(() => {
        //APPEL API
        //TODO Trouver une solution pour ajouter l'id du client en question
        CustomAxios.post(baseUrl + url + "/delete" + "?id=" +  id).then((response) => {
          //SET ACTION
          const action: CrudAction = {
            type: TYPES.CRUD.DELETE,
            crud: id,
          }
          //DISPATCH ACTION
          dispatch(action)
        });
      }, 500)
    }
};

const edit = (data: any, type : string) => {

  let url = ""
    
  switch (type) {
    case 'client':
      url = routesApi.UPDATE_CLIENT
      break;
    case 'technicien':
      url = routesApi.UPDATE_TECHNICIEN
      break;
    case 'user-com':
      url = routesApi.UPDATE_USERCOM
      break;
    case 'user-client':
      url = routesApi.UPDATE_USERCLIENT
      break;
    default:
      console.log("erreur");
  }

    return (dispatch: CrudDispatch) => {
      setTimeout(() => {
        //APPEL API
        CustomAxios.post(baseUrl + url, data).then((response) => {
          //SET ACTION
          const action: CrudAction = {
            type: TYPES.CRUD.UPDATE,
            crud: response.data,
          }
          //DISPATCH ACTION
          dispatch(action)
        });
        if(type === 'user-com' || 'user-client'){
          let user : any = store.getState().userReduxState;
          user.id === data.id && window.location.reload();
        }
      }, 500)
    }
};

const changeRoleClient = (data : any) => {

  return (dispatch: CrudDispatch) => {
    setTimeout(() => {
      //APPEL API
      CustomAxios.post(baseUrl + routesApi.USER_CLIENT, data).then((response) => {
        //SET ACTION
        const action: CrudAction = {
            // TODO : Changer User et mettre client
          type: TYPES.CRUD.UPDATE,
          crud: response.data,
        }
        //DISPATCH ACTION
        dispatch(action)
      });
    }, 500)
  }
};

export const CrudAction = {
  get,
  searchUserClient,
  add,
  deleteOne,
  edit,
  changeRoleClient
} 