import { TYPES } from "../actions/actionTypes";
import { LoadingAction, LoadingState } from "../Interfaces/typeLoading";

const initialState : LoadingState = false

const loadingReducer = (state : LoadingState = initialState, action: LoadingAction) => {
  switch (action.type) {
    case TYPES.LOADING:
      return action.loading
    default:
      return state
  }
}

export default loadingReducer