import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { ITypeMateriel, typeMaterielToSend } from "../redux/Interfaces/typeTypeMateriel";
import { IRedux } from "../redux/reducers";
import { useSelector } from "react-redux";

interface IModaleTypeMateriel {
  title: string;
  openModale: boolean;
  fonctionOpenModale: Function;
  typeMaterielIdSelectionne: number;
  addTypeMateriel: Function;
  editTypeMateriel: Function;
  idClient: number;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 20,
  p: 4,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
};

const inputStyle = {
  width: "100%",
  mb: 2,
};

function ModaleTypeMateriel({
  title,
  openModale,
  fonctionOpenModale,
  typeMaterielIdSelectionne,
  addTypeMateriel,
  editTypeMateriel,
  idClient,
}: IModaleTypeMateriel) {

  //Création d'un useRef pour éviter le first render du UseEffect au chargement de la page
  const [nomTypeMateriel, setnomTypeMateriel] = useState("");

  //Gestion des types d'intervention
  const typeMateriel: ITypeMateriel[] = useSelector(
    (state: IRedux) => state?.typeMaterielReduxState
  );

  //Pour un edit, on va récupérer directement les données présentes pour le type intervention en question   
  useEffect(() => {
    if (openModale === true && typeMaterielIdSelectionne !== 0) {
      typeMateriel?.forEach(
        (typeIntervention: any) => {
          if (typeIntervention.id === typeMaterielIdSelectionne) {
            setnomTypeMateriel(typeIntervention.type)
          }
        }
      );
    } else {
      setnomTypeMateriel("");
    }

  }, [openModale]);


  const handlenomTypeMaterielChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setnomTypeMateriel(event.target.value);
  };

  const handleConfirmerClick = () => {
    if (typeMaterielIdSelectionne !== 0) {
      let typeInter: typeMaterielToSend = { client: {id: idClient}, type: nomTypeMateriel, id: typeMaterielIdSelectionne }
      
      editTypeMateriel(typeInter)
    } else {
      let typeInter: typeMaterielToSend = { client: {id: idClient}, type: nomTypeMateriel }
      addTypeMateriel(typeInter)
    }
    fonctionOpenModale(false)
  };


  return (
    <div>
      <Modal
        open={openModale}
        onClose={() => fonctionOpenModale(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 4 }}>
            {title}
          </Typography>
          <TextField
            label="Nom du type de matériel"
            value={nomTypeMateriel}
            onChange={handlenomTypeMaterielChange}
            sx={inputStyle}
          />
          <div>
            <Button
              sx={{ m: 2 }}
              variant="contained"
              onClick={() => fonctionOpenModale(false)}
            >
              Annuler
            </Button>
            <Button
              sx={{ m: 2 }}
              variant="contained"
              onClick={handleConfirmerClick}
            >
              Confirmer
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ModaleTypeMateriel;
