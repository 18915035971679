import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Folder from "../../style/img/folder.png";
import FileIcon from "../../style/img/csv-file.png";
import DeleteIcon from "@mui/icons-material/Delete";
import Lottie from "lottie-react";
import onCompleteAnimation from "../../style/img/97240-success.json";
import "./DropZoneComponent.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { uploadFileActions } from "../../redux/actions/uploadFileActions";
import { bindActionCreators } from "redux";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../services/traduction/i18n";
import { IRedux } from "../../redux/reducers";

interface IDropZoneComponent {
	type: "client" | "lieu";
}

const DropZoneComponent = ({ type }: IDropZoneComponent) => {
	const [file, setFile] = useState({
		path: "",
		name: "",
		size: "",
		type: "",
	});

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { uploadFile } = bindActionCreators(uploadFileActions, dispatch);
	const clientId = useSelector((state: IRedux) => state?.currentClient.id);
	const errorFileReduxStore = useSelector(
		(state: any) => state?.uploadFileReduxState,
		shallowEqual
	);

	const navigate = useNavigate();
	const [errorFile, setErrorFile] = React.useState("");

	useEffect(() => {
		// setErrorFile(errorFileReduxStore)
		// // setErrorFile(errorFileReduxStore)
	}, [clientId, errorFile]);

	const [sentFileMessage, setSentFileMessage] = useState(false);

	const sizeInMb = (bytes: any): string => `${(bytes / 1000).toFixed(2)} Ko`;

	const onDrop = useCallback((acceptedFiles: any) => {
		setFile(acceptedFiles[0]);
	}, []);

	const { getRootProps, getInputProps, isDragAccept, isDragReject } =
		useDropzone({
			onDrop,
			multiple: false,
			accept: { "text/csv": [".csv"] },
		});

	const handleSubmit = (file: any) => {
		const formData: any = new FormData();
		formData.append("file", file);
		formData.append("id_client", Number(clientId));

		uploadFile(formData, type);
		removeFile(file);
		setSentFileMessage(true);
		downloadFile();
	};

	const removeFile = (file: any) => {
		setFile({
			path: "",
			name: "",
			size: "",
			type: "",
		});
	};
	// const csvFileData = [
	//     ['Alan Walker', 'Singer'],
	//     ['Cristiano Ronaldo', 'Footballer'],
	//     ['Saina Nehwal', 'Badminton Player'],
	//     ['Arijit Singh', 'Singer'],
	//     ['Terence Lewis', 'Dancer']
	// ];

	const downloadFile = () => {
		//Titre de chaque colonne du fichier
		let csv = "Type d'erreur,Utilisateur\n";
		//Mise au propre des erreurs reçues
		let errorFile = errorFileReduxStore.data;

		let newErrorFile =
			errorFileReduxStore?.data &&
			errorFileReduxStore?.data
				.replace("Erreur;N� interne;\r\n", "")
				.replaceAll(";", ",")
				.replaceAll(",\r", "");

		//Associations des erreurs avec les titres de colonne
		csv = csv.concat("", newErrorFile);

		//display the created CSV data on the web browser
		// document.write(csv);
		// const hiddenElement = document.createElement('a');
		// hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
		// hiddenElement.target = '_blank';

		setErrorFile("data:text/csv;charset=utf-8," + encodeURI(csv));
		//provide the name for the CSV file to be downloaded
		// hiddenElement.download = "Fichier d'erreurs.csv";
		// hiddenElement.click();
	};

	interface IBorderStyle {
		border: string;
	}
	const [borderDropZone, setBorderDropZone] = useState<IBorderStyle>({
		border: "dashed white",
	});

	useEffect(() => {
		isDragReject === true &&
			setBorderDropZone({
				border: "dashed red",
			});
		isDragAccept === true &&
			setBorderDropZone({
				border: "dashed green",
			});

		if (isDragAccept === false && isDragReject === false) {
			setBorderDropZone({
				border: "dashed white",
			});
		}
	}, [isDragAccept, isDragReject]);

	return (
		<div className="dropzone-component-container">
			<div className="dropzone-tool-container">
				<div
					className="drag-drop-zone"
					{...getRootProps()}
					style={borderDropZone}
				>
					<input {...getInputProps()} />
					{/* Dropzone */}
					<img
						src={Folder}
						style={{ width: "35px", margin: "10px" }}
					/>
					<p className="title-drag-drop">
						{t("IMPORT_FILE.DRAG_AND_DROP.first_description")}
					</p>
					{isDragReject ? (
						<p className="description-drag-drop">
							{t("IMPORT_FILE.DRAG_AND_DROP.error_description")}
						</p>
					) : (
						<p className="description-drag-drop">
							{t("IMPORT_FILE.DRAG_AND_DROP.second_description")}
						</p>
					)}
				</div>
				{/* Render file */}
				{!sentFileMessage ? (
					file?.name.length > 1 && (
						<div className="drag-drop-submit-container">
							<div className="drag-drop-file-element">
								<img src={FileIcon} style={{ width: "25px" }} />
								<span>
									{file?.name} - {sizeInMb(file?.size)}
								</span>
								<DeleteIcon
									onClick={() => removeFile(file)}
									sx={{
										cursor: "pointer",
										"&:hover": {
											opacity: "0.5",
										},
									}}
								/>
							</div>
							<div>
								<Button
									variant="contained"
									onClick={() => handleSubmit(file)}
								>
									{t("IMPORT_FILE.DRAG_AND_DROP.send_btn")}
								</Button>
							</div>
						</div>
					)
				) : (
					<div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								marginTop: "20px",
							}}
						>
							<p>{t("IMPORT_FILE.confirmation_message")}</p>
							<Lottie
								animationData={onCompleteAnimation}
								loop={false}
								style={{ width: 50 }}
							/>
						</div>
						{errorFileReduxStore?.data !==
							"Votre fichier a bien été uploader sans problème" && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
								}}
							>
								<p style={{ textAlign: "justify" }}>
									{t("IMPORT_FILE.error_message")}
								</p>
								<Button
									variant="outlined"
									color="error"
									onClick={downloadFile}
									href={errorFile}
									download="Fichier d'erreurs"
									startIcon={<FileDownloadIcon />}
								>
									{t("IMPORT_FILE.error_btn")}
								</Button>
							</div>
						)}
						{type === "client" && (
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									marginTop: "20px",
								}}
							>
								<p>
									{t("IMPORT_FILE.planning_creation_message")}
								</p>
								<Button
									variant="contained"
									onClick={() =>
										navigate("/admin/creation-rdv")
									}
									startIcon={<CalendarMonthIcon />}
								>
									{t("IMPORT_FILE.planning_creation_btn")}
								</Button>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default DropZoneComponent;
