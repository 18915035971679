import CustomAxios from "../../services/Interceptor/CustomAxios";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"
import { DispatchStatistiques, StatistiquesAction } from "../Interfaces/typeStatistiques";

let baseUrl = process.env.REACT_APP_URL_BACK;
const getStatistiques = (id_client : number) => {
  return (dispatch: DispatchStatistiques) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_STATISTIQUES + "?id_client=" + id_client)
      .then(function (response) {
        if (response.status === 200) {
          const action: StatistiquesAction = {
            type: TYPES.STAT.GET,
            statistiques: response.data
          }
          dispatch(action)
        }
      })
  }
}


const getStatistiquesPeriodeIntervention = (id_periode : number) => {
  return (dispatch: DispatchStatistiques) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_STATISTIQUES_PERIODE_INTER + "?id_periode=" + id_periode)
      .then(function (response) {
        if (response.status === 200) {
          const action: StatistiquesAction = {
            type: TYPES.STAT_PERIODE_INTERVENTION.GET,
            statistiques: response.data
          }
          dispatch(action)
        }
      })
  }
}


export const satistiquesAction = {
  getStatistiques,getStatistiquesPeriodeIntervention
} 