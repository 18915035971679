import { TYPES } from "../actions/actionTypes";
import { LoggedAction, LoggedState } from "../Interfaces/typeLogged";

const initialState : LoggedState = false

const loggedReducer = (state : LoggedState = initialState, action: LoggedAction) => {
  switch (action.type) {
    case TYPES.CONNEXION.LOGGED:
      return action.logged
    default:
      return state
  }
}

export default loggedReducer