import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import AppointmentCreationForm from "../components/AppointmentCreationForm";
import AddPlanningTech from "../pages/AddPlanningTech";
import Dashboard from "../pages/Dashboard";
import DetailPlanningPeriodeInter from "../pages/DetailPlanningPeriodeInter";
import ImportByFile from "../pages/ImportByFile";
import CrudPage from "../pages/CrudPage";
import ValiderPeriodeIntervention from "../pages/ValiderPeriodeIntervention";
import { IRedux } from "../redux/reducers";
import LiensInternes from "../services/LiensInternes";
import "./NavBarTableauDeBord.scss";
import { IUser } from "../redux/Interfaces/typeUser";
import ChoixClient from "../pages/ChoixClient";
import { currentClientAction } from "../redux/actions/CurrentClientActions";
import { bindActionCreators } from "redux";
import InformationsClient from "../pages/InformationsClient";
import RerchercheUserClient from "../pages/RerchercheUserClient";
import AddPeriodeRappel from "../pages/AddPeriodeRappel";
import AddTypeInter from "../pages/AddTypeInter";
import DetailsUserClient from "../pages/DetailsUserClient";

export default function LayoutPrivee() {
	const navigate = useNavigate();
	const logged: boolean = useSelector(
		(state: IRedux) => state?.loggedReduxState
	);
	const userSelected: IUser = useSelector(
		(state: IRedux) => state?.userReduxState
	);
	const role: string[] = useSelector(
		(state: IRedux) => state?.userReduxState.role
	);
	if (
		!role.includes("AdminComNetWork") &&
		!role.includes("ComNetWorkSupervisor") &&
		role.length > 0
	) {
		navigate(LiensInternes.CONNEXION);
	}

	const dispatch = useDispatch();
	const { addCurrentClient } = bindActionCreators(
		currentClientAction,
		dispatch
	);
	useEffect(() => {
		if (userSelected?.client?.length === 1) {
			addCurrentClient(userSelected.client[0]);
		}
	}, [userSelected]);

	return (
		<div>
			{logged && (
				<>
					<Routes>
						<Route
							path="/"
							element={
								<Navigate
									to={
										userSelected?.client?.length === 1
											? LiensInternes.ADMIN.BASE +
											  LiensInternes.ADMIN
													.TABLEAU_DE_BORD
											: LiensInternes.ADMIN.BASE +
											  LiensInternes.ADMIN.CHOIX_CLIENT
									}
								/>
							}
						/>

						{(role.includes("AdminComNetWork") ||
							role.includes("ComNetWorkSupervisor")) && (
							<>
								<Route
									path={LiensInternes.ADMIN.CHOIX_CLIENT}
									element={<ChoixClient />}
								/>
								<Route
									path={LiensInternes.ADMIN.TABLEAU_DE_BORD}
									element={<Dashboard />}
								/>
								<Route
									path={
										LiensInternes.ADMIN.INFORMATIONS_CLIENT
									}
									element={<InformationsClient />}
								/>
								<Route
									path={LiensInternes.ADMIN.TYPES_INTER}
									element={<AddTypeInter />}
								/>
								<Route
									path={LiensInternes.ADMIN.IMPORT_FICHIER}
									element={<ImportByFile />}
								/>
								<Route
									path={LiensInternes.ADMIN.CREATION_RDV}
									element={<AppointmentCreationForm />}
								/>
								<Route
									path={
										LiensInternes.ADMIN.ASSIGNATION_TECH +
										"/:id"
									}
									element={<AddPlanningTech />}
								/>
								<Route
									path={
										LiensInternes.ADMIN
											.VALIDATION_PERIODE_INTER
									}
								>
									<Route
										index
										element={<ValiderPeriodeIntervention />}
									/>
									<Route
										path=":id"
										element={<DetailPlanningPeriodeInter />}
									/>
									<Route
										path={
											":id" +
											LiensInternes.ADMIN.PERIODE_RAPPEL
										}
										element={<AddPeriodeRappel />}
									/>
								</Route>

								<Route
									path={
										LiensInternes.ADMIN
											.RECHERCHER_USER_CLIENT
									}
									element={<RerchercheUserClient />}
								/>

								<Route
									path={
										LiensInternes.ADMIN
											.RECHERCHER_USER_CLIENT +
										LiensInternes.ADMIN
											.DETAILS_USER_CLIENT +
										"/:id"
									}
									element={<DetailsUserClient />}
								/>
							</>
						)}
						{role.includes("AdminComNetWork") && (
							<>
								<Route
									path={LiensInternes.ADMIN.LISTE_CLIENT}
									element={
										<CrudPage
											type="client"
											titre="Liste des Clients"
										/>
									}
								/>
								<Route
									path={LiensInternes.ADMIN.USER_COM}
									element={
										<CrudPage
											type="user-com"
											titre="Liste des Superviseurs ComNetWork"
										/>
									}
								/>
								<Route
									path={LiensInternes.ADMIN.TECHNICIEN}
									element={
										<CrudPage
											type="technicien"
											titre="Liste des Techniciens"
										/>
									}
								/>
							</>
						)}
					</Routes>
				</>
			)}
		</div>
	);
}
