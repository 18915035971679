import CustomAxios from "../../services/Interceptor/CustomAxios";
import { DispatchType, CommandesAction } from "../Interfaces/typeCommandes";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"

let baseUrl = process.env.REACT_APP_URL_BACK;
const getCommandes = () => {
  return (dispatch: DispatchType) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_COMMANDES)
      .then(function (response) {
        if (response.status === 200) {
          const action: CommandesAction = {
            type: TYPES.COMMANDE.GET,
            commandes: response.data
          }
          dispatch(action)
        }
      })
  }
}
const getCommandesByClient = (id_client : number) => {
  return (dispatch: DispatchType) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_COMMANDES_CLIENT + "?id_client=" + id_client)
      .then(function (response) {
        if (response.status === 200) {
          const action: CommandesAction = {
            type: TYPES.COMMANDE.GET,
            commandes: response.data
          }
          dispatch(action)
        }
      })
  }
}

export const commandesActions = {
  getCommandes, getCommandesByClient
} 