import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import React from "react";

interface IRadioPatternProps {
	data: [
		{
			value: string;
			label: string;
		}
	];
	defaultValue: string;
	setData: {};
	labelData: string;
}

const RadioPattern: React.FC<IRadioPatternProps> = ({
	data,
	defaultValue,
	setData,
	labelData,
}) => {
	return (
		<FormControl>
			<RadioGroup
				row
				aria-labelledby="demo-row-radio-buttons-group-label"
				name="row-radio-buttons-group"
				defaultValue={defaultValue}
			>
				{data?.map((d: any) => (
					<FormControlLabel
						value={d.value}
						control={<Radio />}
						label={d.label}
						onClick={() =>
							Object.assign(setData, {
								[labelData]: [
									{
										id: d.value,
										name: d.label,
									},
								],
							})
						}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};

export default RadioPattern;
