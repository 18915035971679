import CustomAxios from "../../services/Interceptor/CustomAxios";
import { DispatchType, LieuxAction } from "../Interfaces/typeLieux";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"

let baseUrl = process.env.REACT_APP_URL_BACK;
const getLieux = (id: number) => {

  return (dispatch: DispatchType) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_LIEUX_BY_CLIENT + "?id="+  id)
      .then(function (response) {
        if (response.status === 200) {
          const action: LieuxAction = {
            type: TYPES.LIEU.GET,
            lieux: response.data
          }
          dispatch(action)
        }
      })
  }
}

export const lieuxActions = {
  getLieux
} 