export function formatText(text) {
	switch (text) {
		case "rdvPris":
			return "Nombre de rendez-vous pris sur la période";
		case "creneauxLibres":
			return "Nombre de rendez-vous libres à ce jour";
		case "userClientRdvNull":
			return "Nombre de user qui n'ont pas encore pris de rdv";
		default:
			console.log(`Sorry, we are out of ${text}.`);
	}
}
