import CustomAxios from "../../services/Interceptor/CustomAxios";
import { DispatchType } from "../Interfaces/typeUploadFile";
import { routesApi } from "../../services/RoutesApi"

let baseUrl = process.env.REACT_APP_URL_BACK;


const uploadFile = (formData: any, type : "client" | "lieu") => {

  let url = ""
  switch (type) {
    case 'client':
      url = routesApi.UPLOAD_FILE
      break;
    case 'lieu':
      url = routesApi.UPLOAD_FILE_LIEU
      break;
    default:
      console.log("erreur");
  }

  return (dispatch: DispatchType) => {
    setTimeout(() => {
      //APPEL API
      CustomAxios.post(baseUrl + url , formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        //SET ACTION
        //DISPATCH ACTION
        if (response.status === 200) {
          const action: any = {
            type: "UPLOAD_FILE",
            payload: response.data
          }
          dispatch(action)
        }
      })
        .catch(error => console.log(error));
    }, 500)
  }
};

const getListeClient = (id_client : number) => {
  return (dispatch: DispatchType) => {
    setTimeout(() => {
      //APPEL API
      CustomAxios.get(baseUrl + routesApi.GET_LIST_CLIENT + "?id_client=" + id_client).then((response) => {
        //SET ACTION
        //DISPATCH ACTION
        if (response.status === 200) {
          const action: any = {
            type: "UPLOAD_FILE",
            payload: response.data
          }
          dispatch(action)
        }
      })
        .catch(error => console.log(error));
    }, 500)
  }
};

export const uploadFileActions = {
  uploadFile, getListeClient
} 