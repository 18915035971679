
import { TYPES } from "../actions/actionTypes";
import { IIntervention, InterventionAction, TypeInterventionState } from "../Interfaces/typeIntervention";

const initialState : TypeInterventionState = []

const interventionReducer = (state : TypeInterventionState = initialState, action: InterventionAction) => {
  switch (action.type) {
    case TYPES.INTERVENTION.GET:
      return action.intervention
    case TYPES.INTERVENTION.ADD:
      return [...state, action.intervention]
    case TYPES.INTERVENTION.UPDATE:
      return state.map((typeInter : IIntervention) => {
          if(action.intervention.id === typeInter.id ){
            return action.intervention
          }
        return typeInter
      })
    default:
      return state
  }
}

export default interventionReducer