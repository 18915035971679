import React, { FunctionComponent, useEffect } from "react";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Grid,
	Typography,
} from "@mui/material";
import "./ValiderPeriodeIntervention.scss";
import Modale from "../components/Modale";
import "./ListClient.scss";
import { ICrudPage } from "../model/CrudPage";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { CrudAction } from "../redux/actions/CrudActions";
import { IRedux } from "../redux/reducers";
import Lottie from "lottie-react";
import loadingAnimation from "../style/img/98742-loading.json";
import { loadingAction } from "../redux/actions/LoadingActions";
import ModaleWithInput from "../components/ModalePattern/ModaleWithInput";
import EditIcon from "@mui/icons-material/Edit";
import { roleAction } from "../redux/actions/roleActions";
import { clientActions } from "../redux/actions/clientActions";

const CrudPage: FunctionComponent<ICrudPage> = ({ titre, type }) => {
	const dispatch = useDispatch();
	const { get, add, deleteOne, edit } = bindActionCreators(
		CrudAction,
		dispatch
	);
	const { getRoles } = bindActionCreators(roleAction, dispatch);
	const { getClient } = bindActionCreators(clientActions, dispatch);
	React.useEffect(() => {
		getRoles();
		getClient();
	}, [type]);

	const { setLoading } = bindActionCreators(loadingAction, dispatch);
	useEffect(() => {
		get(type);
		setLoading(true);
	}, [type]);

	const state = useSelector((state: IRedux) => state.crudState);
	useEffect(() => {
		if (type === "user-com") {
			state.filter((s: any) => s.email !== user.email);
		}
	}, [state]);
	const loading = useSelector((state: IRedux) => state.loadingState);
	const user = useSelector((state: IRedux) => state.userReduxState);

	//FONCTIONS MODALE
	const [open, setOpen] = React.useState(false);
	const [openModaleUpdate, setOpenModaleUpdate] = React.useState(false);
	const [dataUpdate, setDataUpdate] = React.useState({});
	const [stateSelectionne, setStateSelectionne] = React.useState(0);

	return (
		<div className="container-main">
			<h1 className="h1-title">{titre}</h1>
			{loading ? (
				<p>
					<Lottie animationData={loadingAnimation} />
				</p>
			) : (
				<div className="container-white">
					<Button
						sx={{ marginTop: "10px", marginBottom: "10px" }}
						variant="contained"
						onClick={() => {
							setOpenModaleUpdate(true);
							setStateSelectionne(0);
							type === "client" &&
								setDataUpdate([
									{
										label: "nom",
										value: "",
									},
								]);
							type === "technicien" &&
								setDataUpdate([
									{
										label: "nom",
										value: "",
									},
									{
										label: "prenom",
										value: "",
									},
									{
										label: "telephone",
										value: "",
									},
									{
										label: "email",
										value: "",
									},
								]);
							type === "user-com" &&
								setDataUpdate([
									{
										label: "nom",
										value: "",
									},
									{
										label: "prenom",
										value: "",
									},
									{
										label: "telephone",
										value: "",
									},
									{
										label: "email",
										value: "",
									},
									{
										label: "client",
										value: [],
									},
									{
										label: "role",
										value: [],
									},
								]);
						}}
					>
						Ajouter un nouveau {type}
					</Button>

					{state?.length === 0 && (
						<Typography
							sx={{ textAlign: "center", padding: "30px " }}
						>
							Aucune donnée
						</Typography>
					)}

					{state?.sort((a:any,b :any) => a.nom.localeCompare(b.nom)).map((s: any) => (
						<>
							<Card sx={{ minWidth: 1000 }} className="card">
								<Grid
									container
									spacing={2}
									className="card-grid"
								>
									<Grid xs={8}>
										<CardContent>
											<Typography>
												{s.nom}{" "}
												{s.prenom && " " + s.prenom}
											</Typography>
											{s.email && (
												<Typography>
													Email : {s.email}
												</Typography>
											)}
											{s.telephone && (
												<Typography>
													Téléphone : {s.telephone}
												</Typography>
											)}
										</CardContent>
									</Grid>
									<Grid xs={4}>
										<CardActions>
											<Button
												size="small"
												onClick={() => {
													setOpenModaleUpdate(true);
													setStateSelectionne(s.id);
													type === "client" &&
														setDataUpdate([
															{
																label: "nom",
																value: s.nom,
															},
														]);
													type === "technicien" &&
														setDataUpdate([
															{
																label: "nom",
																value: s.nom,
															},
															{
																label: "prenom",
																value: s.prenom,
															},
															{
																label: "telephone",
																value: s.telephone,
															},
														]);
													type === "user-com" &&
														setDataUpdate([
															{
																label: "nom",
																value: s.nom,
															},
															{
																label: "prenom",
																value: s.prenom,
															},
															{
																label: "telephone",
																value: s.telephone,
															},
															{
																label: "client",
																value: s.client,
															},
															{
																label: "role",
																value: s.role,
															},
														]);
												}}
											>
												<EditIcon />
											</Button>
											<Button
												size="small"
												onClick={() => {
													setOpen(true);
													setStateSelectionne(s.id);
												}}
											>
												<RemoveCircleIcon color="error" />
											</Button>
										</CardActions>
									</Grid>
								</Grid>
							</Card>
							<Divider />
						</>
					))}

					{/* MODALE Supprimer */}
					<Modale
						title="Supprimer"
						contenu={<p>Voulez vous vraiment supprimer ?</p>}
						actionValider={() => {
							stateSelectionne !== 0 &&
								deleteOne(stateSelectionne, type);
							setOpen(false);
						}}
						openModale={open}
						fonctionOpenModale={setOpen}
					/>
					{openModaleUpdate && (
						<ModaleWithInput
							title={
								stateSelectionne ? "Mettre à jour" : "Ajouter"
							}
							type={type}
							idDataSelectionne={stateSelectionne}
							tableauDeValeur={dataUpdate}
							fonctionSetObject={setDataUpdate}
							actionValider={stateSelectionne ? edit : add}
							openModale={openModaleUpdate}
							fonctionOpenModale={setOpenModaleUpdate}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default CrudPage;
