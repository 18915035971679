import { TYPES } from "../actions/actionTypes"
import { ITypeMateriel, TypeMaterielAction, TypeMaterielState } from "../Interfaces/typeTypeMateriel"

const initialState: TypeMaterielState = []

const typeMaterielReducer = (state: TypeMaterielState = initialState, action: TypeMaterielAction) => {
  switch (action.type) {
    case TYPES.TYPE_MATERIEL.GET:
      return action.typeMateriel
    case TYPES.TYPE_MATERIEL.ADD:
      return [ ...state, action.typeMateriel ]
    case TYPES.TYPE_MATERIEL.EDIT:
      return state.map((typeMater: ITypeMateriel) => {
        if (action.typeMateriel.id === typeMater.id) {
          return action.typeMateriel
        }
        return typeMater
      })
    case TYPES.TYPE_MATERIEL.DELETE:
      return state.filter(s => s.id != action.typeMateriel)
    default:
      return state
  }
}

export default typeMaterielReducer