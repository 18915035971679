import { DispatchLoading, LoadingAction } from "../Interfaces/typeLoading";
import { TYPES } from "./actionTypes";

const setLoading = (load : boolean) => {
  return (dispatch : DispatchLoading) => {
    const loading: LoadingAction = {
      type: TYPES.LOADING,
      loading : load,
    }
    dispatch(loading);
  };
};

export const loadingAction = {
  setLoading
} 