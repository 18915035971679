import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Grid,
	Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { periodesInterventionActions } from "../redux/actions/periodesInterventionsActions";
import { IPeriodeIntervention } from "../redux/Interfaces/typePeriodesIntervention";
import { IRedux } from "../redux/reducers";
import { toDateString } from "../services/FormatDate";
import LiensInternes from "../services/LiensInternes";
import "./ValiderPeriodeIntervention.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Modale from "../components/Modale";

function ValiderPeriodeIntervention() {
	const dispatch = useDispatch();

	//PERIODE INTERVENTION GET
	const {
		getPeriodesIntervention,
		validerPeriodesIntervention,
		deletePeriodesIntervention,
	} = bindActionCreators(periodesInterventionActions, dispatch);
	const currentClientID = useSelector(
		(state: IRedux) => state.currentClient.id
	);
	const periodesInterventionReduxState = useSelector(
		(state: IRedux) => state.periodesInterventionReduxState
	);
	useEffect(() => {
		getPeriodesIntervention(currentClientID, "client");
	}, []);

	//FONCTIONS MODALE
	const [open, setOpen] = React.useState(false);
	const [valider, setValider] = React.useState(false);
	const [periodeInterSelectionnee, setPeriodeInterSelectionnee] =
		React.useState(0);

	return (
		<div className="container-main">
			<h1 className="h1-title">Liste des périodes d'interventions</h1>

			<div className="container-white">
				<Card sx={{ minWidth: 1000 }} className="card">
					<Grid container spacing={2} className="card-grid">
						<Grid xs={8}>
							<CardContent>
								<Typography>Informations</Typography>
							</CardContent>
						</Grid>
						<Grid xs={4}>
							<CardActions>
								<Typography>Valider</Typography>
								<Typography>Supprimer</Typography>
								<Typography>Voir plus</Typography>
							</CardActions>
						</Grid>
					</Grid>
				</Card>
				<Divider />

				{periodesInterventionReduxState.length === 0 && (
					<Typography sx={{ textAlign: "center", padding: "30px " }}>
						Aucune période d'intervention
					</Typography>
				)}

				{periodesInterventionReduxState?.map(
					(periodeInter: IPeriodeIntervention) => (
						<>
							<Card sx={{ minWidth: 1000 }} className="card">
								<Grid
									container
									spacing={2}
									className="card-grid"
								>
									<Grid xs={8}>
										<CardContent>
											<Typography>
												{periodeInter.commande.numero +
													" - " +
													periodeInter.lieu.nom}
											</Typography>
											<Typography>
												{"Du " +
													toDateString(
														periodeInter.dateDebut
													) +
													" au " +
													toDateString(
														periodeInter.dateFin
													)}
											</Typography>
										</CardContent>
									</Grid>
									<Grid xs={4}>
										<CardActions>
											<Button
												size="small"
												disabled={
													periodeInter.validation ===
													false
														? false
														: true
												}
												onClick={() => {
													setOpen(true);
													setValider(true);
													setPeriodeInterSelectionnee(
														periodeInter.id
													);
												}}
											>
												<CheckCircleIcon
													color={
														periodeInter.validation ===
														false
															? "success"
															: "disabled"
													}
												/>
											</Button>

											<Button
												size="small"
												onClick={() => {
													setOpen(true);
													setValider(false);
													setPeriodeInterSelectionnee(
														periodeInter.id
													);
												}}
											>
												<RemoveCircleIcon color="error" />
											</Button>
											<Link
												to={
													LiensInternes.ADMIN.BASE +
													LiensInternes.ADMIN
														.VALIDATION_PERIODE_INTER +
													"/" +
													periodeInter.id
												}
											>
												<Button size="small">
													<MoreHorizIcon />
												</Button>
											</Link>
										</CardActions>
									</Grid>
								</Grid>
							</Card>
							<Divider />
						</>
					)
				)}
				<Modale
					title={valider ? "Valider" : "Supprimer"}
					contenu={
						valider ? (
							<p>
								Voulez vous vraiment valider votre période
								d'intervention ?{" "}
							</p>
						) : (
							<p>
								Voulez vous vraiment supprimer votre période
								d'intervention ?
							</p>
						)
					}
					actionValider={() => {
						if (valider) {
							periodeInterSelectionnee !== 0 &&
								validerPeriodesIntervention(
									periodeInterSelectionnee
								);
						} else {
							periodeInterSelectionnee !== 0 &&
								deletePeriodesIntervention(
									periodeInterSelectionnee
								);
						}
						setOpen(false);
					}}
					openModale={open}
					fonctionOpenModale={setOpen}
				/>
			</div>
		</div>
	);
}

export default ValiderPeriodeIntervention;
