import * as React from "react";
import TextfieldPattern from "../components/formPatterns/textfieldPattern/TextfieldPattern";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import Lottie from "lottie-react";
import onCompleteAnimation from "../style/img/97240-success.json";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "./../redux/actions/userActions";
import { bindActionCreators } from "redux";
import { IRedux } from "../redux/reducers";

interface IFormInput {
	password: string;
	confirmedPassword: string;
}

const defaultValues = {
	password: "",
	confirmedPassword: "",
};

const PasswordCreationPage = () => {
	const navigate = useNavigate();
	const params = useParams();
	const dispatch = useDispatch();
	const { activateUser } = bindActionCreators(userActions, dispatch);

	const YupSchema = yup.object().shape({
		password: yup
			.string()
			.required("Un mot de passe est requis !")
			.matches(
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				"Le mot de passe doit contenir 8 caractères minimum, une majuscule, une minuscule, un nombre et un caractère spécial"
			),
		confirmedPassword: yup
			.string()
			.required("Un mot de passe est requis !")
			.oneOf(
				[yup.ref("password")],
				"Les mots de passe ne sont pas identiques"
			),
	});

	const methods = useForm<IFormInput>({
		resolver: yupResolver(YupSchema),
		defaultValues: defaultValues,
	});
	const {
		handleSubmit,
		reset,
		control,
		setValue,
		watch,
		formState: { errors },
	} = methods;

	const errorState = useSelector((state: IRedux) => state.errorState);
	const [error, setError] = React.useState(false);
	React.useEffect(() => {
		setError(
			errorState.status === 0 || errorState.status !== 200 ? false : true
		);
	}, [errorState]);

	const onSubmit = (data: IFormInput) => {
		if (data.password === data.confirmedPassword) {
			const infoToSend = {
				password: data.password,
				uuid: params.id,
			};
			activateUser(infoToSend);
		}
	};

	return (
		<div className="container-main">
			<h1 style={{ textAlign: "center" }}>Créez votre mot de passe</h1>
			<div className="container-white">
				{!error ? (
					<FormProvider {...methods}>
						<form
							onSubmit={methods.handleSubmit(onSubmit)}
							className="form-container"
						>
							<TextfieldPattern
								type="password"
								name="password"
								control={control}
								label="Mot de passe"
								fullWidth={true}
								error={!!errors.password}
								helperText={
									errors.password && errors?.password?.message
								}
							/>
							<TextfieldPattern
								type="password"
								name="confirmedPassword"
								control={control}
								label="Confirmation du mot de passe"
								fullWidth={true}
								error={!!errors.confirmedPassword}
								helperText={
									errors.confirmedPassword &&
									errors?.confirmedPassword?.message
								}
							/>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									gap: "10px",
									marginTop: "10px",
								}}
							>
								<Button type="submit" variant={"contained"}>
									Envoyer
								</Button>
								<Button
									type="reset"
									onClick={() => {
										reset();
									}}
									variant={"outlined"}
								>
									Réinitialiser
								</Button>
							</div>
						</form>
						<p>
							NB. Le mot de passe doit contenir 8 caractères minimum, une majuscule, une minuscule, un nombre et un caractère spécial.
						</p>
					</FormProvider>
				) : (
					<div
						style={{
							padding: "20px",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div style={{ display: "flex", alignItems: "center" }}>
							<p>Votre mot de passe a bien été créé.</p>
							<Lottie
								animationData={onCompleteAnimation}
								loop={false}
								style={{ width: 50 }}
							/>
						</div>
						<p style={{ textAlign: "center" }}>
							Vous pouvez désormais vous connecter à la plateforme
							de prise de rendez-vous à l'aide de votre adresse
							email et mot de passe et sélectionner un créneau
							d'intervention.
						</p>
						<Button
							variant="contained"
							onClick={() => navigate("/connexion")}
						>
							Se connecter et prendre rendez-vous
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default PasswordCreationPage;
