import React, { useEffect, useRef } from "react";
import PlanningFullCalendar from "../components/planningPattern/PlanningFullCalendar";
import {
	Button,
	Typography,
	TextField,
	Stepper,
	Step,
	StepLabel,
	Box,
	Autocomplete,
	AppBar,
	Tab,
	Tabs,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { periodesInterventionActions } from "../redux/actions/periodesInterventionsActions";
import { techniciensActions } from "../redux/actions/techniciensActions";
import { crenauxActions } from "../redux/actions/creneauxActions";

import { bindActionCreators } from "redux";
import PlanningVisuelPeriodeFullCalendar from "../components/planningPattern/PlanningVisuelPeriodeFullCalendar";
import { IRedux } from "../redux/reducers";
import { AutoCompletePeriodeInter } from "../model/AutoCompletePeriodeInter";
import { calculDuree } from "../services/CalculDebutFinJournee";
import { useParams } from "react-router-dom";
import { IPeriodeIntervention } from "../redux/Interfaces/typePeriodesIntervention";

const AddPlanningTech = () => {
	const dispatch = useDispatch();
	const { getPeriodesIntervention } = bindActionCreators(
		periodesInterventionActions,
		dispatch
	);
	const { getTechniciens } = bindActionCreators(techniciensActions, dispatch);
	const currentClientID = useSelector(
		(state: IRedux) => state.currentClient.id
	);

	useEffect(() => {
		if (currentClientID !== 0) {
			getPeriodesIntervention(currentClientID, "client-validation");
			getTechniciens();
		}
	}, [currentClientID]);

	const periodesInterventionReduxState = useSelector(
		(state: IRedux) => state.periodesInterventionReduxState
	);
	const [listOfPeriodes, setListOfPeriodes] = React.useState<
		AutoCompletePeriodeInter[]
	>([]);
	useEffect(() => {
		let listOfPeriodesData: AutoCompletePeriodeInter[] = [];
		periodesInterventionReduxState?.map((onePeriode: any) => {
			let data = {
				label:
					onePeriode?.lieu?.nom +
					" | commande - " +
					onePeriode?.commande?.numero +
					" - " +
					calculDuree(onePeriode?.typeIntervention?.duree),
				id: onePeriode.id,
			};
			listOfPeriodesData.push(data);
			setListOfPeriodes(listOfPeriodesData);
		});
	}, [periodesInterventionReduxState]);

	const techniciensReduxState = useSelector(
		(state: IRedux) => state.techniciensReduxState
	);
	const listOfTechniciens = techniciensReduxState;

	const { getCreneauxHoraires } = bindActionCreators(
		crenauxActions,
		dispatch
	);

	//GESTION DES CRENEAUX
	const listOfCreneaux = useSelector(
		(state: IRedux) => state.creneauxReduxState
	);

	const { addCreneauxToTechnician } = bindActionCreators(
		crenauxActions,
		dispatch
	);

	let idPeriodIntervention: number;
	const [confirmCreneauxToTechnicien, setConfirmCreneauxToTechnicien] =
		React.useState<boolean>(false);

	//Gestion de l'état des étapes d'assignation
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set<number>());
	const [disableNextButton, setDisableNextButton] = React.useState(true);
	const [periodesIntervention, setPeriodesIntervention] = React.useState<
		number | undefined
	>(0);

	//PERIODE INTER SELECTIONNEE
	const [
		periodesInterventionSelectionnee,
		setPeriodesInterventionSelectionnee,
	] = React.useState<{}>({});

	const isStepSkipped = (step: number) => {
		return skipped.has(step);
	};

	//SI PERIODE INTER SELECTIONNEE DANS L'URL DIRECT STEP 2
	const params = useParams();
	const idPeriode: number = params.id ? parseInt(params.id) : 0;
	useEffect(() => {
		if (idPeriode !== 0) {
			setActiveStep(1);
			setPeriodesIntervention(idPeriode);
			getCreneauxHoraires(idPeriode);
			periodesInterventionReduxState.forEach(
				(periodeInter: IPeriodeIntervention) => {
					if (periodeInter.id === idPeriode) {
						setPeriodesInterventionSelectionnee(periodeInter);
					}
				}
			);
		}
	}, [idPeriode]);

	//Création d'un useRef pour éviter le first render du UseEffect au chargement de la page
	const didMountRef = useRef(false);

	useEffect(() => {
		//Après le first render du UseEffect,
		if (didMountRef.current) {
			//On bloque le bouton "Suivant" si aucune sélection a été effectuée
			if (activeStep === 0) {
				if (
					periodesIntervention === undefined ||
					periodesIntervention === null
				) {
					setDisableNextButton(true);
				} else {
					periodesInterventionReduxState?.forEach(
						(onePeriode: any) => {
							if (onePeriode.id === periodesIntervention) {
								setPeriodesInterventionSelectionnee(onePeriode);
								getCreneauxHoraires(onePeriode.id);
							}
						}
					);
					setDisableNextButton(false);
				}
			}
		}
		//Au premier chargement, on passe le useRef à true pour activer les actions au changement d'état des States
		didMountRef.current = true;
		setConfirmCreneauxToTechnicien(false);
	}, [activeStep, periodesIntervention, confirmCreneauxToTechnicien]);

	useEffect(() => {
		if (listOfCreneaux !== undefined) {
			periodesInterventionReduxState?.forEach((onePeriode: any) => {
				if (onePeriode.id === periodesIntervention) {
					idPeriodIntervention = onePeriode.id;
					getCreneauxHoraires(onePeriode.id);
				}
				//getCreneauxHoraires(idPeriodIntervention);
			});
		}
	}, [confirmCreneauxToTechnicien]);

	//Fonction pour avancer à l'étape suivante du formulaire
	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	//Fonction pour revenir à l'étape précédente du formulaire
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	//Titres du spinner
	const steps = [
		"Sélection de la période d'intervention à assigner",
		"Affectation de la période au planning des techniciens",
		"Confirmation du nouveau planning des techniciens",
	];

	interface TabPanelProps {
		children?: React.ReactNode;
		dir?: string;
		index: number;
		value: number;
	}

	function TabPanel(props: TabPanelProps) {
		const { children, value, index, ...other } = props;

		return (
			<Typography
				component="div"
				role="tabpanel"
				hidden={value !== index}
				id={`action-tabpanel-${index}`}
				aria-labelledby={`action-tab-${index}`}
				{...other}
			>
				{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
			</Typography>
		);
	}

	function a11yProps(index: any) {
		return {
			id: `action-tab-${index}`,
			"aria-controls": `action-tabpanel-${index}`,
		};
	}

	const [value, setValue] = React.useState<number>(0);

	const handleChange = (event: unknown, newValue: number) => {
		setValue(newValue);
	};

	return (
		<div className="container-main">
			<h1 className="h1-title">
				Assignation de techniciens à une période d'intervention
			</h1>
			<div className="container-white">
				<h3>
					Choisissez la période d'intervention à affecter au(x)
					technicien(s)
				</h3>
				{/* Stepper */}
				<Stepper activeStep={activeStep}>
					{steps.map((label, index) => {
						const stepProps: { completed?: boolean } = {};
						const labelProps: {
							optional?: React.ReactNode;
						} = {};
						if (isStepSkipped(index)) {
							stepProps.completed = false;
						}
						return (
							<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
				<div
					style={{
						display: activeStep === 0 ? "block" : "none",
						width: "100%",
					}}
				>
					<Autocomplete
						noOptionsText="Pas de périodes disponibles"
						options={listOfPeriodes}
						sx={{ width: 300 }}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.id}>
									{option.label}
								</li>
							);
						}}
						onChange={(
							event: any,
							option: AutoCompletePeriodeInter | null
						) => {
							setPeriodesIntervention(option?.id);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Choix de la période d'intervention"
								variant="standard"
							/>
						)}
					/>
				</div>
				<br />
				<div
					style={{
						display: activeStep === 1 ? "block" : "none",
						width: "100%",
					}}
				>
					<Box
						sx={{
							bgcolor: "background.paper",
							width: "100%",
							position: "relative",
							minHeight: 200,
						}}
					>
						<AppBar position="static" color="default">
							<Tabs
								value={value}
								onChange={handleChange}
								indicatorColor="primary"
								textColor="primary"
								variant="fullWidth"
								aria-label="action tabs example"
							>
								{listOfTechniciens?.map(
									(technicien: any, index: number) => {
										return (
											<Tab
												label={technicien?.nom}
												key={index}
												{...a11yProps(index)}
											/>
										);
									}
								)}
							</Tabs>
						</AppBar>
						{listOfTechniciens?.map(
							(technicien: any, index: number) => {
								return (
									<TabPanel
										index={index}
										value={value}
										key={index}
									>
										<h3 style={{ textAlign: "center" }}>
											Planning de {technicien?.nom}
										</h3>
										{Object.keys(
											periodesInterventionSelectionnee
										).length !== 0 &&
											listOfCreneaux.length > 0 && (
												<PlanningFullCalendar
													setConfirmCreneauxToTechnicien={
														setConfirmCreneauxToTechnicien
													}
													addCreneauxToTechnician={
														addCreneauxToTechnician
													}
													listOfCreneaux={
														listOfCreneaux
													}
													periodesInterventionSelectionnee={
														periodesInterventionSelectionnee
													}
													technicien={technicien}
												/>
											)}
									</TabPanel>
								);
							}
						)}
					</Box>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<h3>Planning global de la période d'intervention</h3>
						<PlanningVisuelPeriodeFullCalendar
							listOfCreneaux={listOfCreneaux}
							periodesInterventionSelectionnee={
								periodesInterventionSelectionnee
							}
							deletable={false}
						/>
					</div>
				</div>
				<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
					<Button
						color="inherit"
						disabled={activeStep === 0}
						onClick={handleBack}
						sx={{ mr: 1 }}
					>
						Retour
					</Button>
					<Box sx={{ flex: "1 1 auto" }} />
					{/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
					{activeStep !== steps.length && (
						<Button
							onClick={handleNext}
							disabled={disableNextButton}
						>
							{activeStep === steps.length - 1
								? "Terminer"
								: "Suivant"}
						</Button>
					)}
				</Box>
			</div>
		</div>
	);
};

export default AddPlanningTech;
