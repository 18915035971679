import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import allocales from "@fullcalendar/core/locales-all";
import "./PlanningFullCalendar.scss";
import { calculDuree } from "../../services/CalculDebutFinJournee";
import ModaleConfirmRdv from "../ModalePattern/ModaleConfirmRecap/ModaleConfirmRdv";

const PlanningFullCalendarUserClient = ({
	listOfCreneaux,
	periodesInterventionSelectionnee,
	creneauxUserClient,
	setCreneauChoisi,
	setActiveStep,
	activeStep,
}: any) => {
	const dayjs = require("dayjs");
	const duration = require("dayjs/plugin/duration");
	dayjs.extend(duration);

	const [events, setEvents] = useState([]);

	//DUREE D'UN EVENT
	const [slotDuration, setSlotDuration] = useState<any>("01:30:00");
	const [slotDurationMinute, setSlotDurationMinute] = useState<number>(60);
	useEffect(() => {
		if (
			periodesInterventionSelectionnee?.typeIntervention?.duree !==
			undefined
		) {
			setSlotDuration(
				calculDuree(
					periodesInterventionSelectionnee?.typeIntervention?.duree
				)
			);
			setSlotDurationMinute(
				periodesInterventionSelectionnee?.typeIntervention?.duree
			);
		}
	}, [periodesInterventionSelectionnee]);

	const [open, setOpen] = React.useState(false);


	const renderEventContent = (eventContent: any) => {
		if (
			eventContent.timeText === "12:00 - 13:00" ||
			eventContent.timeText === "13:00 - 14:00"
		) {
		}
		if (eventContent?.event._def.title === "créneau disponible") {
			eventContent.backgroundColor = "green";
			eventContent.borderColor = "green";
		} else {
			eventContent.backgroundColor = "#90EE90";
			eventContent.borderColor = "#90EE90";
		}

		return (
			<b>
				{eventContent?.timeText}h :{" "}
				{(eventContent?.event._def.title).toLowerCase()}
			</b>
		);
	};

	
	const calendarRef = useRef<FullCalendar>(null!);
	let calendarAPI: any;

	useEffect(() => {
		calendarAPI = calendarRef?.current?.getApi();
	}, [events]);

	//CALCUL PLAGE HORAIRE
	const [plageHoraire, setPlageHoraire] = useState<any>({
		heureDebut: "06:00",
		heureFin: "18:00",
	});

	// Créneaux
	const initialEvents = listOfCreneaux?.map((creneauReserve: any) => {
		let title = "créneau disponible";
		return {
			title: title,
			start: creneauReserve.dateDebut,
			end: creneauReserve.dateFin,
			idCreneau: creneauReserve.id.toString(),
		};
	});

	const [newData, setNewData] = useState<any>({});

	function handleEventClick(clickInfo: any) {
		setNewData(parseInt(clickInfo.event._def.extendedProps.idCreneau));
		setOpen(true);
	};

	// Ajout d'un créneau dans la liste
	function addCreneau(newData: any) {
		let creneauChoisi = []
		let indexCreneau;
		indexCreneau = listOfCreneaux.findIndex((creneau: any) => newData === creneau.id)
		if(indexCreneau !== -1) {
			creneauChoisi.push(listOfCreneaux[indexCreneau])
			setCreneauChoisi(creneauChoisi)
			setActiveStep(activeStep + 1)
			setOpen(false)
		}
		console.log('creneauChoisi', creneauChoisi)
	};

	return (
		<>
			{Object.keys(periodesInterventionSelectionnee).length !== 0 && (
				<div
					style={{
						background: "white",
						margin: "20px",
						padding: "40px",
						borderRadius: "20px",
					}}
				>
					<FullCalendar
						ref={calendarRef}
						plugins={[
							timeGridPlugin,
							dayGridPlugin,
							interactionPlugin,
							interactionPlugin,
						]}
						headerToolbar={{
							right: "prev,next",
						}}
						timeZone={"local"}
						locales={allocales}
						locale={"FR"}
						height={700}
						expandRows={true}
						buttonText={{
							day: "Jour",
							prev: "Précédent",
							next: "Suivant",
							month: "Mois",
							week: "Semaine",
						}}
						initialView="timeGridWeek"
						validRange={() => {
							return {
								start: new Date(
									creneauxUserClient?.dateDebut
								),
								end: new Date(
									creneauxUserClient?.dateFin
								),
							};
						}}
						selectable={false}
						editable={false}
						dayHeaderFormat={{
							week: "long",
							day: "numeric",
							month: "long",
						}}
						weekends={false}
						eventOverlap={false}
						selectOverlap={false}
						allDaySlot={false}
						slotDuration={slotDuration}
						slotMinTime={plageHoraire.heureDebut}
						slotMaxTime={plageHoraire.heureFin}
						businessHours={[
							{
								daysOfWeek: [1, 2, 3, 4, 5],
								startTime: "08:00",
								endTime: "12:00",
							},
							{
								daysOfWeek: [1, 2, 3, 4, 5],
								startTime: "14:00", // 10am
								endTime: "18:00", // 4pm
							},
						]}
						events={initialEvents}
						eventContent={renderEventContent}
						eventClick={handleEventClick}
					/>
					
					{open && (

						<ModaleConfirmRdv
							title={"Prendre un rendez-vous pour ce créneau ?"}
							data={newData}
							actionValider={addCreneau}
							openModale={open}
							fonctionOpenModale={setOpen}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default PlanningFullCalendarUserClient;
