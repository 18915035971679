import { Button } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import DatePickerPattern from "../components/formPatterns/datePickerPattern/DatePickerPattern";
import { periodesInterventionActions } from "../redux/actions/periodesInterventionsActions";
import { IPeriodeIntervention } from "../redux/Interfaces/typePeriodesIntervention";
import { IRedux } from "../redux/reducers";
import LiensInternes from "../services/LiensInternes";
import Lottie from "lottie-react";
import loadingAnimation from "../style/img/98742-loading.json";

function AddPeriodeRappel() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { addPeriodeIntervention } = bindActionCreators(
		periodesInterventionActions,
		dispatch
	);

	const userID = useSelector((state: IRedux) => state.userReduxState.id);

	const error = useSelector((state: IRedux) => state.errorState);

	const [loading, setLoading] = useState<boolean>(false);

	//RECUPERATION PERIODE INTERVENTION
	const params = useParams();
	const idPeriode: number = params.id ? parseInt(params.id) : 0;

	//RECUPERATION DE LA PERIODE D'INTERVENTION POUR AFFICHER LES DETAILS
	const periodesInterventionReduxState = useSelector(
		(state: IRedux) => state.periodesInterventionReduxState
	);
	const [periodeSelected, setPeriodSelected] =
		useState<IPeriodeIntervention>();
	useEffect(() => {
		periodesInterventionReduxState.forEach(
			(periodeInter: IPeriodeIntervention) => {
				if (periodeInter.id === idPeriode) {
					setPeriodSelected(periodeInter);
				}
			}
		);
	}, []);

	const defaultValues = {
		debutPeriode: null,
		finPeriode: null,
	};

	//Appel des méthodes de React Hook Form
	const methods = useForm<any>({ defaultValues: defaultValues });
	const {
		reset,
		control,
		formState: { errors },
	} = methods;

	const [dateDebutNewPeriode, setDateDebutNewPeriode] = useState<any>();
	const [dateFinNewPeriode, setDateFinNewPeriode] = useState<any>();

	const handleSubmit = () => {
		setLoading(true);
		const dataToSend = {
			dateDebut: dayjs(dateDebutNewPeriode).format("YYYY-MM-DDTHH:mm:ss"),
			dateFin: dayjs(dateFinNewPeriode).format("YYYY-MM-DDTHH:mm:ss"),
			lieu: {
				id: periodeSelected?.lieu.id,
			},
			commande: {
				id: periodeSelected?.commande.id,
			},
			typeIntervention: {
				id: periodeSelected?.typeIntervention.id,
			},
			userComNetWork: {
				id: userID,
			},
			periodeInterventionReference: {
				id: periodeSelected?.id,
			},
		};
		console.log("dataToSend", dataToSend);
		addPeriodeIntervention(dataToSend, null, "rappel");

		setTimeout(function () {
			console.log("error.status", error.status);
			error.status === 200 &&
				navigate(
					LiensInternes.ADMIN.BASE +
						LiensInternes.ADMIN.ASSIGNATION_TECH +
						"/0"
				);
		}, 1000);
	};

	return (
		<div className="container-main">
			<h1 className="h1-title">
				Créer une période de rappel pour <br />
				{periodeSelected?.commande.numero +
					" - " +
					periodeSelected?.lieu.nom}
			</h1>
			{loading ? (
				<p>
					<Lottie animationData={loadingAnimation} />
				</p>
			) : (
				<div className="container-white">
					<div>
						<DatePickerPattern
							control={control}
							name={"debutPeriode"}
							label={t(
								"FORMULAIRE_PERIODE_INTERVENTION.label_periode_debut"
							)}
							required={true}
							optionsSelected={dateDebutNewPeriode}
							setOptionsSelected={setDateDebutNewPeriode}
							disableFuture={false}
							disablePast={true}
						/>
					</div>
					<div>
						<DatePickerPattern
							control={control}
							name={"finPeriode"}
							label={t(
								"FORMULAIRE_PERIODE_INTERVENTION.label_periode_fin"
							)}
							required={true}
							optionsSelected={dateFinNewPeriode}
							setOptionsSelected={setDateFinNewPeriode}
							disableFuture={false}
							disablePast={true}
							minDate={dateDebutNewPeriode}
						/>
					</div>

					<Button variant="contained" onClick={handleSubmit}>
						Envoyer
					</Button>
				</div>
			)}
		</div>
	);
}

export default AddPeriodeRappel;
