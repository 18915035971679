import CustomAxios from "../../services/Interceptor/CustomAxios";
import { DispatchType, CreneauxAction, ICreneau } from "../Interfaces/typeCreneaux";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"
import { DispatchTypeTechnicien, ITechnicien, TechniciensAction } from "../Interfaces/typeTechniciens";
import { ErrorAction, IError } from "../Interfaces/typeError";
import { Dispatch } from "redux";
import { DispatchUser, IUser, UserAction } from "../Interfaces/typeUser";

let baseUrl = process.env.REACT_APP_URL_BACK;
const getCreneauxHoraires = (periodeInterventionID: number) => {

    return (dispatch: DispatchType) => {
        CustomAxios
            .get(baseUrl + routesApi.GET_CRENEAUX + `?id_periode_intervention=${periodeInterventionID}`)
            .then(function (response) {
                if (response.status === 200 && response.data.statusCode !== 403) {
                  const data : ICreneau[] = response.data
                    const action: CreneauxAction = {
                        type: TYPES.CRENEAUX.GET,
                        creneaux: data
                    }

                    dispatch(action)
                }
            })
    }
}

const addCreneauxToTechnician = (idTechnician: number, tabCreneaux: any) => {

    return (dispatch: DispatchTypeTechnicien) => {
        CustomAxios
            .put(baseUrl + routesApi.ADD_CRENEAUX + `?idTechncien=${idTechnician}`, tabCreneaux)
            .then(function (response) {
                if (response.status === 200) {
                    const technicien : ITechnicien = {
                      creneauHoraires: response.data,
                      id : idTechnician,
                      email: "",
                      prenom: "",
                      nom: "",
                      rdvs: []
                    }
                    const actionTechncien: TechniciensAction = {
                        type: TYPES.TECHNICIENS.ADD_CRENEAUX_TO_TECH,
                        technicien: [technicien]
                    }
                    dispatch(actionTechncien)
                }
            })
    }
}

const deleteTechnicien = (idCreneau : number) => {

    return (dispatch: Dispatch) => {
        CustomAxios
            .post(baseUrl + routesApi.DELETE_TECH_CRENEAU, { id : idCreneau})
            .then(function (response) {
              if (response.status === 200 && response.data.statusCode !== 403) {
                const data : ICreneau = response.data
                  const action: CreneauxAction = {
                      type: TYPES.CRENEAUX.UPDATE,
                      creneaux: data
                  }
                  dispatch(action)
              }
              if (response.data.statusCode === 403) {
                const errorDispatch : IError = {
                  error : response.data.message,
                  status : response.data.statusCode
                }
                const actionError: ErrorAction = {
                  type: TYPES.ERROR.SET,
                  error : errorDispatch,
                }
                  dispatch(actionError)
              }
            })
    }
}

const getCreneauxByLieuAndRdvNotNull = (params : number) => {
  return (dispatch: DispatchType) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_CRENEAUX_BY_RDV_AND_LIEU, { params: params })
      .then(function (response) {
        if (response.status === 200 && response.data.statusCode !== 403) {
          const data : ICreneau[] = response.data
            const action: CreneauxAction = {
                type: TYPES.CRENEAUX.GET,
                creneaux: data
            }

            dispatch(action)
        }
        if (response.data.statusCode === 403) {
          const data : ICreneau[] = response.data
            const action: CreneauxAction = {
                type: TYPES.CRENEAUX.GET,
                creneaux: null
            }

            dispatch(action)
        }
      })
  }
}

const getCreneauxForUser = (id_periode: number) => {

    return (dispatch: DispatchType) => {
        CustomAxios
            .get(baseUrl + routesApi.GET_CRENEAUX_USER + `?id_periode=${id_periode}`) 
            .then(function (response) {
                if (response.status === 200 && response.data.statusCode !== 403) {
                    const data : ICreneau[] = response.data
                      const action: CreneauxAction = {
                          type: TYPES.CRENEAUX.GET_CRENEAUX_FOR_USER,
                          creneaux: data
                      }
                      dispatch(action)
                  }
            })
    }
}

const addOneCreneauForUser = (creneauToSend: any) => {
    return (dispatch: DispatchUser) => {
        CustomAxios
            .put(baseUrl + routesApi.ADD_ONE_RDV, creneauToSend)
            .then(function (response) {
                if (response.status === 200) {
                    const user : IUser = {
                      id : response.data.id,
                      email: response.data.email,
                      prenom: response.data.prenom,
                      nom : response.data.nom,
                      telephone : response.data.telephone,
                      role : response.data.roles,
                      client : response.data.client,
                      commandes : response.data.commandes ? response.data.commandes : [],
                      rdvs: response.data.rdvs,
                    }
                    const actionUser: UserAction = {
                        type: TYPES.USER.ADD_ONE_CRENEAUX_TO_USER,
                        user: user,
                    }
                    dispatch(actionUser)
                }
            })
    }
}

const modifyOneCreneauForUser = (creneauToSend: any) => {
    return (dispatch: DispatchUser) => {
        CustomAxios
            .post(baseUrl + routesApi.ADD_ONE_RDV, creneauToSend)
            .then(function (response) {
                if (response.status === 200) {
                    const user : IUser = {
                      id : response.data.id,
                      email: response.data.email,
                      prenom: response.data.prenom,
                      nom : response.data.nom,
                      telephone : response.data.telephone,
                      role : response.data.roles,
                      client : response.data.client,
                      commandes : response.data.commandes ? response.data.commandes : [],
                      rdvs: response.data.rdvs,
                    }
                    const actionUser: UserAction = {
                        type: TYPES.USER.MODIFY_ONE_CRENEAUX,
                        user: user,
                    }
                    dispatch(actionUser)
                }
            })
    }
}

export const crenauxActions = {
    getCreneauxHoraires,
    getCreneauxByLieuAndRdvNotNull,
    deleteTechnicien,
    addCreneauxToTechnician,
    getCreneauxForUser,
    addOneCreneauForUser,
    modifyOneCreneauForUser,
} 
