import CustomAxios from "../../services/Interceptor/CustomAxios";
import { DispatchUser, IUser, UserAction } from "../Interfaces/typeUser";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"
import { ErrorAction, ErrorDispatch, IError } from "../Interfaces/typeError";

let baseUrl = process.env.REACT_APP_URL_BACK;

// const getUser = () => {

//   return (dispatch: DispatchType) => {
//     setTimeout(() => {
//       //APPEL API
//       CustomAxios.get(baseUrl + routesApi.USERS).then((response) => {
//         //SET ACTION
//         const action: UserAction = {
//           type: TYPES.USER.GET,
//           user: response.data,
//           status: response.status,
//           roles: response.data.roles
//         }
//         //DISPATCH ACTION
//         dispatch(action)
//       });
//     }, 500)
//   }
// };

const activateUser = (data: any) => {
  return (dispatch: DispatchUser) => {
    CustomAxios
      .post(baseUrl + routesApi.ACTIVATION, {
        password: data.password,
        uuid: data.uuid
      })
      .then((response) => {
      })
  }
}

const modifyMdp = (data: any) => {
  return (dispatch: ErrorDispatch) => {
    CustomAxios
      .post(baseUrl + routesApi.MODIFY_MDP, {
        id: data.id,
        password: data.password
      })
      .then((response) => {
        const errorDispatch: IError = {
          error: response.data,
          status: response.status
        }
        const actionError: ErrorAction = {
          type: TYPES.ERROR.SET,
          error: errorDispatch,
        }
        dispatch(actionError);
      });

  }
}

const emailResetPassword = (email: string) => {
  return (dispatch: ErrorDispatch) => {
    CustomAxios
      .post(baseUrl + routesApi.EMAIL_RESET_MDP + "?email=" + email)
      .then((response) => {
        const errorDispatch: IError = {
          error: response.data,
          status: response.status
        }
        const actionError: ErrorAction = {
          type: TYPES.ERROR.SET,
          error: errorDispatch,
        }
        dispatch(actionError);
      });

  }
}

const resetPassword = (data: any) => {
  return (dispatch: DispatchUser) => {
    CustomAxios
      .post(baseUrl + routesApi.RESET_MDP + "?uuid=" + data.uuid, { password: data.password })
      .then((response) => {
        const errorDispatch: IError = {
          error: response.data,
          status: response.status
        }
        const actionError: ErrorAction = {
          type: TYPES.ERROR.SET,
          error: errorDispatch,
        }
      })
  }
}

const setTelephoneUser = (userToSend: IUser) => {
  return (dispatch: DispatchUser) => {
    CustomAxios
      .post(baseUrl + routesApi.SET_TEL_USER, userToSend)
      .then(function (response) {
        if (response.status === 200) {
          const user: IUser = {
            id: response.data.id,
            email: response.data.email,
            prenom: response.data.prenom,
            nom: response.data.nom,
            telephone: response.data.telephone,
            role: response.data.roles,
            client: response.data.client,
            commandes: response.data.commandes ? response.data.commandes : [],
            rdvs: response.data.rdvs ? response.data.rdvs : [],
          }
          const action: UserAction = {
            type: TYPES.USER.SET_TEL_USER,
            user: user,
          }
          dispatch(action)
        }
      })
  }
}

export const userActions = {
  activateUser, modifyMdp, emailResetPassword, resetPassword, setTelephoneUser
} 
