import { ErrorAction, ErrorDispatch, IError } from "../Interfaces/typeError";
import { TYPES } from "./actionTypes";

const setError = (errorResponse : string, status : number) => {
  return (dispatch : ErrorDispatch) => {
    const error : IError = {
      error : errorResponse,
      status : status
    }
    const actionError: ErrorAction = {
      type: TYPES.ERROR.SET,
      error : error,
    }
    dispatch(actionError);
  };
};


const deleteError = () => {
  return (dispatch : ErrorDispatch) => {
    const actionError: ErrorAction = {
      type: TYPES.ERROR.DELETE,
      error : null,
    }
    dispatch(actionError);
  };
};

export const ErrorActions = {
  setError, deleteError
} 