import { sr } from "date-fns/locale";
import { TYPES } from "../actions/actionTypes";
import { ILieu, LieuxAction } from "../Interfaces/typeLieux";

const initialState: [] = []

const lieuxReducer = (state = initialState, action: LieuxAction) => {
  switch (action.type) {
    case TYPES.LIEU.GET:
      return action.lieux
    default:
      return state
  }
}

export default lieuxReducer