import { Button, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import CardWithButton from "../components/card/CardWithButton";
import ModaleWithInput from "../components/ModalePattern/ModaleWithInput";
import { CrudAction } from "../redux/actions/CrudActions";
import { roleAction } from "../redux/actions/roleActions";
import { uploadFileActions } from "../redux/actions/uploadFileActions";
import { IRedux } from "../redux/reducers";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import LiensInternes from "../services/LiensInternes";

function RerchercheUserClient() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { searchUserClient, changeRoleClient } = bindActionCreators(
		CrudAction,
		dispatch
	);
	const { getRoles } = bindActionCreators(roleAction, dispatch);
	const { getListeClient } = bindActionCreators(uploadFileActions, dispatch);
	const currentClientId: any = useSelector(
		(state: IRedux) => state?.currentClient.id
	);
	React.useEffect(() => {
		getRoles();
		getListeClient(currentClientId);
	}, []);

	function handleChange(event: any) {
		event.target.value.length > 3 &&
			searchUserClient(event.target.value, currentClientId);
	}
	const data: any = useSelector((state: IRedux) => state?.crudState);

	//FONCTIONS MODALE
	const [openModaleUpdate, setOpenModaleUpdate] = React.useState(false);
	const [dataUpdate, setDataUpdate] = React.useState({});
	const [stateSelectionne, setStateSelectionne] = React.useState(0);

	//DOWNLOAD DU CSV
	const csv: any = useSelector(
		(state: any) => state?.uploadFileReduxState.data
	);
	const [errorFile, setErrorFile] = React.useState("");
	function downloadCsv() {
		setErrorFile("data:text/csv;charset=utf-8," + encodeURI(csv));
	}

	return (
		<div className="container-main">
			<h1 className="h1-title">Rechercher un utilisateur client</h1>
			<div className="container-white">
				<div
					style={{
						margin: "2rem",
					}}
				>
					<Button
						variant="contained"
						onClick={downloadCsv}
						href={errorFile}
						download="Liste des utilisateurs"
					>
						Télécharger le fichier de tous les utilisateurs
					</Button>
				</div>
				<TextField
					id="outlined-basic"
					label="Rechercher"
					variant="outlined"
					fullWidth
					onChange={(e) => handleChange(e)}
				/>
				{(data.length === 0 || !data[0].hasOwnProperty("lieu")) && (
					<Typography align="center" sx={{ padding: "30px" }}>
						Aucun résulat à votre recherche
					</Typography>
				)}

				{data?.map((d: any) => {
					if (d.lieu) {
						return (
							<CardWithButton
								key={d.id}
								data={d}
								buttons={[
									{
										title: "Changer de role ",
										onClick: () => {
											setOpenModaleUpdate(true);
											setStateSelectionne(d.id);
											setDataUpdate([
												{
													label: "role",
													value: d.role,
												},
											]);
										},
									},
									{
										title: <MoreHorizIcon />,
										onClick: () => {
											navigate(
												LiensInternes.ADMIN.BASE +
													LiensInternes.ADMIN
														.RECHERCHER_USER_CLIENT +
													LiensInternes.ADMIN
														.DETAILS_USER_CLIENT +
													"/" +
													d.id									  
											);
										},
									},
								]}
							/>
						);
					}
				})}
			</div>

			{openModaleUpdate && (
				<ModaleWithInput
					title={"Changer le role"}
					type={"role"}
					idDataSelectionne={stateSelectionne}
					tableauDeValeur={dataUpdate}
					fonctionSetObject={setDataUpdate}
					actionValider={changeRoleClient}
					openModale={openModaleUpdate}
					fonctionOpenModale={setOpenModaleUpdate}
				/>
			)}
		</div>
	);
}

export default RerchercheUserClient;
