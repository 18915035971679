import React from "react";
import DropZoneComponent from "../components/dropzone_component/DropZoneComponent";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	AlertTitle,
	Button,
	List,
	ListItem,
	ListItemText,
	Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../services/traduction/i18n";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ImportByFile = () => {
	const { t } = useTranslation();

	const liste = [
		{
			titre: "Index",
			explications: "L'index permet de traiter les erreurs",
		},
		{
			titre: "Email - Nom - Prénom",
			explications:
				"Ces champs sont obligatoires afin d'avoir toutes les informations des utilisateurs en base",
		},
		{
			titre: "Commande",
			explications:
				"La commande est obligatoire car l'utilisateur doit être relié à une commande, attention : la commande va être rentrée en base de données, si une commande apparait plusieurs fois dans le fichier, il faut que le nom de la commande soit EXACTEMENT la même(majuscule au même endroit, espace au même endroit...). Si le nom de la commande n'est pas exactement la même il y aura plusieurs commandes avec des noms différents dans la base de données",
		},
		{
			titre: "Lieu",
			explications:
				"Les lieux sont déjà dans la base de donnée, il faut récupérer le nom du lieu et le copier coller dans le champ du fichier csv",
		},
		{
			titre: "Matériel",
			explications:
				"Le matériel n'est pas obligatoire, mais si il y a un nouveau matériel il doit obligatoirement avoir un ancien avec le même #",
		},
		{
			titre: "Type de matériel",
			explications:
				"Le type de matériel est contenu en base, comme les lieux il faut donc récupérer le type en base de données et l'ajouter dans le fichier csv",
		},
	];

	return (
		<div
			className="box"
			style={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<h1 className="header" style={{ color: "white" }}>
				{t("IMPORT_FILE.title")}
			</h1>
			{/* <DragDropFilePattern /> */}
			{/* <div style={{ background: "red", height: "5px", width: '100%' }}></div> */}
			<div
				style={{
					background: "white",
					width: "800px",
					padding: "20px 30px",
					marginBottom: "20px",
					borderRadius: "10px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<p style={{ textAlign: "center" }}>
					{t("IMPORT_FILE.template_text")}
				</p>

				<Alert
					severity="warning"
					sx={{
						margin: "10px",
					}}
				>
					<AlertTitle>
						Champs obligatoires dans le fichier csv
					</AlertTitle>
					Ce fichier s'applique<strong> à tous les clients</strong>,
					les champs ne sont pas spécifiques à un seul.
					<Accordion
						sx={{
							backgroundColor: "transparent",
							boxShadow: "none",
							position: "static",
						}}
					>
						<AccordionSummary
							expandIcon={<KeyboardArrowDownIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>Voir plus</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<List>
								{liste.map((l: any) => (
									<ListItem>
										<ListItemText
											primary={l.titre}
											secondary={l.explications}
										/>
									</ListItem>
								))}
							</List>
						</AccordionDetails>
					</Accordion>
				</Alert>
				<Button variant="contained">
					<Link
						to="/template.csv"
						target="_blank"
						download
						style={{ color: "inherit", textDecoration: "inherit" }}
					>
						{t("IMPORT_FILE.template_button")}
					</Link>
				</Button>
			</div>
			<DropZoneComponent type="client" />
		</div>
	);
};

export default ImportByFile;
