import {LoggedAction } from "../Interfaces/typeLogged";
import axios from "axios";
import { routesApi } from "../../services/RoutesApi"
import { IUser, UserAction } from "../Interfaces/typeUser";
import { Dispatch } from "redux";
import { TYPES } from "./actionTypes";
import CustomAxios from "../../services/Interceptor/CustomAxios";
import { navigateCustom } from "../../services/Navigate";
import LiensInternes from "../../services/LiensInternes";
import { ErrorAction, IError } from "../Interfaces/typeError";

let baseUrl = process.env.REACT_APP_URL_BACK;

const connexion = (data: any, navigate?: any) => {
  return (dispatch : Dispatch) => {
    axios
      .post(baseUrl + routesApi.LOGIN, data)
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("accessToken", response.data.access_token);
          localStorage.setItem(
            "refreshToken",
            response.data.refresh_token

          );
          const actionLogged: LoggedAction = {
            type: TYPES.CONNEXION.LOGGED,
            logged : true,
          }
          
          dispatch(actionLogged);
          const newUser : IUser = {
            id : response.data.user.id,
            email : response.data.user.email,
            prenom : response.data.user.prenom,
            nom : response.data.user.nom,
            telephone : response.data.user.telephone,
            role : response.data.roles,
            client : response.data.user.client,
            commandes : response.data.user.commandes ? response.data.user.commandes : [],
            rdvs : response.data.user.rdvs ? response.data.user.rdvs : [],
          }
          const user: UserAction = {
            type: TYPES.USER.ADD,
            user : newUser,
          }
          dispatch(user);
          navigateCustom(response.data.roles, navigate)
        }
      })
      .catch(function (error) {
        const errorDispatch : IError = {
          error : error.response.data,
          status : error.response.status
        }
        const actionError: ErrorAction = {
          type: TYPES.ERROR.SET,
          error : errorDispatch,
        }
        dispatch(actionError);
      });
  };
};

const deconnexion = ( navigate?: any) => {
  return (dispatch : Dispatch) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem(
      "refreshToken",
    );

    const newUser : IUser = {
      id: 0,
      email: "",
      prenom: "",
      nom: "",
      telephone: "",
      client : [],
      role : [],
      commandes: [],
      rdvs: [],
    }
    const user: UserAction = {
      type: TYPES.USER.ADD,
      user : newUser,
    }
    dispatch(user);

    const actionLogged: LoggedAction = {
      type: TYPES.CONNEXION.LOGGED,
      logged : false,
    }    
    dispatch(actionLogged);

    navigate(LiensInternes.CONNEXION)

  };
};

const checkLogged = (navigate?: any) => {
  return (dispatch : Dispatch) => {
    CustomAxios
      .get(baseUrl + routesApi.DASHBOARD)
      .then(function (response) {
        
        if (response.status === 200) {
          const actionLogged: LoggedAction = {
            type: TYPES.CONNEXION.LOGGED,
            logged : true,
          }
          const roles : string[] = []
          response.data.role.map((role : any) => roles.push(role.name))          
          dispatch(actionLogged);
          const newUser : IUser = {
            id : response.data.id,
            email : response.data.email,
            prenom : response.data.prenom,
            nom : response.data.nom,
            telephone : response.data.telephone,
            role : roles,
            client : response.data.client,
            commandes : response.data.commandes ? response.data.commandes : [],
            rdvs : response.data.rdvs ? response.data.rdvs : [],
          }
          const user: UserAction = {
            type: TYPES.USER.ADD,
            user : newUser,
          }
          dispatch(user);
        }
      })
  };
};

export const loggedActions = {
  connexion, checkLogged, deconnexion
} 