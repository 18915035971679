import CustomAxios from "../../services/Interceptor/CustomAxios";
import { ClientDispatch, ClientAction, Client } from "../Interfaces/typeClient";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"

let baseUrl = process.env.REACT_APP_URL_BACK;


const getClient = () => {

  return (dispatch: ClientDispatch) => {
    setTimeout(() => {
      //APPEL API
      CustomAxios.get(baseUrl + routesApi.GET_CLIENT).then((response) => {
        //SET ACTION
        const action: ClientAction = {
            // TODO : Changer User et mettre client
          type: TYPES.CLIENT.GET,
          client: response.data,
        }
        //DISPATCH ACTION
        dispatch(action)
      });
    }, 500)
  }
};

const add = (client: Client) => {

    return (dispatch: ClientDispatch) => {
      setTimeout(() => {
        //APPEL API
        CustomAxios.put(baseUrl + routesApi.GET_CLIENT, client).then((response) => {
          //SET ACTION
          const action: ClientAction = {
            type: TYPES.CLIENT.ADD,
            client: response.data,
          }
          //DISPATCH ACTION
          dispatch(action)
        });
      }, 500)
    }
};

const deleteOne = (client: Client) => {

    return (dispatch: ClientDispatch) => {
      setTimeout(() => {
        //APPEL API
        //TODO Trouver une solution pour ajouter l'id du client en question
        CustomAxios.delete(baseUrl + routesApi.GET_CLIENT).then((response) => {
          //SET ACTION
          const action: ClientAction = {
            type: TYPES.CLIENT.DELETE,
            client: response.data,
          }
          //DISPATCH ACTION
          dispatch(action)
        });
      }, 500)
    }
};

const edit = (client: Client) => {

    return (dispatch: ClientDispatch) => {
      setTimeout(() => {
        //APPEL API
        CustomAxios.post(baseUrl + routesApi.GET_CLIENT, client.id).then((response) => {
          //SET ACTION
          const action: ClientAction = {
            type: TYPES.CLIENT.EDIT,
            client: response.data,
          }
          //DISPATCH ACTION
          dispatch(action)
        });
      }, 500)
    }
};

export const clientActions = {
  getClient,
  add,
  deleteOne,
  edit
} 