import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import OptionsCheckbox from "./OptionsCheckbox";

interface ICheckboxPatternProps {
	data: [
		{
			label: string;
			value: string;
		}
	];
	dataBdd: any;
	labelData: string;
	setData: {};
}

const CheckboxPattern: React.FC<ICheckboxPatternProps> = ({
	data,
	dataBdd,
	labelData,
	setData,
}) => {
	return (
		<FormGroup>
			{data?.map((d) => (
				<FormControlLabel
					control={
						<OptionsCheckbox
							data={d}
							dataBdd={dataBdd}
							labelData={labelData}
							setData={setData}
						/>
					}
					label={d.label}
					labelPlacement={"end"}
				/>
			))}
		</FormGroup>
	);
};

export default CheckboxPattern;
