export function calculPlageHoraire(creneaux) {
	//SORTIR LE PREMIER DE LA LISTE
	let dateDebut = "";
	let id = creneaux[0].id;
	creneaux.map((creneau) => {
		if (id > creneau.id) {
			id = creneau.id;
			dateDebut = creneau.dateDebut;
		}
	});

	const creneauJournee = [];
	creneaux.map((creneau) => {
		if (dateDebut.slice(0, 10) === creneau.dateDebut.slice(0, 10))
			creneauJournee.push(creneau);
	});
	let lastElement = creneauJournee[creneauJournee.length - 1];
	const tableauHoraire = {
		heureDebut: dateDebut.slice(11, 19),
		heureFin: lastElement?.dateDebut.slice(11, 19),
	};
	return tableauHoraire;
}

export function calculDuree(plage) {
	const hours = Math.floor(plage / 60);
	const minutes = plage % 60;
	if (minutes === 0) {
		return "0"+ hours + ":0" + minutes + ":00"} 
	else {
		return "0"+ hours + ":" + minutes + ":00"
	}
}
