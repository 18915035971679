
import { combineReducers } from "redux";
import userReducer from "./userReducer";
import loggedReducer from "./loggedReducer";
import uploadFileReducer from "./uploadFileReducer";
import interventionReducer from "./interventionReducer";
import commandesReducer from "./commandesReducer";
import lieuxReducer from "./lieuxReducer";
import periodesInterventionReducer from "./periodesInterventionReducer";
import techniciensReducer from "./techniciensReducer";
import creneauxReducer from "./creneauxReducer";
import clientReducer from "./clientReducer";
import { CreneauxState } from "../Interfaces/typeCreneaux";
import { TechniciensState } from "../Interfaces/typeTechniciens";
import { UserState } from "../Interfaces/typeUser";
import { LoggedState } from "../Interfaces/typeLogged";
import { TypeInterventionState } from "../Interfaces/typeIntervention";
import currentClientReducer from "./currentClientReducer";
import { CurrentClientState } from "../Interfaces/typeCurrentClient";
import { PeriodeInterventionState } from "../Interfaces/typePeriodesIntervention";
import { LoadingState } from "../Interfaces/typeLoading";
import crudReducer from "./crudReducer";
import loadingReducer from "./loadingReducer";
import { ErrorState } from "../Interfaces/typeError";
import errorReducer from "./ErrorReducer";
import roleReducer from "./roleReducer";
import { RoleState } from "../Interfaces/typeRole";
import { LieuxState } from "../Interfaces/typeLieux";
import typeMaterielReducer from "./TypeMaterielReducer";
import { TypeMaterielState } from "../Interfaces/typeTypeMateriel";
import RdvReducer from "./rdvReducer";
import { RdvState } from "../Interfaces/typeRdv";
import statistiquesReducer from "./statistiquesReducer";
import { StatistiquesState } from "../Interfaces/typeStatistiques";

export interface IRedux {
	creneauxReduxState: CreneauxState;
	techniciensReduxState: TechniciensState;
	userReduxState: UserState;
	loggedReduxState: LoggedState;
	loadingState: LoadingState;
	roleReduxState: RoleState;
	rdvReduxState: RdvState;
  lieuxReduxState: LieuxState,
  typeMaterielReduxState: TypeMaterielState,
  typeInterventionReduxState : TypeInterventionState
  currentClient : CurrentClientState
  periodesInterventionReduxState : PeriodeInterventionState
  crudState : CrudState
  statistiquesState : StatistiquesState
  errorState : ErrorState
}

const rootReducer = combineReducers({
	userReduxState: userReducer,
	clientReduxState: clientReducer,
	loggedReduxState: loggedReducer,
	loadingState: loadingReducer,
	uploadFileReduxState: uploadFileReducer,
	typeInterventionReduxState: interventionReducer,
	commandesReduxState: commandesReducer,
	lieuxReduxState: lieuxReducer,
	typeMaterielReduxState: typeMaterielReducer,
	roleReduxState: roleReducer,
	rdvReduxState: RdvReducer,
	periodesInterventionReduxState: periodesInterventionReducer,
	techniciensReduxState: techniciensReducer,
	creneauxReduxState: creneauxReducer,
	currentClient: currentClientReducer,
	crudState: crudReducer,
	statistiquesState: statistiquesReducer,
  errorState : errorReducer
});

export default rootReducer;
