import CustomAxios from "../../services/Interceptor/CustomAxios";
import { TypeInterventionDispatch, InterventionAction, IIntervention } from "../Interfaces/typeIntervention";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"
import { Dispatch } from "redux";
import { ErrorAction, IError } from "../Interfaces/typeError";

let baseUrl = process.env.REACT_APP_URL_BACK;
const getTypeInterventions = () => {

  return (dispatch: TypeInterventionDispatch) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_INTERVENTIONS)
      .then(function (response) {
        if (response.status === 200) {
          const action: InterventionAction = {
            type: TYPES.INTERVENTION.GET,
            intervention: response.data
          }
          dispatch(action)
        }
      })
  }
}

const addTypeIntervention = (typeInterventionToSend: any) => {
  return (dispatch: Dispatch) => {
      CustomAxios
          .put(baseUrl + routesApi.GET_INTERVENTIONS, typeInterventionToSend)
          .then(function (response) {
              if (response.status === 200) {
                  const typeInter : IIntervention = {
                    type: response.data.type,
                    duree: response.data.duree,
                    id: response.data.id,
                  }
                  const action: InterventionAction = {
                    type: TYPES.INTERVENTION.ADD,
                    intervention: typeInter
                  }
                  dispatch(action)
              }
          })
  }
}
const editTypeIntervention = (typeInterventionToEdit: any) => {

  return (dispatch: Dispatch) => {
    CustomAxios
        .post(baseUrl + routesApi.GET_INTERVENTIONS, typeInterventionToEdit)
        .then(function (response) {
          if (response.status === 200 && response.data.statusCode !== 403) {
            const data : IIntervention = response.data
              const action: InterventionAction = {
                  type: TYPES.INTERVENTION.UPDATE,
                  intervention: data
              }
              dispatch(action)
          }
          if (response.data.statusCode === 403) {
            const errorDispatch : IError = {
              error : response.data.message,
              status : response.data.statusCode
            }
            const actionError: ErrorAction = {
              type: TYPES.ERROR.SET,
              error : errorDispatch,
            }
              dispatch(actionError)
          }
        })
}
};


export const typeInterventionActions = {
  getTypeInterventions,
  addTypeIntervention,
  editTypeIntervention,
} 