import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

interface IModaleConfirmRecap {
	title: string;
	data: any;
	details?: any;
	actionValider: Function;
	actionAbsenceClient?: Function;
	openModale: boolean;
	fonctionOpenModale: Function;
}

const style = {
	position: "absolute" as const,
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 600,
	bgcolor: "background.paper",
	borderRadius: '10px',
	boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
	p: 4,
};

function ModaleConfirmRecap({
	title,
	data,
	details,
	openModale,
	fonctionOpenModale,
	actionValider,
	actionAbsenceClient,
}: IModaleConfirmRecap) {
	return (
		<div>
			<Modal
				open={openModale}
				onClose={() => fonctionOpenModale(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
						sx={{ fontWeight: 'bold', textAlign: 'center', mb: '5%' }}
					>
						{title}
					</Typography>
						{details}
					<Typography
						sx={{ display: 'flex', justifyContent: 'space-between', m: '1%' }}
					>
						{title === "Supprimer ce créneau ?" ?
							<>
								<Button
									variant="contained"
									onClick={() => {
										actionValider(data);
										fonctionOpenModale(false);
									}}
								>
									Supprimer
								</Button>
								<Button
									variant="outlined"
									onClick={() => fonctionOpenModale(false)}
								>
									Annuler
								</Button>
							</> :
							<><Button
								variant="contained"
								onClick={() => {
									actionValider(data[0]);
									fonctionOpenModale(false);
									setTimeout(() => window.location.reload(), 1000);
								}}
							>
								Valider rendez-vous
							</Button><Button
								variant="outlined"
								onClick={() => {
									if (actionAbsenceClient) {
										actionAbsenceClient(data[0]);
										setTimeout(() => window.location.reload(), 1000);
									}
									fonctionOpenModale(false);	
								}}
							>
									Absence du client
								</Button></>
						}</Typography>
				</Box>
			</Modal>
		</div>
	);
}

export default ModaleConfirmRecap;
