import { TYPES } from "../actions/actionTypes";
import { IRdv, RdvAction } from "../Interfaces/typeRdv";

const initialState: [] = []

const RdvReducer = (state = initialState, action: RdvAction) => {
  switch (action.type) {
    case TYPES.RDV.GET:
      return action.rdv
    case TYPES.RDV.CHECK_RDV:
      return action.rdv
    case TYPES.RDV.ABSENCE_RDV:
      return state.map((rdv: IRdv) => {
        if (action.rdv.id === rdv.id) {
          return action.rdv
        }
        return rdv
      })
    case TYPES.RDV.VALIDER:
      return state.map((rdv: IRdv) => {
        if (action.rdv.id === rdv.id) {
          return action.rdv
        }
        return rdv
      })
    default:
      return state
  }
}

export default RdvReducer