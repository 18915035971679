import { Doughnut } from "react-chartjs-2";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Typography,
	useTheme,
} from "@mui/material";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import TabletIcon from "@mui/icons-material/Tablet";
import BuildIcon from "@mui/icons-material/Build";
import { Chart, ArcElement } from "chart.js";
import { useSelector } from "react-redux";
import { IRedux } from "../../redux/reducers";

const InterventionsByType = () => {
	const theme = useTheme();
	Chart.register(ArcElement);

	const stat = useSelector(
		(state: IRedux) =>
			state.statistiquesState.tableaudeBord.periodeInterventionCamembert
	);
	const total = stat?.Dépanage + stat?.Installation + stat?.Réparation;

	const data = {
		datasets: [
			{
				data: [stat?.Dépanage, stat?.Installation, stat?.Réparation],
				backgroundColor: ["#EA5863", "#FE9063", "#27C7D4"],
				borderWidth: 8,
				borderColor: "#FFFFFF",
				hoverBorderColor: "#FFFFFF",
			},
		],
		labels: Object?.keys(stat),
	};

	const options = {
		// animation: true,
		cutoutPercentage: 80,
		layout: { padding: 0 },
		legend: {
			display: false,
		},
		maintainAspectRatio: false,
		responsive: true,
		tooltips: {
			backgroundColor: theme.palette.background.paper,
			bodyFontColor: theme.palette.text.secondary,
			borderColor: theme.palette.divider,
			borderWidth: 1,
			enabled: true,
			footerFontColor: theme.palette.text.secondary,
			intersect: false,
			mode: "index",
			titleFontColor: theme.palette.text.primary,
		},
	};

	const devices = [
		{
			title: "Installation",
			value: ((stat?.Installation / total) * 100).toFixed(2),
			icon: LaptopMacIcon,
			color: "#FE9063",
		},
		{
			title: "Dépanage",
			value: ((stat?.Dépanage / total) * 100).toFixed(2),
			icon: TabletIcon,
			color: "#EA5863",
		},
		{
			title: "Réparation",
			value: ((stat?.Réparation / total) * 100).toFixed(2),
			icon: BuildIcon,
			color: "#27C7D4",
		},
	];

	return (
		<Card sx={{ height: "100%" }}>
			<CardHeader title="Nombre d'interventions par type d'opération" />
			<Divider />
			<CardContent>
				<Box
					sx={{
						height: 295,
						position: "relative",
					}}
				>
					<Doughnut data={data} options={options} />
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						pt: 2,
					}}
				>
					{devices.map(({ color, icon: Icon, title, value }) => (
						<Box
							key={title}
							sx={{
								p: 1,
								textAlign: "center",
							}}
						>
							<Icon color="action" />
							<Typography color="textPrimary" variant="body1">
								{title}
							</Typography>
							<Typography style={{ color }} variant="h4">
								{value}%
							</Typography>
						</Box>
					))}
				</Box>
			</CardContent>
		</Card>
	);
};

export default InterventionsByType;
