import { Alert, AlertColor, Snackbar, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ErrorActions } from "../../redux/actions/errorActions";
import { IRedux } from "../../redux/reducers";

function SnackBarPattern() {
	const [open, setOpen] = React.useState(false);
	const [severity, setSeverity] = React.useState<AlertColor>("success");

	const dispatch = useDispatch();
	const { deleteError } = bindActionCreators(ErrorActions, dispatch);
	const error: any = useSelector((state: IRedux) => state?.errorState);

	useEffect(() => {
		error.status !== 0 && setOpen(true);
		error.status !== 0 && error.status === 200
			? setSeverity("success")
			: setSeverity("error");
	}, [error]);

	setTimeout(function () {
		error.status !== 0 && deleteError();
	}, 70000);

	const handleClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	return (
		<Stack spacing={2} sx={{ width: "100%" }}>
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert
					onClose={handleClose}
					severity={severity}
					sx={{ width: "100%" }}
				>
					{error.error}
				</Alert>
			</Snackbar>
		</Stack>
	);
}

export default SnackBarPattern;
