import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import ImportCsvLieu from "../pages/superviseurClient/ImportCsvLieu";
import SuperviseurClientRdvLieu from "../pages/superviseurClient/SuperviseurClientRdvLieu";
import { IRedux } from "../redux/reducers";
import LiensInternes from "../services/LiensInternes";

function LayoutSuperviseurClient() {
	const logged: boolean = useSelector(
		(state: IRedux) => state?.loggedReduxState
	);

	return (
		<div>
			{logged && (
				<Routes>
					<Route
						path="/"
						element={
							<Navigate
								to={
									LiensInternes.SUPERVISEUR_CLIENT.BASE +
									LiensInternes.SUPERVISEUR_CLIENT
										.TABLEAU_DE_BORD
								}
							/>
						}
					/>

					<Route
						path={LiensInternes.SUPERVISEUR_CLIENT.TABLEAU_DE_BORD}
						element={<Dashboard />}
					/>
					<Route
						path={LiensInternes.SUPERVISEUR_CLIENT.RDV_LIEU}
						element={<SuperviseurClientRdvLieu />}
					/>
					<Route
						path={LiensInternes.SUPERVISEUR_CLIENT.CSV_ADD_LIEU}
						element={<ImportCsvLieu />}
					/>
				</Routes>
			)}
		</div>
	);
}

export default LayoutSuperviseurClient;
