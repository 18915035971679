import React, { useEffect, useState } from "react";
import "./App.css";
import "./services/traduction/i18n";
import {
	Routes,
	Route,
	useNavigate,
	Outlet,
} from "react-router-dom";

import { Contact } from "./pages";
import PasswordCreationPage from "./pages/PasswordCreationPage";

import { useSelector, useDispatch } from "react-redux";
import LoginForm from "./pages/forms/LoginForm";
import AppointmentUser from "./pages/AppointmentUser";
import { IRedux } from "./redux/reducers";
import { bindActionCreators } from "redux";
import { loggedActions } from "./redux/actions/loggedActions";
import LayoutPrivee from "./layout/LayoutPrivee";
import LiensInternes from "./services/LiensInternes";
import { navigateCustom } from "./services/Navigate";
import "tippy.js/dist/tippy.css";
import SnackBarPattern from "./components/errorPattern/SnackBarPattern";
import LayoutSuperviseurClient from "./layout/LayoutSuperviseurClient";
import DetailsProfil from "./pages/DetailsProfil";
import { IUser } from "./redux/Interfaces/typeUser";
import TechnicienDashboard from "./pages/technicien/TechnicienDashboard";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import NavBarHeader from "./components/NavBar/NavBarHeader/NavBarHeader";
import NavBarTableauDeBord from "./components/NavBar/NavBarTableauDeBord/NavBarTableauDeBord";
import Footer from "./components/footer/Footer";
import ResetPasswordPage from "./pages/ResetPasswordPage";

const drawerWidth = 280;

function App() {
	//REGARDER SI LE TOKEN EST ENCORE VALIDE
	localStorage.setItem("enCours", "false");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let frontUrl = process.env.REACT_APP_URL_FRONT;
	const { checkLogged } = bindActionCreators(loggedActions, dispatch);

	const roles: string[] = useSelector(
		(state: IRedux) => state?.userReduxState.role
	);

	const [displayNavBarDashboard, setDisplayNavBarDashboard] = useState(false);
	useEffect(() => {
		if (
			roles.includes("AdminComNetWork") ||
			roles.includes("ComNetWorkSupervisor") ||
			roles.includes("ClientSupervisor")
		) {
			setDisplayNavBarDashboard(true);
		}
		if (roles.length === 0) {
			setDisplayNavBarDashboard(false);
		}
	}, [roles]);

	useEffect(() => {
		if (
			localStorage.getItem("accessToken") &&
			localStorage.getItem("refreshToken") &&
			!window.location.href.includes("/activation/") &&
			!window.location.href.includes("/reset-password/")
		) {
			checkLogged(navigate);
		}
		if (
			!localStorage.getItem("accessToken") &&
			!localStorage.getItem("refreshToken") &&
			!window.location.href.includes("/activation/") &&
			!window.location.href.includes("/reset-password/")
		) {
			navigate(LiensInternes.CONNEXION);
		}
	}, []);

	useEffect(() => {
		if (window.location.href === frontUrl && roles.length > 0) {
			navigateCustom(roles, navigate);
		}
	}, [roles]);

	const userSelected: IUser = useSelector(
		(state: IRedux) => state?.userReduxState
	);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					background: "#1F3C8B",
					minHeight: "95vh",
					width: "100%",
				}}
			>
				<CssBaseline />
				{/* HEADER  */}
				<NavBarHeader />
				{displayNavBarDashboard && <NavBarTableauDeBord />}
				<Box
					component="main"
					sx={{
						flexGrow: 1,
						p: 3,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Toolbar />
					<Routes>
						<Route path="/">
							<Route
								path={LiensInternes.ADMIN.BASE + "/*"}
								element={<LayoutPrivee />}
							/>
							<Route
								path={
									LiensInternes.SUPERVISEUR_CLIENT.BASE + "/*"
								}
								element={<LayoutSuperviseurClient />}
							/>
							<Route
								path={LiensInternes.PROFIL}
								element={<DetailsProfil user={userSelected} />}
							/>
						</Route>
						<Route
							path={LiensInternes.PRISE_RDV}
							element={<AppointmentUser />}
						/>
						<Route
							path={LiensInternes.TECHNICIEN}
							element={<TechnicienDashboard />}
						/>
						<Route
							path={LiensInternes.CONTACT}
							element={<Contact />}
						/>
						<Route
							path={LiensInternes.CONNEXION}
							element={<LoginForm />}
						/>
						<Route
							path="/reset-password/:id"
							element={<ResetPasswordPage />}
						/>
						<Route
							path="/activation/:id"
							element={<PasswordCreationPage />}
						/>
						{/* <Route path="*" element={<NoPage />} /> */}
						{/* <Route path="*" element={<Navigate to="/" replace />} /> */}
					</Routes>

					<Outlet />
				</Box>
			</Box>
			{/* FOOTER */}
			<Footer />

			<SnackBarPattern />
		</>
	);
}
export default App;
