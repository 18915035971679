import CustomAxios from "../../services/Interceptor/CustomAxios";
import { DispatchTypeTechnicien, TechniciensAction } from "../Interfaces/typeTechniciens";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"

let baseUrl = process.env.REACT_APP_URL_BACK;
const getTechniciens = () => {

  return (dispatch: DispatchTypeTechnicien) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_TECHNICIENS)
      .then(function (response) {
        if (response.status === 200) {
          const action: TechniciensAction = {
            type: TYPES.TECHNICIENS.GET,
            technicien: response.data
          }
          dispatch(action)
        }
      })
  }
}


export const techniciensActions = {
  getTechniciens,
} 