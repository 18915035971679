import { Bar } from "react-chartjs-2";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	useTheme,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Chart as ChartJS, registerables } from "chart.js";
import { useSelector } from "react-redux";
import { IRedux } from "../../redux/reducers";
import { toDateString } from "../../services/FormatDate";
ChartJS.register(...registerables);
const InterventionsByDate = () => {
	const theme = useTheme();

	const stat = useSelector(
		(state: IRedux) => state.statistiquesState.tableaudeBord.graphiqueRdvs
	);

	const rdvPropose = stat?.map((s: any) => {
		return s.rdvPropose;
	});
	const rdvPris = stat?.map((s: any) => {
		return s.rdvPris;
	});

	const rdvHonore = stat?.map((s: any) => {
		return s.rdvHonore;
	});

	const rdvRateComNet = stat?.map((s: any) => {
		return s.rdvRateComNet;
	});

	const rdvRateClient = stat?.map((s: any) => {
		return s.rdvRateClient;
	});

	const label = stat?.map((s: any) => {
		return toDateString(s.date);
	});
	const data = {
		datasets: [
			{
				backgroundColor: "#03045F",
				barPercentage: 0.5,
				barThickness: 12,
				borderRadius: 4,
				categoryPercentage: 0.5,
				data: rdvPropose.reverse(),
				label: "RDV proposés",
				maxBarThickness: 10,
			},
			{
				backgroundColor: "#0078B8",
				barPercentage: 0.5,
				barThickness: 12,
				borderRadius: 4,
				categoryPercentage: 0.5,
				data: rdvPris.reverse(),
				label: "Nombre de RDV pris",
				maxBarThickness: 10,
			},
			{
				backgroundColor: "#00B6DA",
				barPercentage: 0.5,
				barThickness: 12,
				borderRadius: 4,
				categoryPercentage: 0.5,
				data: rdvHonore.reverse(),
				label: "RDV honorés",
				maxBarThickness: 10,
			},
			{
				backgroundColor: "#93E1F0",
				barPercentage: 0.5,
				barThickness: 12,
				borderRadius: 4,
				categoryPercentage: 0.5,
				data: rdvRateComNet.reverse(),
				label: "RDV ratés par ComNetwork",
				maxBarThickness: 10,
			},
			{
				backgroundColor: "#ADD4F3",
				barPercentage: 0.5,
				barThickness: 12,
				borderRadius: 4,
				categoryPercentage: 0.5,
				data: rdvRateClient.reverse(),
				label: "RDV ratés par les bénéficiaires",
				maxBarThickness: 10,
			},
		],
		labels: label.reverse(),
	};

	const options = {
		// animation: false,
		cornerRadius: 20,
		layout: { padding: 0 },
		legend: { display: false },
		maintainAspectRatio: false,
		responsive: true,
		xAxes: [
			{
				ticks: {
					fontColor: theme.palette.text.secondary,
				},
				gridLines: {
					display: false,
					drawBorder: false,
				},
			},
		],
		yAxes: [
			{
				ticks: {
					fontColor: theme.palette.text.secondary,
					beginAtZero: true,
					min: 0,
				},
				gridLines: {
					borderDash: [2],
					borderDashOffset: [2],
					color: theme.palette.divider,
					drawBorder: false,
					zeroLineBorderDash: [2],
					zeroLineBorderDashOffset: [2],
					zeroLineColor: theme.palette.divider,
				},
			},
		],
		tooltips: {
			backgroundColor: theme.palette.background.paper,
			bodyFontColor: theme.palette.text.secondary,
			borderColor: theme.palette.divider,
			borderWidth: 1,
			enabled: true,
			footerFontColor: theme.palette.text.secondary,
			intersect: false,
			mode: "index",
			titleFontColor: theme.palette.text.primary,
		},
	};

	return (
		<Card>
			<CardHeader
				action={
					<Button
						endIcon={<ArrowDropDownIcon fontSize="small" />}
						size="small"
					>
						7 dernier jours
					</Button>
				}
				title="Derniers rendez-vous"
			/>
			<Divider />
			<CardContent>
				<Box
					sx={{
						height: 400,
						position: "relative",
					}}
				>
					<Bar data={data} options={options} />
				</Box>
			</CardContent>
			<Divider />
			<Box
				sx={{
					display: "flex",
					justifyContent: "flex-end",
					p: 2,
				}}
			>
				<Button
					color="primary"
					endIcon={<ArrowRightIcon fontSize="small" />}
					size="small"
				>
					Accéder à la liste complète
				</Button>
			</Box>
		</Card>
	);
};

export default InterventionsByDate;
