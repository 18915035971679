import CustomAxios from "../../services/Interceptor/CustomAxios";
import { DispatchPeriodeIntervention, PeriodesInterventionAction } from "../Interfaces/typePeriodesIntervention";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"

let baseUrl = process.env.REACT_APP_URL_BACK;

const addPeriodeIntervention = (dataToSend: any, commandesID: any, type : "rappel" | "new") => {
  let url = ""

  switch (type) {
    case 'new':
      url = routesApi.ADD_PERIODE_INTERVENTION + `?id_commande=${commandesID.map((id: number) => + id.toString()).join(',')}`
      break;
    case 'rappel':
      url = routesApi.ADD_PERIODE_INTERVENTION_RAPPEL
      break;
    default:
      console.log("erreur");
  }

  return (dispatch: DispatchPeriodeIntervention) => {
    CustomAxios
      .put(baseUrl + url,  dataToSend)
      .then(function (response) {
        if (response.status === 200) {
          const action: PeriodesInterventionAction = {
            type: TYPES.PERIODES_INTERVENTION.ADD,
            periodesIntervention: response.data
          }
          dispatch(action)
        }
      })
  }
}

const getPeriodesIntervention = (id: number, type : string) => {
  return (dispatch: DispatchPeriodeIntervention) => {
    let url = ""
    switch (type) {
      case 'client':
        url = '/by-client?id_client=' + id
        break;
      case 'client-validation':
        url = '/by-client-and-validate?id_client=' + id
        break;
      case 'user-com':
        url = '/by-user-com?id_user=' + id
        break;
      case 'client-user':
        url = '/by-client-user?id_client=' + id
        break;
      default:
        console.log("erreur");
    }

    CustomAxios
      .get(baseUrl + routesApi.GET_PERIODE_INTERVENTION + url)
      .then(function (response) {
        if (response.status === 200) {
          const action: PeriodesInterventionAction = {
            type: TYPES.PERIODES_INTERVENTION.GET,
            periodesIntervention: response.data
          }
          dispatch(action)
        }
      })
  }
}

const getOnePeriodesIntervention = (id: number) => {
  return (dispatch: DispatchPeriodeIntervention) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_ONE_PERIODE_INTERVENTION + "?id=" + id)
      .then(function (response) {
        if (response.status === 200) {
          const action: PeriodesInterventionAction = {
            type: TYPES.PERIODES_INTERVENTION.GET,
            periodesIntervention: response.data
          }
          dispatch(action)
        }
      })
  }
}

const getPeriodesInterventionByLieu = (id: number) => {
  return (dispatch: DispatchPeriodeIntervention) => {

    CustomAxios
      .get(baseUrl + routesApi.GET_PERIODE_INTERVENTION_LIEU + "?id_lieu=" +id)
      .then(function (response) {
        if (response.status === 200) {
          const action: PeriodesInterventionAction = {
            type: TYPES.PERIODES_INTERVENTION.GET,
            periodesIntervention: response.data
          }
          dispatch(action)
        }
      })
  }
}


const validerPeriodesIntervention = (id: number) => {
  return (dispatch: DispatchPeriodeIntervention) => {
    CustomAxios
      .post(baseUrl + routesApi.VALIDER_PERIODE_INTERVENTION + "?id_periode_intervention=" + id
      )
      .then(function (response) {
        if (response.status === 200) {
          const action: PeriodesInterventionAction = {
            type: TYPES.PERIODES_INTERVENTION.VALIDER,
            periodesIntervention: id
          }
          dispatch(action)
        }
      })
  }
}

const deletePeriodesIntervention = (id: number) => {
  return (dispatch: DispatchPeriodeIntervention) => {
    CustomAxios
      .post(baseUrl + routesApi.DELETE_PERIODE_INTERVENTION + "?id=" + id
      )
      .then(function (response) {
        if (response.status === 200) {
          const action: PeriodesInterventionAction = {
            type: TYPES.PERIODES_INTERVENTION.DELETE,
            periodesIntervention: id
          }
          dispatch(action)
        }
      })
  }
}


export const periodesInterventionActions = {
  addPeriodeIntervention,
  getPeriodesIntervention, 
  validerPeriodesIntervention, 
  deletePeriodesIntervention,
  getPeriodesInterventionByLieu,
  getOnePeriodesIntervention
} 