import {
	Avatar,
	Card,
	CardContent,
	Grid,
	Typography,
} from "@mui/material";

interface ICardDashboard {
	title: string | undefined;
	data: string;
	couleur: string;
	icon?: any;
}

function CardDashboard({ title, data, couleur, icon }: ICardDashboard) {
	return (
		<Card sx={{ height: "100%" }}>
			<CardContent>
				<Grid
					container
					spacing={3}
					sx={{ justifyContent: "space-between" }}
				>
					<Grid item xs={8}>
						<Typography
							color="textSecondary"
							gutterBottom
							variant="overline"
						>
							{title}
						</Typography>
						{/* <Typography color="textPrimary" variant="h4">
							{data}
						</Typography> */}
					</Grid>
					<Grid item xs={4}>
						<Avatar
							sx={{
								backgroundColor: couleur,
								height: 100,
								width: 100,
							}}
						>
							<Typography
								sx={{
									color: "#FFFFFF",
								}}
								variant="h5"
							>
								{data}
							</Typography>
						</Avatar>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

export default CardDashboard;
