import {
	Autocomplete,
	Box,
	Button,
	Step,
	StepLabel,
	Stepper,
	TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

interface IStepperPattern {
	steps: string[];
	render: any;
	dataOnChange: any;
}

function StepperPattern({ steps, render, dataOnChange }: IStepperPattern) {
	//Gestion de l'état des étapes d'assignation
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set<number>());
	const [disableNextButton, setDisableNextButton] = React.useState(true);

	//REGARDE SI LE BOUTON EN BAS A DROITE EST DISABLE OU PAS
	useEffect(() => {
		steps.length >= activeStep && setDisableNextButton(false);
		activeStep === 0 &&
			dataOnChange === undefined &&
			setDisableNextButton(true);
	}, [steps, dataOnChange]);

	const isStepSkipped = (step: number) => {
		return skipped.has(step);
	};

	//Fonction pour avancer à l'étape suivante du formulaire
	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};
	//Fonction pour revenir à l'étape précédente du formulaire
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<div>
			{/* Stepper */}
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const stepProps: { completed?: boolean } = {};
					const labelProps: {
						optional?: React.ReactNode;
					} = {};
					if (isStepSkipped(index)) {
						stepProps.completed = false;
					}
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			<br />

			{/* CONTENU */}

			{Object.values(render).map((val: any, i: number) => {
				return (
					<div
						style={{
							display: activeStep === i ? "block" : "none",
							width: "100%",
						}}
					>
						{val()}
					</div>
				);
			})}

			{/* BOUTON  */}
			<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
				<Button
					color="inherit"
					disabled={activeStep === 0}
					onClick={handleBack}
					sx={{ mr: 1 }}
				>
					Retour
				</Button>
				<Box sx={{ flex: "1 1 auto" }} />
				{/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
				{activeStep !== steps.length && (
					<Button onClick={handleNext} disabled={disableNextButton}>
						{activeStep === steps.length - 1
							? "Terminer"
							: "Suivant"}
					</Button>
				)}
			</Box>
		</div>
	);
}

export default StepperPattern;
