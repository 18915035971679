import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IModale } from "../model/Modale";
import { Grid } from "@mui/material";

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const Modale: React.FunctionComponent<IModale> = ({
	title,
	contenu,
	actionValider,
	openModale,
	fonctionOpenModale,
}) => {
	return (
		<div>
			<Modal
				open={openModale}
				onClose={() => {
					fonctionOpenModale(false);
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
					>
						{title}
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						{contenu}
					</Typography>

					<Grid
						container
						rowSpacing={1}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={6}>
							<Button
								variant="contained"
								onClick={() => actionValider()}
							>
								Valider
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								variant="contained"
								onClick={() => {
									fonctionOpenModale(false);
								}}
							>
								Fermer
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div>
	);
};

export default Modale;
