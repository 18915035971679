import { TYPES } from "../actions/actionTypes";
import { CurrentClientAction, CurrentClientState } from "../Interfaces/typeCurrentClient";

const initialState : CurrentClientState = {
  id : 0,
  nom : ""
}

const currentClientReducer = (
  state : CurrentClientState = initialState,
  action: CurrentClientAction
) => {
  switch (action.type) {
    case TYPES.CURRENT_CLIENT.ADD:
      return action.client ? action.client : null
  }
  return state
}

export default currentClientReducer
