import LiensInternes from "./LiensInternes";

export function navigateCustom(roles, navigate) {
	if (roles.includes("AdminComNetWork")) {
		return navigate(LiensInternes.ADMIN.BASE);
	}
	if (roles.includes("ComNetWorkSupervisor")) {
		return navigate(LiensInternes.ADMIN.BASE);
	}
	if (roles.includes("ClientSupervisor")) {
		return navigate(LiensInternes.SUPERVISEUR_CLIENT.BASE);
	}
	if (roles.includes("Technicien")) {
		return navigate(LiensInternes.TECHNICIEN);
	}
	if (roles.includes("ClientUser")) {
		return navigate(LiensInternes.PRISE_RDV);
	}
	return navigate(LiensInternes.CONNEXION);
}
