import CustomAxios from "../../services/Interceptor/CustomAxios";
import { TYPES } from "./actionTypes";
import { routesApi } from "../../services/RoutesApi"
import { ITypeMateriel, TypeMaterielAction, TypeMaterielDispatch } from "../Interfaces/typeTypeMateriel";
import { ErrorAction, IError } from "../Interfaces/typeError";
import { store } from "../../redux/store";
import { Dispatch } from "redux";

let baseUrl = process.env.REACT_APP_URL_BACK;
const getTypeMaterielByClient = (id_client: number) => {

  return (dispatch: TypeMaterielDispatch) => {
    CustomAxios
      .get(baseUrl + routesApi.GET_TYPE_MATERIEL_BY_CLIENT + "?id=" + id_client)
      .then(function (response) {
        if (response.status === 200) {
          const action: TypeMaterielAction = {
            type: TYPES.TYPE_MATERIEL.GET,
            typeMateriel: response.data
          }
          dispatch(action)
        }
      })
  }
}

const addTypeMateriel = (typeMaterielToSend: any) => {
  return (dispatch: TypeMaterielDispatch) => {
    CustomAxios
      .put(baseUrl + routesApi.TYPE_MATERIEL, typeMaterielToSend)
      .then(function (response) {
        if (response.status === 200) {
          const typeMateriel: ITypeMateriel = {
            type: response.data.type,
            id: response.data.id,
          }
          const action: TypeMaterielAction = {
            type: TYPES.TYPE_MATERIEL.ADD,
            typeMateriel: typeMateriel
          }
          dispatch(action)
        }
        if (response.status === 409) {
          const errorDispatch = {
            error: "Le type est déjà présent en base de données.",
            status: response.status,
          };
          const actionError = {
            type: TYPES.ERROR.SET,
            error: errorDispatch,
          };
          store.dispatch(actionError);
        }
        return response;
      })
  }
}

const editTypeMateriel = (typeMaterielToEdit: any) => {

  return (dispatch: Dispatch) => {
    CustomAxios
      .post(baseUrl + routesApi.TYPE_MATERIEL, typeMaterielToEdit)
      .then(function (response) {
        if (response.status === 200 && response.data.statusCode !== 403) {
          const data: ITypeMateriel = response.data
          const action: TypeMaterielAction = {
            type: TYPES.TYPE_MATERIEL.EDIT,
            typeMateriel: data
          }
          dispatch(action)
        }
        if (response.data.statusCode === 403) {
          const errorDispatch: IError = {
            error: response.data.message,
            status: response.data.statusCode
          }
          const actionError: ErrorAction = {
            type: TYPES.ERROR.SET,
            error: errorDispatch,
          }
          dispatch(actionError)
        }
      })
  }
};

const deleteTypeMateriel = (id: number) => {
  return (dispatch: TypeMaterielDispatch) => {
    CustomAxios
      .post(baseUrl + routesApi.DELETE_TYPE_MATERIEL + "?id=" + id
      )
      .then(function (response) {
        if (response.status === 200) {
          const action: TypeMaterielAction = {
            type: TYPES.TYPE_MATERIEL.DELETE,
            typeMateriel: id
          }
          dispatch(action)
        }
      })
  }
}

export const typeMaterielActions = {
  getTypeMaterielByClient,
  addTypeMateriel,
  editTypeMateriel,
  deleteTypeMateriel
} 