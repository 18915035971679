import React, { useEffect, useRef, useState } from "react";
import {
	Button,
	TextField,
	Stepper,
	Step,
	StepLabel,
	Box,
	Autocomplete,
	InputLabel,
	Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { crenauxActions } from "../redux/actions/creneauxActions";
import { bindActionCreators } from "redux";
import { IRedux } from "../redux/reducers";
import { AutoCompleteCommandes } from "../model/AutoCompleteCommandes";
import PlanningFullCalendarUserClient from "../components/planningPattern/PlanningFullCalendarUserClient";
import { userActions } from "../redux/actions/userActions";
import { IUser } from "../redux/Interfaces/typeUser";
import { toDateStringWithHour, toHourString } from "../services/FormatDate";
import { CreneauChoisi } from "../redux/Interfaces/typeCreneaux";
import { periodesInterventionActions } from "../redux/actions/periodesInterventionsActions";
import { loggedActions } from "../redux/actions/loggedActions";
import LiensInternes from "../services/LiensInternes";
import { useNavigate } from "react-router-dom";
import { rdvAction } from "../redux/actions/rdvActions";
import ModaleAppointment from "../components/ModaleAppointment";

const AppointmentUser = () => {
	const dispatch = useDispatch();

	//Récupération des différentes méthodes actions
	const { setTelephoneUser } = bindActionCreators(userActions, dispatch);
	const { addOneCreneauForUser, modifyOneCreneauForUser, getCreneauxForUser } = bindActionCreators(
		crenauxActions,
		dispatch
	);
	const { getPeriodesIntervention } = bindActionCreators(
		periodesInterventionActions,
		dispatch
	);
	const { checkRdvCommande } = bindActionCreators(
		rdvAction,
		dispatch
	);
	const { deconnexion } = bindActionCreators(loggedActions, dispatch);

	const navigate = useNavigate();

	//Gestion Utilisateur / Récupération des différentes informations de l'userClient après s'être login
	const userRedux = useSelector((state: IRedux) => state.userReduxState);
	const userCommandes: string[] = useSelector(
		(state: IRedux) => state?.userReduxState.commandes
	);
	const creneauxUserClient = useSelector(
		(state: IRedux) => state.creneauxReduxState
	);
	const checkRdv = useSelector(
		(state: IRedux) => state?.rdvReduxState
	);
	const [currentNumTel, setCurrentNumTel] = useState<string>("");
	const [creneauChoisi, setCreneauChoisi] = useState<any>([]);

	//Gestion  redirections
	useEffect(() => {
		if (
			userRedux.role.includes("AdminComNetWork") ||
			userRedux.role.includes("ComNetWorkSupervisor") ||
			userRedux.role.includes("Technicien")
		) {
			navigate(LiensInternes.CONNEXION);
		}
	}, []);

	//Gestion des périodes interventions
	const periodesInterventionReduxState = useSelector(
		(state: IRedux) => state.periodesInterventionReduxState
	);
	const [
		periodesInterventionSelectionnee,
		setPeriodesInterventionSelectionnee,
	] = React.useState<{}>({});
	const [idPeriodeInterventionSelectionee, setIdPeriodeInterventionSelectionee] = React.useState<number>(0);

	//Gestion Liste des différentes commandes (+ périodes) d'un userClient à afficher dans l'Autocomplete Step 1
	const [listOfCommandes, setListOfCommades] = React.useState<
		AutoCompleteCommandes[]
	>([]);

	const listOfCreneaux = useSelector(
		(state: IRedux) => state.creneauxReduxState.creneauHoraire
	);

	const [confirmCreneauxToUserClient, setConfirmCreneauxToUserClient] =
		React.useState<boolean>(false);

	//Gestion de l'état des étapes d'assignation
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set<number>());
	const [disableNextButton, setDisableNextButton] = React.useState(true);
	const [periodesIntervention, setPeriodesIntervention] = React.useState<
		number | undefined
	>(0);

	// Gestion de la modale après avoir fini de prendre rendez-vous
	const [openModale, setOpenModale] = React.useState(false);

	// Informations à afficher pour le STEP 2
	const [commandeChoisie, setCommandeChoisie] = React.useState<string | null>(
		""
	);
	let type = creneauxUserClient?.typeIntervention?.type;

	//Gestion période début et fin
	const [startPeriodeSelected, setStartPeriodSelected] =
		React.useState<string>("");
	const [endPeriodeSelected, setEndPeriodSelected] =
		React.useState<string>("");

	const isStepSkipped = (step: number) => {
		return skipped.has(step);
	};

	//Gestion des erreurs du formulaire
	const [errorForm, seterrorForm] = useState<{ [key: string]: string }>({});

	//Création d'un useRef pour éviter le first render du UseEffect au chargement de la page
	const didMountRef = useRef(false);

	//Les différents useEffect
	useEffect(() => {
		let listOfCommandesData: AutoCompleteCommandes[] = [];
		periodesInterventionReduxState?.map((onePeriode: any, key: number) => {
			let data = {
				label: onePeriode?.commande?.numero,
				id: onePeriode?.id,
				typeIntervention: onePeriode?.typeIntervention?.type,
			};
			listOfCommandesData.push(data);
			setListOfCommades(listOfCommandesData);
		});
	}, [periodesInterventionReduxState]);

	useEffect(() => {
		if (userRedux.id !== 0) {
			getPeriodesIntervention(userRedux.id, "client-user");
		}
	}, [userRedux.id]);

	useEffect(() => {
		// First render du UsefEffect,
		if (!didMountRef.current) {
			setCurrentNumTel(userRedux.telephone);

			// Si l'userClient se connecte et qu'il n'a aucune commande
			if (userRedux.commandes.length === 0) {
				navigate(LiensInternes.PROFIL);
			}

			// TODO : voir pour passer directement à l'étape 2
			// if (userRedux.commandes.length === 1) {
			// 	// setPeriodesIntervention()
			// 	periodesInterventionReduxState?.map((onePeriode: any, key: number) => {

			// 		setPeriodesInterventionSelectionnee(onePeriode);
			// 		getCreneauxForUser(onePeriode.id);
			// 		setStartPeriodSelected(onePeriode.dateDebut);
			// 		setEndPeriodSelected(onePeriode.dateFin);
			// 		setActiveStep(activeStep + 1)
			// 	}
			// 	);
			// }
		}

		//Après le first render du UseEffect,
		else {
			//On bloque le bouton "Suivant" si aucune sélection a été effectuée dans l'autocomplete
			if (
				periodesIntervention === undefined ||
				periodesIntervention === 0
			) {
				setDisableNextButton(true);
			}
			//On bloque si aucun créneau n'a été choisi
			else if (
				activeStep === 1 &&
				(creneauChoisi === undefined || creneauChoisi.length === 0)
			) {
				setDisableNextButton(true);
			} else if (activeStep === 2) {
				setDisableNextButton(true);
			} else if (activeStep === 3) {
				setOpenModale(true)
			} else {
				periodesInterventionReduxState?.forEach((onePeriode: any) => {
					if (onePeriode.id === periodesIntervention) {
						setIdPeriodeInterventionSelectionee(parseInt(onePeriode.id))
						setPeriodesInterventionSelectionnee(onePeriode);
						getCreneauxForUser(onePeriode.id);
						setStartPeriodSelected(onePeriode.dateDebut);
						setEndPeriodSelected(onePeriode.dateFin);
						checkRdvCommande(userRedux.id, parseInt(onePeriode.id))
					}
				});
				setDisableNextButton(false);
			}
		}

		//Au premier chargement, on passe le useRef à true pour activer les actions au changement d'état des States
		didMountRef.current = true;

		setConfirmCreneauxToUserClient(false);
	}, [activeStep, periodesIntervention, confirmCreneauxToUserClient]);

	//Fonction pour avancer à l'étape suivante du formulaire
	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	//Fonction pour revenir à l'étape précédente du formulaire
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	//Titres du spinner
	const steps = [
		"Sélection de la période d'intervention pour prendre rendez-vous",
		"Sélection du créneau et coordonnée téléphonique",
		"Récapitulatif et confirmation du rendez-vous",
	];

	//Fonction pour vérification de l'input Numéro Téléphone pour vérifier si le numéro saisi est valide
	function validationForm(): boolean {
		const errors: { [key: string]: string } = {};
		if (currentNumTel.length <= 9 || currentNumTel.length >= 11) {
			errors["numTel"] = "Veuillez saisir un numéro de téléphone valide";
		}
		seterrorForm(errors);
		if (Object.keys(errors).length === 0) {
			return true;
		} else {
			return false;
		}
	}

	//Fonction pour vérification + Envoie de l'interface User avec le numéro de téléphone valide
	function onSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		const numTelToSend: IUser = { ...userRedux, telephone: currentNumTel };
		// Si tout est bon on envoie à la fois le créneau et le numéro de téléphone + l'user peut cliquer sur "Terminer"
		if (validationForm()) {
			if (Number(checkRdv) === 0) {
				let creneauToSend: CreneauChoisi = {
					userClient: { id: userRedux.id },
					creneauHoraire: { id: creneauChoisi[0].id },
				};
				addOneCreneauForUser(creneauToSend);
			} else {
				let creneauToSend: CreneauChoisi = {
					id: checkRdv.id,
					userClient: { id: userRedux.id },
					creneauHoraire: { id: creneauChoisi[0].id,
					periodeIntervention: { id: idPeriodeInterventionSelectionee} },
				};
				modifyOneCreneauForUser(creneauToSend);
			}
			setTelephoneUser(numTelToSend);
			setDisableNextButton(false);
		}
	}
	return (
		<div
			style={{
				background: "white",
				margin: "20px",
				padding: "40px",
				borderRadius: "20px",
			}}
		>
			<h2>Bonjour {userRedux.nom + " " + userRedux.prenom},</h2>
			{/* Stepper */}
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const stepProps: { completed?: boolean } = {};
					const labelProps: {
						optional?: React.ReactNode;
					} = {};
					if (isStepSkipped(index)) {
						stepProps.completed = false;
					}
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>

			{/* STEP 1 */}
			<div
				style={{
					display: activeStep === 0 ? "block" : "none",
					width: "100%",
				}}
			>
				<div>
					<div
						style={{
							fontWeight: "bold",
							marginTop: "3%",
						}}
					>
						Pour commencer, choisissez sur quelle période
						d'intervention vous souhaitez prendre un rendez-vous :
					</div>
					<br />
					{userCommandes.length > 0 ? (
						<Autocomplete
							options={listOfCommandes}
							noOptionsText="Aucun rendez-vous proposé"
							sx={{ width: 300, marginLeft: "35%" }}
							renderOption={(props, option) => {
								return (
									<li {...props} key={option.id}>
										{option.label +
											" - " +
											option.typeIntervention}
									</li>
								);
							}}
							onChange={(
								event: any,
								option: AutoCompleteCommandes | null
							) => {
								setCommandeChoisie(option?.label || null);
								setPeriodesIntervention(option?.id);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Choix du rendez-vous"
									variant="outlined"
								/>
							)}
						/>
					) : (
						<div style={{ fontWeight: "bold", color: "red" }}>
							Vous n'avez aucun rendez-vous à prendre pour le
							moment.
						</div>
					)}
				</div>
			</div>
			<br />

			{/* STEP 2 */}
			<div
				style={{
					display: activeStep === 1 ? "block" : "none",
					width: "100%",
				}}
			>
				<h3 style={{ textAlign: "center" }}>
					Prise de rendez-vous pour la commande {commandeChoisie} pour
					un(e) {type}:
				</h3>
				<div
					style={{
						fontWeight: "bold",
						margin: "3%",
					}}
				>
					Choisissez maintenant votre créneau horraire pour votre
					rendez-vous avec le technicien :
				</div>
				<Box
					sx={{
						bgcolor: "background.paper",
						width: "100%",
						position: "relative",
						minHeight: 200,
					}}
				>
					<PlanningFullCalendarUserClient
						listOfCreneaux={listOfCreneaux}
						periodesInterventionSelectionnee={
							periodesInterventionSelectionnee
						}
						creneauxUserClient={creneauxUserClient}
						setCreneauChoisi={setCreneauChoisi}
						setActiveStep={setActiveStep}
						activeStep={activeStep}
					/>
				</Box>
			</div>

			{/* STEP 3 */}
			<div
				style={{
					display: activeStep === 2 ? "block" : "none",
					width: "100%",
				}}
			>
				<div
					style={{
						width: "100%",
						fontWeight: "bold",
					}}
				>
					<div
						style={{
							marginTop: "3%",
							display: "flex",
							flexDirection: "row",
							alignItems: "flex-start",
							justifyContent: "center",
						}}
					>
						<div style={{ marginRight: "4%" }}>
							{creneauChoisi.map((d: any) => (
								<>
									<div
										style={{
											fontWeight: "bold",
											marginBottom: "1%",
										}}
									>
										Récapitulatif de votre rendez-vous :
									</div>
									<Typography>
										{"Type de l'intervention : " +
											creneauxUserClient.typeIntervention
												.type}
									</Typography>
									<Typography>
										{"Durée approximative : " +
											creneauxUserClient.typeIntervention
												.duree +
											" minutes"}
									</Typography>
									<Typography>
										{"Rendez-vous le : " +
											toDateStringWithHour(d.dateDebut) +
											" jusqu'à " +
											toHourString(d.dateFin)}
									</Typography>
								</>
							))}
						</div>
						<form onSubmit={onSubmit} style={{ marginLeft: "4%" }}>
							<div
								style={{
									marginTop: "2%",
								}}
							>
								<InputLabel
									id="type-label"
									sx={{
										fontWeight: "bold",
										marginBottom: "3%",
									}}
								>
									Veuillez entrer votre numéro de téléphone :
								</InputLabel>
								<TextField
									sx={{
										display: "flex",
										justifyContent: "center",
									}}
									id="outlined-basic"
									label="Numéro de téléphone"
									value={currentNumTel}
									variant="outlined"
									onChange={(event) =>
										setCurrentNumTel(event.target.value)
									}
									required
								/>
							</div>
							<span style={{ color: "red" }}>
								{errorForm["numTel"]}
							</span>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
								}}
							>
								<Button
									type="submit"
									variant={"contained"}
									sx={{ margin: "4%" }}
								>
									Valider mon numéro de tel.
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
				<Button
					color="inherit"
					disabled={activeStep === 0}
					onClick={handleBack}
					sx={{ mr: 1 }}
				>
					Retour
				</Button>
				<Box sx={{ flex: "1 1 auto" }} />
				{activeStep !== steps.length && (
					<Button onClick={handleNext} disabled={disableNextButton}>
						{activeStep === steps.length - 1
							? "Terminer"
							: "Suivant"}
					</Button>
				)}
			</Box>
			{openModale ? <ModaleAppointment actionDeconnexion={deconnexion} openModale={openModale}/> : ""}
		</div>
	);
};

export default AppointmentUser;
