import React from "react";
import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import "./NavBar.scss";
import { ListItemNavBar } from "../../../model/ListItemNavBar";

function ListItemCustom({ url, nom, logo, roleNav }: ListItemNavBar) {
	return (
		<ListItem disablePadding>
			<NavLink
				to={url}
				className={({ isActive }) =>
					["link_nav", isActive ? "active" : null]
						.filter(Boolean)
						.join(" ")
				}
			>
				<ListItemButton>
					<ListItemIcon>{logo}</ListItemIcon>
					<ListItemText>{nom}</ListItemText>
				</ListItemButton>
			</NavLink>
		</ListItem>
	);
}

export default ListItemCustom;
