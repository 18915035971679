import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

interface IModaleConfirmRdv{
	title: string;
	data: any;
	actionValider: Function;
	openModale: boolean;
	fonctionOpenModale: Function;
}

const style = {
	position: "absolute" as "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 600,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 20,
	p: 4,
    borderRadius: '10px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

function ModaleConfirmRdv({
	title,
	data,
	openModale,
	fonctionOpenModale,
	actionValider,
}: IModaleConfirmRdv) {
	return (
		<div>
			<Modal
				open={openModale}
				onClose={() => fonctionOpenModale(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
					>
						{title}
					</Typography>
					<Button
                        sx={{mr: '8px'}}
						variant="contained"
						onClick={() => {
							actionValider(data);
							fonctionOpenModale(false);
						}}
					>
						Confirmer
					</Button>
					<Button
						variant="contained"
						onClick={() => fonctionOpenModale(false)}
					>
						Annuler
					</Button>
				</Box>
			</Modal>
		</div>
	);
}

export default ModaleConfirmRdv;
