import { Alert } from "@mui/material";
import React from "react";

interface IErrorPattern {
	type: "success" | "error" | "warning";
	message: string | any;
}

function ErrorPattern({ type, message }: IErrorPattern) {
	return (
		<div>
			<Alert severity={type}>{message}</Alert>
		</div>
	);
}

export default ErrorPattern;
