import axios from "axios";
import { TYPES } from "../../redux/actions/actionTypes";
import { store } from "../../redux/store";
import { routesApi } from "../RoutesApi";
let baseUrl = process.env.REACT_APP_URL_BACK;
let frontUrl = process.env.REACT_APP_URL_FRONT;

export function refreshToken(uuid, originalRequest, CustomAxios) {
	axios
		.get(baseUrl + routesApi.GET_REFRESH_TOKEN + `?uuid=${uuid}`)
		.then((response) => {
			if (response.status === 200) {
				localStorage.setItem("accessToken", response.data.access_token);
				localStorage.setItem(
					"refreshToken",
					response.data.refresh_token
				);

				const actionLogged = {
					type: TYPES.CONNEXION.LOGGED,
					logged: true,
				};

				store.dispatch(actionLogged);
				const newUser = {
					id: response.data.user.id,
					email: response.data.user.email,
					prenom: response.data.user.prenom,
					nom: response.data.user.nom,
					telephone: response.data.user.telephone,
					role: response.data.roles,
					client: response.data.user.client,
					commandes: response.data.user.commandes
						? response.data.user.commandes
						: [],
				};
				const user = {
					type: TYPES.USER.ADD,
					user: newUser,
				};
				store.dispatch(user);
				localStorage.setItem("enCours", "false");
				return CustomAxios(originalRequest);
			} else {
				localStorage.removeItem("accessToken");
				localStorage.removeItem("refreshToken");
				localStorage.setItem("enCours", "false");
				store.dispatch({
					type: "getLogged",
					payload: false,
				});
				window.location.href = frontUrl + "connexion";
			}
		})
		.catch((error) => {
			localStorage.removeItem("accessToken");
			localStorage.removeItem("refreshToken");
			localStorage.setItem("enCours", "false");
			store.dispatch({
				type: "getLogged",
				payload: false,
			});
			//window.location.href = frontUrl;
		});
}
