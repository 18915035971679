import { sr } from "date-fns/locale";
import { TYPES } from "../actions/actionTypes";
import { ITechnicien, TechniciensAction, TechniciensState } from "../Interfaces/typeTechniciens";

const initialState : TechniciensState = []

const techniciensReducer = (state : TechniciensState = initialState, action: TechniciensAction) => {
  switch (action.type) {
    case TYPES.TECHNICIENS.GET:
      return action.technicien
    case TYPES.TECHNICIENS.ADD_CRENEAUX_TO_TECH:
      return state.map((tech : ITechnicien)  => {
        if(action.technicien[0].id === tech.id){
          tech.creneauHoraires = tech.creneauHoraires.concat(action.technicien[0].creneauHoraires)
          return tech
        }
        return tech
      })
    default:
      return state
  }
}

export default techniciensReducer