import { TYPES } from "../actions/actionTypes";
import { RoleAction } from "../Interfaces/typeRole";

const initialState: [] = []

const roleReducer = (state = initialState, action: RoleAction) => {
  switch (action.type) {
    case TYPES.ROLE.GET:
      return action.role
    default:
      return state
  }
}

export default roleReducer