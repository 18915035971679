import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import { userActions } from "../../redux/actions/userActions";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";

interface IModaleResetPassword {
    openModale: boolean;
    fonctionOpenModale: Function;
}

const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: '10px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    p: 4,
};

function ModaleResetPassword({ openModale, fonctionOpenModale }: IModaleResetPassword) {
    const [email, setEmail] = React.useState("");

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        emailResetPassword(email)
        fonctionOpenModale(false);
    };

    const dispatch = useDispatch();
    const { emailResetPassword } = bindActionCreators(userActions, dispatch);

    return (
        <div>
            <Modal
                open={openModale}
                onClose={() => fonctionOpenModale(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ fontWeight: 'bold', textAlign: 'center', mb: '5%' }}
                    >
                        {"Mot de passe oublié :"}
                    </Typography>
                    <Typography
                        sx={{ m: '1%' }}
                    >
                        <Typography
                            sx={{ mb: '2%' }}
                        >
                            Veuillez saisir l'adresse e-mail associée à votre compte :
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            label="E-mail"
                            type="email"
                            fullWidth
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            marginTop: '3%'
                        }}>
                            <Button onClick={() => fonctionOpenModale(false)} color="primary">
                                Annuler
                            </Button>
                            <Button onClick={handleSubmit} type="submit" color="primary">
                                Valider
                            </Button>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

export default ModaleResetPassword;
