import { Card, CardContent, Divider, Typography, CardActions, Button, Grid } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React from "react";
import Modale from "../Modale";
import ModaleTypeMateriel from "../ModaleTypeMateriel";
import { typeMaterielActions } from "../../redux/actions/typeMaterielActions";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";

interface ISimpleCard {
	data: any;
	idClient: number;
}

function SimpleCard({ data, idClient }: ISimpleCard) {

	//Fonctions et const pour la modale
	const [open, setOpen] = React.useState(false);
	const [openDeleteModale, setOpenDeleteModale] = React.useState(false);
	const [typeMaterielIdSelectionne, setTypeMaterielIdSelectionne] =
		React.useState(0);
	
	let currentClientID = idClient;

	const dispatch = useDispatch();

	//Méthodes action : Type intervention
	const { 
		addTypeMateriel,
		editTypeMateriel,
		deleteTypeMateriel
	} = bindActionCreators(
		typeMaterielActions,
		dispatch
	);

	return (
		<>
			{data.map((d: any) => (
				<div key={d.id}>
					<Card sx={{ minWidth: 400, p: 0 }} className="card">
						<CardContent sx={{ p: 3 }}>
							{d.nom && (
								<Typography component="h2">
									{d.nom}
								</Typography>
							)}
							<Grid container display="flex" sx={{ alignItems: 'center' }} justifyContent="space-between">
								<Grid item xs={8}>
									{d.type && (
										<Typography color="textSecondary">
											{d.type}
										</Typography>
									)}
								</Grid>
								<Grid item xs={4}>
									{d.type && (
										<CardActions disableSpacing sx={{ display: 'flex' }}>
											<Button
												size="small"
												onClick={() => {
													setOpen(true);
													setTypeMaterielIdSelectionne(d.id)
												}}
											>
												<EditIcon />
											</Button>
											<Button
												size="small"
												onClick={() => {
													setOpenDeleteModale(true);
													setTypeMaterielIdSelectionne(d.id);
													
												}}
											>
												<DeleteIcon />
											</Button>
										</CardActions>
									)}
								</Grid>
							</Grid>

							{d.prenom && (
								<>
									<Typography variant="body2" component="p">
										{d.prenom}
									</Typography>
									<Typography variant="body2" component="p">
										{d.email}
									</Typography>
								</>
							)}
						</CardContent>
					</Card>
					<Divider />
					{/* MODALE Delete */}
					<Modale
						title="Supprimer"
						contenu={<p>Voulez vous vraiment supprimer ce matériel ?</p>}
						actionValider={() => {
							setOpenDeleteModale(false);
							deleteTypeMateriel(typeMaterielIdSelectionne);
						}}
						openModale={openDeleteModale}
						fonctionOpenModale={setOpenDeleteModale}
					/>
					{/* Modale Edit */}
					<ModaleTypeMateriel
						title={"Modifier un matériel"}
						typeMaterielIdSelectionne={typeMaterielIdSelectionne}
						addTypeMateriel={addTypeMateriel}
						editTypeMateriel={editTypeMateriel}
						openModale={open}
						fonctionOpenModale={setOpen}
						idClient={currentClientID}
					/>
				</div>
			))}
		</>
	);
}

export default SimpleCard;
