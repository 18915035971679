import { TYPES } from "../actions/actionTypes";
import { UserAction, UserState } from "../Interfaces/typeUser";

const initialState : UserState = {
  id: 0,
  email: "",
  prenom: "",
  nom: "",
  telephone: "",
  client : [],
  role : [],
  commandes: [],
  rdvs: [],
}

const userReducer = (
  state :UserState = initialState,
  action: UserAction
) => {
  switch (action.type) {
    case TYPES.USER.ADD:
      return action.user
    // case TYPES.USER.REMOVE:
    //   const removeUser: IUser[] = state.user.filter(
    //     usr => usr.id !== action.user.id
    //   )
    //   return {
    //     ...state,
    //     user: removeUser,
    //   }
    // case TYPES.USER.GET:
    //   const newUser: IUser = {
    //     id: action.user.id,
    //     email: action.user.email,
    //     password: action.user.password,
    //     prenom: action.user.prenom,
    //     nom: action.user.nom
    //   }
    //   return { user: newUser }
    case TYPES.USER.ACTIVATE:
      return action
  }
  return state
}

export default userReducer
