import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import PlanningVisuelPeriodeFullCalendar from "../components/planningPattern/PlanningVisuelPeriodeFullCalendar";
import { crenauxActions } from "../redux/actions/creneauxActions";
import { IPeriodeIntervention } from "../redux/Interfaces/typePeriodesIntervention";
import { IRedux } from "../redux/reducers";
import { Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Modale from "../components/Modale";
import { periodesInterventionActions } from "../redux/actions/periodesInterventionsActions";
import LiensInternes from "../services/LiensInternes";
import { satistiquesAction } from "../redux/actions/StatistiquesActions";
import CardDashboard from "../components/dashboard/CardDashboard";
import { formatText } from "../services/formatText";

function DetailPlanningPeriodeInter() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	//RECUPERATION DE L'ID QUI EST DANS LES PARAMS
	const params = useParams();
	const idPeriode: number = params.id ? parseInt(params.id) : 0;

	const { validerPeriodesIntervention, deletePeriodesIntervention } =
		bindActionCreators(periodesInterventionActions, dispatch);

	//GET LES CRENEAUX HORAIRES DE LA PERIODE
	const { getCreneauxHoraires } = bindActionCreators(
		crenauxActions,
		dispatch
	);
	const { getStatistiquesPeriodeIntervention } = bindActionCreators(
		satistiquesAction,
		dispatch
	);
	useEffect(() => {
		getCreneauxHoraires(idPeriode);
		getStatistiquesPeriodeIntervention(idPeriode);
	}, []);
	const creneauxHoraires = useSelector(
		(state: IRedux) => state.creneauxReduxState
	);
	const stat = useSelector(
		(state: IRedux) => state.statistiquesState.periodeIntervention
	);

	//RECUPERATION DE LA PERIODE D'INTERVENTION POUR AFFICHER LES DETAILS
	const periodesInterventionReduxState = useSelector(
		(state: IRedux) => state.periodesInterventionReduxState
	);
	const [periodeSelected, setPeriodSelected] =
		useState<IPeriodeIntervention>();
	useEffect(() => {
		periodesInterventionReduxState.forEach(
			(periodeInter: IPeriodeIntervention) => {
				if (periodeInter.id === idPeriode) {
					setPeriodSelected(periodeInter);
				}
			}
		);
	}, []);

	//FONCTIONS MODALE
	const [open, setOpen] = React.useState(false);
	const [valider, setValider] = React.useState(false);

	return (
		<div className="container-main">
			<div className="container-white">
				<Typography align="center" variant="h5">
					Planning global de la période d'intervention
				</Typography>
				<Typography align="center" variant="h5">
					{" "}
					{periodeSelected?.commande.numero +
						" - " +
						periodeSelected?.lieu.nom}
				</Typography>
				<Button
					size="small"
					disabled={
						periodeSelected?.validation === false ? false : true
					}
					onClick={() => {
						setOpen(true);
						setValider(true);
					}}
				>
					<CheckCircleIcon
						color={
							periodeSelected?.validation === false
								? "success"
								: "disabled"
						}
					/>
				</Button>
				<Button
					size="small"
					onClick={() => {
						setOpen(true);
						setValider(false);
					}}
				>
					<RemoveCircleIcon color="error" />
				</Button>

				{/* STATS */}
				<div
					style={{ display: "flex", gap: "10px", marginTop: "20px" }}
				>
					{stat &&
						Object.entries(stat).map((value: any) => {
							return (
								<div style={{ flex: 1 }}>
									<CardDashboard
										title={formatText(value[0])}
										data={value[1]}
										couleur="#60CCEC"
									/>
								</div>
							);
						})}
				</div>

				{/* BOUTON RAPPEL PERIODE  */}
				{periodeSelected?.validation === true &&
					stat?.userClientRdvNull !== 0 && (
						<Link
							to={
								LiensInternes.ADMIN.BASE +
								LiensInternes.ADMIN.VALIDATION_PERIODE_INTER +
								"/" +
								periodeSelected?.id +
								LiensInternes.ADMIN.PERIODE_RAPPEL
							}
						>
							<Button variant="contained">
								Créer une période de rappel
							</Button>
						</Link>
					)}

				<PlanningVisuelPeriodeFullCalendar
					listOfCreneaux={creneauxHoraires}
					periodesInterventionSelectionnee={periodeSelected}
					deletable={true}
				/>

				<Modale
					title={valider ? "Valider" : "Supprimer"}
					contenu={
						valider ? (
							<p>
								Voulez vous vraiment valider votre période
								d'intervention ?{" "}
							</p>
						) : (
							<p>
								Voulez vous vraiment supprimer votre période
								d'intervention ?
							</p>
						)
					}
					actionValider={() => {
						if (valider) {
							idPeriode && validerPeriodesIntervention(idPeriode);
						} else {
							idPeriode && deletePeriodesIntervention(idPeriode);
							navigate(
								LiensInternes.ADMIN.BASE +
									LiensInternes.ADMIN.VALIDATION_PERIODE_INTER
							);
						}
						setOpen(false);
					}}
					openModale={open}
					fonctionOpenModale={setOpen}
				/>
			</div>
		</div>
	);
}

export default DetailPlanningPeriodeInter;
