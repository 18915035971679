import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, Button, FormControl, Grid } from "@mui/material";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { loggedActions } from "../../redux/actions/loggedActions";
import { bindActionCreators } from "redux";
import Lottie from "lottie-react";
import loadingAnimation from "../../style/img/98742-loading.json";
import { useNavigate } from "react-router-dom";
import { IRedux } from "../../redux/reducers";
import ErrorPattern from "../../components/errorPattern/ErrorPattern";
import { ErrorActions } from "../../redux/actions/errorActions";
import ModaleResetPassword from "../../components/ModalePattern/ModaleResetPassword";
import "./LoginForms.scss";

interface MyForm {
  email: string;
  password: string;
}

const YupSchema = yup.object().shape({
  email: yup
    .string()
    .email("Entrez un format d'email valide !")
    .required("Un email est requis !"),
  password: yup.string().required("Un mot de passe est requis !"),
});

const defaultValues = {
  email: "",
  password: "",
};

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { connexion } = bindActionCreators(loggedActions, dispatch);
  const { deleteError } = bindActionCreators(ErrorActions, dispatch);

  useEffect(() => {
    deleteError();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const errorState = useSelector((state: IRedux) => state.errorState);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<MyForm>({
    resolver: yupResolver(YupSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (errorState.status !== 0) {
      setIsLoading(false);
    }
  }, [errorState]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = async (data: MyForm) => {
    connexion(data, navigate);
    setIsLoading(true);
  };

  const [openModale, setOpenModale] = useState(false);

  return isLoading ? (
    <div className="container-main">
      <div
        className="container-white"
        style={{
          maxWidth: "35%",
          backgroundColor: "transparent",
        }}
      >
        <Lottie animationData={loadingAnimation} />
      </div>
    </div>
  ) : (
    <div className="container-main">
      <div className="container-white login-container">
        <form onSubmit={handleSubmit(onSubmit)} className="form-container">
          {errorState.status !== 0 && (
            <ErrorPattern type="error" message={errorState.error} />
          )}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    required
                    fullWidth
                    onChange={onChange}
                    value={value}
                    label={"Email"}
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors.email && errors?.email?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"password"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    required
                    fullWidth
                    onChange={onChange}
                    type={"password"}
                    value={value}
                    label={"Mot de passe"}
                    variant="outlined"
                    error={!!errors.password}
                    helperText={errors.password && errors?.password?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ marginTop: "2%" }}
          >
            <Grid item>
              <Button
                type="submit"
                variant={"outlined"}
                fullWidth
                className="form-button" 
              >
                Connexion
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => reset()}
                variant={"outlined"}
                fullWidth
                className="form-button"
              >
                Réinitialiser
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid
          container
          justifyContent="center"
        >
          <button
            className="forgot-password-button"
            onClick={() => {
              setOpenModale(true);
            }}
          >
            Mot de passe oublié ?
          </button>
        </Grid>
      </div>
      <ModaleResetPassword
        openModale={openModale}
        fonctionOpenModale={setOpenModale}
      />
    </div>
  );
};

export default LoginForm;
