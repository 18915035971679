import { sr } from "date-fns/locale";
import { TYPES } from "../actions/actionTypes";
import { IUser, UserAction, UserState } from "../Interfaces/typeUser";

const initialState = {}

const uploadFileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TYPES.UPLOADFILE:
      return {
        ...state,
        data: action.payload
      }
    default:
      return state
  }
}

export default uploadFileReducer