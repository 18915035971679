import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Grid,
	Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { IRedux } from "../redux/reducers";
import "./AddTypeInter.scss";
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { typeInterventionActions } from "../redux/actions/typeInterventionActions";
import { IIntervention } from "../redux/Interfaces/typeIntervention";
import ModaleTypeInter from "../components/ModaleTypeInter";

function AddTypeInter() {

	const dispatch = useDispatch();

	//Méthodes action : Type intervention
	const { 
		getTypeInterventions,
		addTypeIntervention,
		editTypeIntervention,
	} = bindActionCreators(
		typeInterventionActions,
		dispatch
	);
	useEffect(() => {
		getTypeInterventions();
	}, []);

	//Gestion des types d'intervention
	const typeInterventionReduxState = useSelector(
		(state: IRedux) => state.typeInterventionReduxState
	);

	//Fonctions et const pour la modale
	const [open, setOpen] = React.useState(false);
	const [ajouter, setAjouter] = React.useState(false);
	const [typeInterIdSelectionne, setTypeInterIdSelectionne] =
		React.useState(0);

	useEffect(() => {
		if (!open) {
			setTypeInterIdSelectionne(0)
		}
	}, [open]);

	return (
		<div className="container-main">
			<h1 className="h1-title">Liste des types d'intervention</h1>

			<div className="container-white">
				<Card sx={{ maxWidth: 1000 }} className="card">
					<Grid container spacing={2} className="card-grid">
						<Grid xs={8}>
							<CardContent>
								<Typography variant="h6">Informations sur le type d'intervention</Typography>
							</CardContent>
						</Grid>
						<Grid xs={2}>
							<CardActions>
								<Typography variant="h6">Modifier</Typography>
							</CardActions>
						</Grid>
						<Grid xs={2}>
							<CardActions>
							<Button variant="contained" startIcon={<AddCircleIcon />} size="medium"
								onClick={() => {
									setOpen(true);
									setAjouter(true);
								}}>
								AJouter
							</Button>
							</CardActions>
						</Grid>
					</Grid>
				</Card>
				<Divider />

				{typeInterventionReduxState.length === 0 && (
					<Typography sx={{ textAlign: "center", padding: "30px " }}>
						Il n'y a aucun type d'intervention existant
					</Typography>
				)}

				{typeInterventionReduxState?.map(
					(typeInter: IIntervention, key: number) => (
						<>
							<Card sx={{ minWidth: 1000 }} className="card">
								<Grid
									container
									spacing={2}
									className="card-grid"
								>
									<Grid xs={8}>
										<CardContent>
											<Typography>
												{'Nom : ' + typeInter.type}
											</Typography>
											<Typography>
												{'Durée : ' + typeInter.duree + ' minutes.'}
											</Typography>
										</CardContent>
									</Grid>
									<Grid xs={4}>
										<CardActions>
											<Button
												size="small"
												onClick={() => {
													setOpen(true);
													setAjouter(false);
													setTypeInterIdSelectionne(
														typeInter.id
													);
												}}
											>
												<EditIcon />
											</Button>
										</CardActions>
									</Grid>
								</Grid>
							</Card>
							<Divider />
						</>
					)
				)}
				<ModaleTypeInter
					title={ajouter ? "Ajouter un nouveau type d'intervention" : "Modifier un type d'intervention"}
					typeInterIdSelectionne={typeInterIdSelectionne}
					listeTypeIntervention={typeInterventionReduxState}
					addTypeIntervention={addTypeIntervention}
					editTypeIntervention={editTypeIntervention}
					openModale={open}
					fonctionOpenModale={setOpen}
				/>
			</div>
		</div>
	);
}

export default AddTypeInter;
