import { TYPES } from "../actions/actionTypes";
import { IPeriodeIntervention, PeriodeInterventionState, PeriodesInterventionAction } from "../Interfaces/typePeriodesIntervention";

const initialState : PeriodeInterventionState =  []

const periodesInterventionReducer = (state : PeriodeInterventionState = initialState, action: PeriodesInterventionAction) => {
  switch (action.type) {
    case TYPES.PERIODES_INTERVENTION.GET:
      return action.periodesIntervention
    case TYPES.PERIODES_INTERVENTION.ADD:
      return action.periodesIntervention.forEach((element : IPeriodeIntervention) => {
        state.push(element)
      });
    case TYPES.PERIODES_INTERVENTION.VALIDER:
      return state.map((periodeInter : IPeriodeIntervention) => {
        if(periodeInter.id === action.periodesIntervention){
          periodeInter.validation = true
          return periodeInter;
        }
        return periodeInter
      })
    case TYPES.PERIODES_INTERVENTION.DELETE:
      return state.filter(pi => pi.id != action.periodesIntervention)
    default:
      return state
  }
}

export default periodesInterventionReducer