import {
	Alert,
	Box,
	Button,
	CardContent,
	Divider,
	Grid,
	Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IRedux } from "../redux/reducers";
import { toDateStringWithHour } from "../services/FormatDate";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LiensInternes from "../services/LiensInternes";

function DetailsUserClient() {
	const params = useParams();
	const navigate = useNavigate();

	const idUserClient = params.id ? parseInt(params.id) : null;
	const data: any = useSelector((state: IRedux) => state?.crudState);

	const filteredData = data.filter((user: any) => user.id === idUserClient);

	function handleGoBack() {
		navigate(
			LiensInternes.ADMIN.BASE +
			LiensInternes.ADMIN
				.RECHERCHER_USER_CLIENT
		);
	}

	return (
		<div className="container-main">
			<div className="container-white" style={{ maxWidth: '50%', margin: '0 auto' }}>
				<Grid container spacing={1}>
					<Grid xs={8}>
						<Box>
							<CardContent>
								<Typography variant="h5" component="div">
									{filteredData[0].nom + " " + filteredData[0].prenom + " (" + filteredData[0].email + ")"}
								</Typography>
								<Typography
									sx={{ mt: 3 }}
									color="text.secondary"
								>
									{filteredData[0].telephone !== null &&
										"Numéro de téléphone : " + filteredData[0].telephone}
								</Typography>
								<Typography
									sx={{ mt: 1 }}
									color="text.secondary"
								>
									{filteredData[0].telephone !== null &&
										"Adresse mail : " + filteredData[0].email}
								</Typography>
								<Typography sx={{ mt: 1 }} variant="body2">
									<Typography color="text.secondary">Commandes :</Typography>
									{"- " + filteredData[0].commandes.map(
										(commande: any) => commande.numero
									)}
								</Typography>
							</CardContent>
						</Box>
					</Grid>
					<Grid xs={4}>
						<Box>
							{filteredData[0].telephone === null && (
								<Alert severity="error">
									Le téléphone n'est pas connu
								</Alert>
							)}
							{filteredData[0].activation === false && (
								<Alert severity="error">
									Le user n'a pas activé son compte
								</Alert>
							)}
						</Box>
					</Grid>
				</Grid>

				<Divider />

				<Typography
					variant="h5"
					sx={{
						margin: "0.8rem",
					}}
				>
					Liste des rendez-vous de l'utilisateur :
				</Typography>

					
				{filteredData[0].rdvs.length === 0 ? <Typography sx={{margin: "3%"}} component="div">Aucun rendez-vous trouvé.</Typography> : 
					filteredData[0].rdvs.map((rdv: any) => (
					<CardContent>
						<Typography component="div">
							{"- " + toDateStringWithHour(rdv.creneauHoraire.dateDebut)}{" "}
							- {toDateStringWithHour(rdv.creneauHoraire.dateFin)}
						</Typography>
						<Typography sx={{ mb: 1.5 }} color="text.secondary">
							{"avec le technicien " + rdv.technicien.nom + " " + rdv.technicien.prenom + "(" + rdv.technicien.email + ")"} 
						</Typography>
						<Divider />
					</CardContent>
				))}
				<Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "5%" }}>
					<Button
						variant="contained"
						size="large"
						startIcon={<ArrowBackIcon />}
						onClick={handleGoBack}
					>
						Retour
					</Button></Box>
			</div>
		</div>
	);
}

export default DetailsUserClient;
