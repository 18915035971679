import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
	Button,
	Typography,
	Stepper,
	Step,
	StepLabel,
	Box,
	CircularProgress,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { green } from "@mui/material/colors";

import AutoCompleteMultiplePattern from "../components/formPatterns/autoCompleteMultiplePattern/AutoCompleteMultiplePattern";

import AutoCompletePattern from "../components/formPatterns/autocompletePattern/AutoCompletePattern";
import DatePickerPattern from "./formPatterns/datePickerPattern/DatePickerPattern";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { typeInterventionActions } from "../redux/actions/typeInterventionActions";
import { bindActionCreators } from "redux";
import { commandesActions } from "../redux/actions/commandesActions";
import { lieuxActions } from "../redux/actions/lieuxActions";
import { periodesInterventionActions } from "../redux/actions/periodesInterventionsActions";

import { useTranslation } from "react-i18next";
import "../services/traduction/i18n";
import dayjs from "dayjs";
import { IRedux } from "../redux/reducers";
import { IIntervention } from "../redux/Interfaces/typeIntervention";
import { calculDuree } from "../services/CalculDebutFinJournee";
import LiensInternes from "../services/LiensInternes";

interface IFormInput {
	commandes: string[] | null;
	sites: string | null;
	intervention: string | null;
	debutPeriode: string | null;
	finPeriode: string | Date | null;
	sauvegarde: string | Date | null;
}

const defaultValues = {
	intervention: "",
	commandes: [],
	sites: "",
	debutPeriode: null,
	finPeriode: null,
	sauvegarde: "",
};

//Titres du spinner
const steps = [
	"Sélection de la typologie d'intervention",
	"Sélection d'une ou plusieurs commande(s)",
	"Sélection du lieu d'intervention",
	"Sélection de la période d'intervention",
];

const AppointmentCreationForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { getTypeInterventions } = bindActionCreators(
		typeInterventionActions,
		dispatch
	);
	const { getCommandesByClient } = bindActionCreators(
		commandesActions,
		dispatch
	);
	const { getLieux } = bindActionCreators(lieuxActions, dispatch);
	const { addPeriodeIntervention } = bindActionCreators(
		periodesInterventionActions,
		dispatch
	);

	const userID = useSelector((state: IRedux) => state.userReduxState.id);
	const currentClientID = useSelector(
		(state: IRedux) => state.currentClient.id
	);

	//INIT TYPE INTERVENTION
	const interventionOptionsObject = useSelector(
		(state: IRedux) => state.typeInterventionReduxState
	);
	const [typeIntervention, setTypeIntervention] = useState<IIntervention[]>();
	useEffect(() => {
		setTypeIntervention(interventionOptionsObject);
	}, [interventionOptionsObject]);

	const interventionOptionsInString = interventionOptionsObject.map(
		(option: any) => option.type + " - " + calculDuree(option.duree)
	);

	const commandesReduxState = useSelector(
		(state: any) => state.commandesReduxState
	);
	const arrayOfCommandes = commandesReduxState?.action?.commandes;
	const commandesInString = arrayOfCommandes?.map(
		(commande: any) => commande.numero
	);

	const lieuxReduxState = useSelector((state: any) => state.lieuxReduxState);
	const arrayOfLieux = lieuxReduxState;
	const lieuxInString = arrayOfLieux?.map((lieu: any) => lieu.nom);

	//Appel des méthodes de React Hook Form
	const methods = useForm<IFormInput>({ defaultValues: defaultValues });
	const {
		reset,
		control,
		formState: { errors },
	} = methods;

	//Fonction au Submit du formulaire
	const onSubmit = (data: IFormInput) => console.log(data);

	//UseState des options sélectionnés dans chaque champs
	const [interventionSelectionned, setInterventionSelectionned] =
		React.useState<string>();
	const [commmandesSelectionned, setCommmandesSelectionned] = React.useState<
		string[]
	>([]);
	const [sitesSelectionned, setSitesSelectionned] = React.useState<string>();
	const [startPeriodSelectionned, setStartPeriodSelectionned] =
		React.useState<Date>(new Date());
	const [endPeriodSelectionned, setEndPeriodSelectionned] =
		React.useState<Date>();
	// const [selectionName, setSelectionName] = React.useState<string>()

	//Gestion de l'état des étapes du formulaire
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set<number>());
	const [disableNextButton, setDisableNextButton] = React.useState(true);
	const isStepSkipped = (step: number) => {
		return skipped.has(step);
	};

	//Gestion des état à la sauvegarde de la sélection
	const [loading, setLoading] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	const timer = React.useRef<number>();

	const buttonSx = {
		...(success && {
			bgcolor: green[500],
			"&:hover": {
				bgcolor: green[700],
			},
		}),
	};

	React.useEffect(() => {
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	const handleButtonClickOnSave = () => {
		if (!loading) {
			setSuccess(false);
			setLoading(true);

			let typeInterventionID;
			typeIntervention?.forEach((intervention: any) => {
				if (interventionSelectionned?.includes(intervention.type)) {
					typeInterventionID = intervention.id;
				}
			});

			let lieuID;
			arrayOfLieux?.forEach((lieu: any) => {
				if (lieu.nom === sitesSelectionned) {
					lieuID = lieu.id;
				}
			});

			let commandesID: any = [];

			for (let i = 0; i < commmandesSelectionned?.length; i++) {
				arrayOfCommandes?.forEach((commande: any) => {
					if (commande.numero === commmandesSelectionned[i]) {
						commandesID.push(commande.id);
					}
				});
			}

			const dataToSend = {
				dateDebut: dayjs(startPeriodSelectionned).format(
					"YYYY-MM-DDTHH:mm:ss"
				),
				dateFin: dayjs(endPeriodSelectionned).format(
					"YYYY-MM-DDTHH:mm:ss"
				),
				lieu: {
					id: lieuID,
				},
				typeIntervention: {
					id: typeInterventionID,
				},
				userComNetWork: {
					id: userID,
				},
			};

			if (commandesID.length > 0) {
				addPeriodeIntervention(dataToSend, commandesID, "new");
			}
			timer.current = window.setTimeout(() => {
				setSuccess(true);
				setLoading(false);
			}, 2000);
		}
	};

	//Création d'un useRef pour éviter le first render du UseEffect au chargement de la page
	const didMountRef = useRef(false);
	let startDate: string = "";
	let endDate: string = "";

	useEffect(() => {
		//Après le first render du UseEffect,
		if (didMountRef.current) {
			//On bloque le bouton "Suivant" si aucune sélection a été effectuée
			if (activeStep === 0) {
				if (
					interventionSelectionned?.length === 0 ||
					interventionSelectionned === undefined ||
					interventionSelectionned === null
				) {
					setDisableNextButton(true);
				} else {
					setDisableNextButton(false);
				}
			} else if (activeStep === 1) {
				if (
					commmandesSelectionned?.length === 0 ||
					commmandesSelectionned === undefined
				) {
					setDisableNextButton(true);
				} else {
					setDisableNextButton(false);
				}
			} else if (activeStep === 2) {
				if (
					sitesSelectionned?.length === 0 ||
					sitesSelectionned === undefined ||
					sitesSelectionned === null
				) {
					setDisableNextButton(true);
				} else {
					setDisableNextButton(false);
				}
			} else if (activeStep === 3) {
				if (
					startPeriodSelectionned === undefined ||
					startPeriodSelectionned === null
				) {
					setDisableNextButton(true);
				} else {
					setDisableNextButton(false);
				}
			}
		}

		//Convertion des states de période d'intervention en objet Date
		let copyOfStartDateObject: any = { ...startPeriodSelectionned };
		startDate = new Date(copyOfStartDateObject.$d).toLocaleDateString(
			"fr-FR",
			{ weekday: "long", month: "long", day: "numeric", year: "numeric" }
		);
		let copyOfEndDateObject: any = { ...endPeriodSelectionned };
		endDate = new Date(copyOfEndDateObject.$d).toLocaleDateString("fr-FR", {
			weekday: "long",
			month: "long",
			day: "numeric",
			year: "numeric",
		});

		if (didMountRef.current === false) {
			getTypeInterventions();
			getCommandesByClient(currentClientID);
			getLieux(currentClientID);
		}
		//Au premier chargement, on passe le useRef à true
		didMountRef.current = true;
	}, [
		interventionSelectionned,
		commmandesSelectionned,
		sitesSelectionned,
		startPeriodSelectionned,
		endPeriodSelectionned,
		activeStep,
	]);

	//Fonction pour avancer à l'étape suivante du formulaire
	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	//Fonction pour revenir à l'étape précédente du formulaire
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<div className="container-main">
			<h1 className="h1-title">
				Création d'une nouvelle période d'intervention
			</h1>
			<div
				style={{
					background: "white",
					padding: "20px",
					width: "80%",
					borderRadius: "20px",
				}}
			>
				{/* Stepper */}
				{!success && (
					<Stepper activeStep={activeStep}>
						{steps.map((label, index) => {
							const stepProps: { completed?: boolean } = {};
							const labelProps: {
								optional?: React.ReactNode;
							} = {};
							if (isStepSkipped(index)) {
								stepProps.completed = false;
							}
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}>
										{label}
									</StepLabel>
								</Step>
							);
						})}
					</Stepper>
				)}

				{/* Formulaire */}
				<div>
					<FormProvider {...methods}>
						<form
							onSubmit={methods.handleSubmit(onSubmit)}
							className="form-container"
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
							}}
						>
							{/* Si les étapes sont complétées, on affiche un message de demande de confirmation. Sinon on affiche les étapes du formulaire*/}
							{activeStep === steps.length ? (
								<React.Fragment>
									<Typography
										sx={{
											mt: 2,
											mb: 1,
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center",
											minHeight: "150px",
										}}
									>
										{/* Si l'utilisateur a confirmé sa sélection, on affiche un message de validation. Sinon, on lui demande de confirmer sa sélection*/}
										{success ? (
											<React.Fragment>
												<div>
													<p>
														{t(
															"FORMULAIRE_PERIODE_INTERVENTION.confirmation_enregistrement_message"
														)}
													</p>
												</div>
												<p
													style={{
														textAlign: "center",
													}}
												>
													{t(
														"FORMULAIRE_PERIODE_INTERVENTION.assignation_technicien_message"
													)}
												</p>
											</React.Fragment>
										) : (
											<React.Fragment>
												<p
													style={{
														textAlign: "center",
														display: "flex",
														alignItems: "center",
														gap: "5px",
													}}
												>
													{" "}
													<span>
														{
															<ReportProblemIcon color="warning" />
														}
													</span>
													{t(
														"FORMULAIRE_PERIODE_INTERVENTION.avertissement_first_message"
													)}
												</p>
												<ul>
													<li>
														Type d'intervention -{" "}
														<span
															style={{
																fontWeight:
																	"bold",
															}}
														>
															{
																interventionSelectionned
															}
														</span>
													</li>
													<li>
														Commande(s) -{" "}
														{commmandesSelectionned?.map(
															(commande) => (
																<span
																	style={{
																		fontWeight:
																			"bold",
																	}}
																>
																	{commande}{" "}
																</span>
															)
														)}
													</li>
													<li>
														Lieu -{" "}
														<span
															style={{
																fontWeight:
																	"bold",
															}}
														>
															{sitesSelectionned}
														</span>
													</li>
													<li>
														Période d'intervention -
														Du{" "}
														<span
															style={{
																fontWeight:
																	"bold",
															}}
														>
															{startPeriodSelectionned?.toLocaleDateString(
																"fr-FR",
																{
																	weekday:
																		"long",
																	month: "long",
																	day: "numeric",
																	year: "numeric",
																}
															)}
														</span>{" "}
														au{" "}
														<span
															style={{
																fontWeight:
																	"bold",
															}}
														>
															{endPeriodSelectionned?.toLocaleDateString(
																"fr-FR",
																{
																	weekday:
																		"long",
																	month: "long",
																	day: "numeric",
																	year: "numeric",
																}
															)}
														</span>
													</li>
												</ul>
												{/* <p style={{ textAlign: "center", fontStyle: "italic" }}>{t("FORMULAIRE_PERIODE_INTERVENTION.avertissement_second_message")}</p> */}
												{/* <TextfieldPattern name={"sauvegarde"} control={control} required={true} multiline={false} label={"Nom de sauvegarde"} /> */}
											</React.Fragment>
										)}
									</Typography>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											pt: 2,
										}}
									>
										<Box sx={{ flex: "1 1 auto" }} />
										{/* <Button onClick={handleReset}>Reset</Button> */}
									</Box>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											gap: "20px",
										}}
									>
										<Box>
											{!success && (
												<Button
													variant="contained"
													sx={buttonSx}
													disabled={loading}
													onClick={(e) => {
														if (!success) {
															handleButtonClickOnSave();
														} else {
															e.preventDefault();
														}
													}}
													startIcon={<SaveIcon />}
													type="submit"
												>
													"Enregistrer"
												</Button>
											)}
											{loading && (
												<CircularProgress
													size={24}
													sx={{
														color: green[500],
														position: "absolute",
														top: "50%",
														left: "50%",
														marginTop: "-12px",
														marginLeft: "-12px",
													}}
												/>
											)}
										</Box>
										{!success ? (
											<Button
												onClick={() => {
													reset();
													setActiveStep(0);
												}}
												variant={"outlined"}
											>
												{t(
													"FORMULAIRE_PERIODE_INTERVENTION.reinitialisation_btn"
												)}
											</Button>
										) : (
											<Button
												variant={"contained"}
												endIcon={<EngineeringIcon />}
												onClick={() =>
													navigate(
														LiensInternes.ADMIN
															.BASE +
															LiensInternes.ADMIN
																.ASSIGNATION_TECH +
															"/0"
													)
												}
											>
												{t(
													"FORMULAIRE_PERIODE_INTERVENTION.assignation_technicien_btn"
												)}
											</Button>
										)}
									</div>
								</React.Fragment>
							) : (
								<React.Fragment>
									<div
										style={{
											display:
												activeStep === 0
													? "block"
													: "none",
											margin: "0 100px",
											width: "100%",
										}}
									>
										<AutoCompletePattern
											name={"intervention"}
											control={control}
											label={t(
												"FORMULAIRE_PERIODE_INTERVENTION.label_type_intervention"
											)}
											required={true}
											content={
												interventionOptionsInString
											}
											placeholder={"Type d'intervention"}
											optionsSelected={
												interventionSelectionned
											}
											setOptionsSelected={
												setInterventionSelectionned
											}
										/>
									</div>
									<br />
									<div
										style={{
											display:
												activeStep === 1
													? "block"
													: "none",
											margin: "0 100px",
											width: "100%",
										}}
									>
										<AutoCompleteMultiplePattern
											name={"commandes"}
											control={control}
											label={t(
												"FORMULAIRE_PERIODE_INTERVENTION.label_commande"
											)}
											required={true}
											content={commandesInString}
											placeholder={"Commande"}
											optionsSelected={
												commmandesSelectionned
											}
											setOptionsSelected={
												setCommmandesSelectionned
											}
										/>
									</div>
									<br />
									<div
										style={{
											display:
												activeStep === 2
													? "block"
													: "none",
											margin: "0 100px",
											width: "100%",
										}}
									>
										<AutoCompletePattern
											name={"sites"}
											control={control}
											label={t(
												"FORMULAIRE_PERIODE_INTERVENTION.label_lieu"
											)}
											required={true}
											content={lieuxInString}
											placeholder={"Site"}
											optionsSelected={sitesSelectionned}
											setOptionsSelected={
												setSitesSelectionned
											}
										/>
									</div>
									<br />
									<div
										style={{
											display:
												activeStep === 3
													? "block"
													: "none",
											margin: "0 100px",
											width: "100%",
										}}
									>
										<div>
											<DatePickerPattern
												name={"debutPeriode"}
												control={control}
												label={t(
													"FORMULAIRE_PERIODE_INTERVENTION.label_periode_debut"
												)}
												required={true}
												optionsSelected={
													startPeriodSelectionned
												}
												setOptionsSelected={
													setStartPeriodSelectionned
												}
												disableFuture={false}
												disablePast={true}
												maxDate={endPeriodSelectionned}
											/>
										</div>
										<p style={{ textAlign: "center" }}>
											ET
										</p>
										<div>
											<DatePickerPattern
												name={"finPeriode"}
												control={control}
												label={t(
													"FORMULAIRE_PERIODE_INTERVENTION.label_periode_fin"
												)}
												required={true}
												optionsSelected={
													endPeriodSelectionned
												}
												setOptionsSelected={
													setEndPeriodSelectionned
												}
												disableFuture={false}
												disablePast={true}
												minDate={
													startPeriodSelectionned
												}
											/>
										</div>
									</div>
								</React.Fragment>
							)}
						</form>
					</FormProvider>

					<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
						{!success && (
							<Button
								color="inherit"
								disabled={activeStep === 0}
								onClick={handleBack}
								sx={{ mr: 1 }}
							>
								Retour
							</Button>
						)}
						<Box sx={{ flex: "1 1 auto" }} />
						{/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
						{activeStep !== steps.length && (
							<Button
								onClick={handleNext}
								disabled={disableNextButton}
							>
								{activeStep === steps.length - 1
									? "Terminer"
									: "Suivant"}
							</Button>
						)}
					</Box>
				</div>
			</div>
		</div>
	);
};

export default AppointmentCreationForm;
