const LiensInternes = {
	ADMIN: {
		BASE: "/admin",
		TABLEAU_DE_BORD: "/tableau-de-bord",
		CHOIX_CLIENT: "/choix-client",
		INFORMATIONS_CLIENT: "/informations-client",
		IMPORT_FICHIER: "/import-fichier",
		TYPES_INTER: "/types-intervention",
		CREATION_RDV: "/creation-rdv",
		ASSIGNATION_TECH: "/assignation-technicien",

		VALIDATION_PERIODE_INTER: "/validation-periode-intervention",
		PERIODE_RAPPEL: "/periode-de-rappel",

		LISTE_CLIENT: "/liste-clients",
		USER_COM: "/user-comnetwork",
		TECHNICIEN: "/technicien",
		RECHERCHER_USER_CLIENT: "/rechercher-utilisateurs-clients",
		DETAILS_USER_CLIENT: "/details-client",
		PROFIL: "/mon-profil",
	},
	SUPERVISEUR_CLIENT: {
		BASE: "/superviseur-client",
		TABLEAU_DE_BORD: "/tableau-de-bord",
		RDV_LIEU: "/rdv-lieu",
		CSV_ADD_LIEU: "/import-fichier-lieu",
		PROFIL: "/mon-profil",
	},
	PROFIL: "/mon-profil",
	CONNEXION: "/connexion",
	CONTACT: "/contact",
	PRISE_RDV: "/prise-de-rendez-vous",
	RESET_PASSWORD: "/reinitialiser-mon-mot-de-passe",

	TECHNICIEN: "/technicien",
};
export default LiensInternes;
