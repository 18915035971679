import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LiensInternes from "../services/LiensInternes";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../redux/actions/userActions';
import { bindActionCreators } from 'redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import TextfieldPattern from '../components/formPatterns/textfieldPattern/TextfieldPattern';
import { IRedux } from '../redux/reducers';

interface IFormInput {
    password: string;
    confirmedPassword: string;
}

function ResetPasswordPage() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const { resetPassword } = bindActionCreators(userActions, dispatch);

    const defaultValues = {
        password: "",
        confirmedPassword: "",
    };

    const YupSchema = yup.object().shape({
        password: yup
            .string()
            .required("Un mot de passe est requis !")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Le mot de passe doit contenir 8 caractères minimum, une majuscule, une minuscule, un nombre et un caractère spécial"
            ),
        confirmedPassword: yup
            .string()
            .required("Un mot de passe est requis !")
            .oneOf(
                [yup.ref("password")],
                "Les mots de passe ne sont pas identiques"
            ),
    });

    const methods = useForm<IFormInput>({
        resolver: yupResolver(YupSchema),
        defaultValues: defaultValues,
    });

    const {
        control,
        formState: { errors },
    } = methods;

    const handleSubmit = (data: IFormInput) => {
        if (data.password === data.confirmedPassword) {
            const infoToSend = {
                password: data.password,
                uuid: params.id,
            };
            resetPassword(infoToSend);
        }
    }

    const handleCancel = () => {
        navigate(LiensInternes.CONNEXION);
    }

    const errorState = useSelector((state: IRedux) => state.errorState);
    const [error, setError] = React.useState(false);
    React.useEffect(() => {
        setError(
            errorState.status === 0 || errorState.status !== 200 ? false : true
        );
    }, [errorState]);

    return (
        <div className="container-main">
            <div className="container-white" style={{ maxWidth: 800 }}>
                <FormProvider {...methods}>
                    <Typography variant="h4" component="h1" color={'black'}>
                        Réinitialisation de votre mot de passe :
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        Veuillez réinitialiser votre mot de passe pour vous connecter à nouveau.
                    </Typography>
                    <form style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }} onSubmit={methods.handleSubmit(handleSubmit)}>
                        <TextfieldPattern
                            label="Nouveau mot de passe"
                            type="password"
                            name="password"
                            control={control}
                            error={!!errors.password}
                            helperText={
                                errors.password && errors?.password?.message
                            }
                        />
                        <TextfieldPattern
                            label="Confirmer le nouveau mot de passe"
                            type="password"
                            name="confirmedPassword"
                            control={control}
                            error={!!errors.confirmedPassword}
                            helperText={
                                errors.confirmedPassword &&
                                errors?.confirmedPassword?.message
                            }
                        />
                        <p>
                            NB. Le mot de passe doit contenir 8 caractères minimum, une majuscule, une minuscule, un nombre et un caractère spécial.
                        </p>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: '3%',
                                gap: "35%",
                            }}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Réinitialiser mon mot de passe
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleCancel()}
                            >
                                Annuler
                            </Button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
}

export default ResetPasswordPage;