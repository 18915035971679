import { Avatar, Button, Grid, Typography, Divider } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import ModaleWithInput from "../components/ModalePattern/ModaleWithInput";
import { CrudAction } from "../redux/actions/CrudActions";
import { userActions } from "../redux/actions/userActions";
import { Client } from "../redux/Interfaces/typeClient";
import { IRdv } from "../redux/Interfaces/typeRdv";
import { IUser } from "../redux/Interfaces/typeUser";
import { toDateStringWithHour, toHourString } from "../services/FormatDate";
import { navigateCustom } from "../services/Navigate";

interface propsDetailsProfile {
	user: IUser;
}

function DetailsProfil({ user }: propsDetailsProfile) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { edit } = bindActionCreators(CrudAction, dispatch);
	const { modifyMdp } = bindActionCreators(userActions, dispatch);

	//FONCTIONS MODALE
	const [openModaleUpdate, setOpenModaleUpdate] = React.useState(false);
	const [dataUpdate, setDataUpdate] = React.useState({});
	const [type, setType] = React.useState("");

	return (
		<div className="container-main">
			<h1 className="h1-title">Mon profil</h1>
			<div className="container-white">
				<Grid
					container
					spacing={2}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Grid xs={4} item>
						<div
							style={{
								textAlign: "center",
								margin: "0.5rem",
								padding: "2rem",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{" "}
							<Avatar
								sx={{
									width: 120,
									height: 120,
									fontSize: "60px",
									marginBottom: "60px",
								}}
							>
								{(user?.prenom).charAt(0)}
								{(user?.nom).charAt(0)}
							</Avatar>
							<Button
								sx={{
									margin: "1rem",
								}}
								variant="contained"
								onClick={() => {
									setDataUpdate([
										{
											label: "nom",
											value: user?.nom,
										},
										{
											label: "prenom",
											value: user?.prenom,
										},
										{
											label: "telephone",
											value: user?.telephone
												? user?.telephone
												: "",
										},
									]);
									user.role[0] === "ClientUser" ? setType("user-client") : setType("user-com");
									setOpenModaleUpdate(true);
								}}
							>
								Modifier mon profil
							</Button>
							<Button
								variant="contained"
								onClick={() => {
									setDataUpdate([
										{
											label: "mot de passe",
											value: "",
										},
									]);
									setOpenModaleUpdate(true);
									setType("modify-mdp");
								}}
							>
								Modifier mon mot de passe
							</Button>
						</div>
					</Grid>

					<Grid xs={8} item>
						<div
							style={{
								textAlign: "center",
								margin: "0.5rem",
								padding: "2rem",
							}}
						>
							<Typography
								variant="h4"
								sx={{ mt: "1rem", mb: "2rem" }}
							>
								Informations personnelles
							</Typography>
							<Typography variant="h6">
								{user.nom} {user.prenom && " " + user.prenom}
							</Typography>
							<Typography variant="h6">{user.email}</Typography>
							<Typography variant="h6">
								{user.telephone
									? user.telephone
									: "Pas de numéro de teléphone"}
							</Typography>
							<Divider sx={{ mt: "2rem", mb: "2rem" }} />

							{user.role[0] !== "Technicien" && user.client.length > 0 && (
								<>
									<Typography variant="h5">
										Mes clients
									</Typography>
									{user.client.map((client: Client, key: number) => (
										<Typography key={key} variant="h6">
											{client.nom}
										</Typography>
									))}
									<Divider sx={{ mt: "2rem", mb: "2rem" }} />
									<Typography variant="h5">
										Mes rôles
									</Typography>
									{user.role.map((role: string, key: number) => (
										<Typography key={key} variant="h6">
											{role}
										</Typography>
									))}
								</>
							)}
							{user.role[0] === "ClientUser" && (
								<>
									<Typography variant="h5" sx={{m: '2%'}}>
										Mes rendez-vous
									</Typography>
									{user.rdvs
									? 
									<>{user.rdvs?.map((rdv: IRdv, key: number) => (
										<Typography key={key} variant="h6">
											<li>
												{'Le ' + toDateStringWithHour(rdv.creneauHoraire.dateDebut) + " jusqu'à " + toHourString(rdv.creneauHoraire.dateFin)}
											</li>
										</Typography>							
									))}</>
									: <Typography>{"Vous n'avez pas de numéro de téléphone enregistré"}</Typography>}
									<Divider sx={{ mt: "2rem", mb: "2rem" }} />
								</>
							)}
						</div>
					</Grid>
					<Button variant="contained" onClick={() => navigateCustom(user.role, navigate)}>Retour</Button>
				</Grid>	
			</div>

			{/* MODALE CHANGEMENT  */}
			{openModaleUpdate && (
				<ModaleWithInput
					title={
						type === "user-com" && "user-client"
							? "Mise à jour :"
							: "Modification du mot de passe :"
					}
					type={type}
					idDataSelectionne={user.id}
					tableauDeValeur={dataUpdate}
					fonctionSetObject={setDataUpdate}
					actionValider={type === "user-com" || "user-client" ? edit : modifyMdp}
					openModale={openModaleUpdate}
					fonctionOpenModale={setOpenModaleUpdate}
				/>
			)}
		</div>
	);
}

export default DetailsProfil;
