import { TYPES } from "../actions/actionTypes";
import { StatistiquesAction } from "../Interfaces/typeStatistiques";

const initialState: {} = {}

const statistiquesReducer = (state = initialState, action: StatistiquesAction) => {
  switch (action.type) {
    case TYPES.STAT.GET:
      return {
        ...state, 
        tableaudeBord :  action.statistiques
      }
    case TYPES.STAT_PERIODE_INTERVENTION.GET:
      return {
        ...state, 
        periodeIntervention :  action.statistiques
      }
    default:
      return state
  }
}

export default statistiquesReducer