import { Checkbox } from "@mui/material";
import React from "react";

interface IOptionsCheckbox {
	data: {
		label: string;
		value: string;
	};
	dataBdd: any;
	labelData: string;
	setData: any;
}

function OptionsCheckbox({
	data,
	dataBdd,
	labelData,
	setData,
}: IOptionsCheckbox) {
	const [check, setCheck] = React.useState<boolean | undefined>(false);

	React.useEffect(() => {
		dataBdd?.map((db: any) => {
			data.label === db.nom && setCheck(true);
		});
	}, [data, dataBdd]);

	function handleChange() {
		if (check) {
			Object.assign(setData, {
				[labelData]: setData[labelData].filter(
					(st: any) => st.id !== data.value
				),
			});
		}
		if (!check) {
			Object.assign(setData, {
				[labelData]: [...setData[labelData], { id: data.value }],
			});
		}

		setCheck(!check);
	}

	return (
		<>
			<Checkbox
				// disabled={disabled}
				checked={check}
				onChange={(e) => handleChange()}
				className="item"
			/>
		</>
	);
}

export default OptionsCheckbox;
