import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import TabsPattern from "../components/NavBar/TabsPattern/TabsPattern";
import { lieuxActions } from "../redux/actions/lieuxActions";
import { typeMaterielActions } from "../redux/actions/typeMaterielActions";
import { ILieu } from "../redux/Interfaces/typeLieux";
import { ITypeMateriel } from "../redux/Interfaces/typeTypeMateriel";
import { IRedux } from "../redux/reducers";

function InformationsClient() {
	// GET DATA
	const dispatch = useDispatch();
	const { getLieux } = bindActionCreators(lieuxActions, dispatch);
	const { getTypeMaterielByClient } = bindActionCreators(
		typeMaterielActions,
		dispatch
	);
	const currentClientID = useSelector(
		(state: IRedux) => state?.currentClient.id
	);

	useEffect(() => {
		getLieux(currentClientID);
		getTypeMaterielByClient(currentClientID);
	}, []);

	const lieux: ILieu[] = useSelector(
		(state: IRedux) => state?.lieuxReduxState
	);
	const typeMateriel: ITypeMateriel[] = useSelector(
		(state: IRedux) => state?.typeMaterielReduxState
	);

	const [tabs, setTabs] = useState<any>([]);

	useEffect(() => {
		setTabs([
			{
				index: 1,
				value: 1,
				labelTabs: "Lieux",
				data: lieux,
			},
			{
				index: 2,
				value: 2,
				labelTabs: "Type Materiel",
				data: typeMateriel,
			},
		]);
	}, [lieux, typeMateriel]);

	return (
		<div className="container-main">
			<h1 className="h1-title">Informations sur le client</h1>
			<div className="container-white">
				<TabsPattern tabs={tabs} />
			</div>
		</div>
	);
}
export default InformationsClient;
