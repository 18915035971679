import { Box, Tab, Tabs, Typography, Grid, CardActions, CardContent, Button, Divider } from "@mui/material";
import React from "react";
import SimpleCard from "../../card/SimpleCard";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModaleTypeMateriel from "../../ModaleTypeMateriel";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { typeMaterielActions } from "../../../redux/actions/typeMaterielActions";
import { IRedux } from "../../../redux/reducers";

interface TabsPattern {
	tabs: {
		index: number;
		value: number;
		labelTabs: string;
		data: any;
	}[];
}

function TabsPattern({ tabs }: TabsPattern) {
	const [value, setValue] = React.useState(1);
	const [open, setOpen] = React.useState(false);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};
	
	//Récupération de l'id du client pour la modale
	const currentClientID = useSelector(
		(state: IRedux) => state.currentClient.id
	);

	const dispatch = useDispatch();

	//Méthodes action : Type intervention
	const {
		addTypeMateriel,
		editTypeMateriel,
	} = bindActionCreators(
		typeMaterielActions,
		dispatch
	);
	return (
		<div>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					centered
				>
					{tabs.map((t: any) => (
						<Tab label={t.labelTabs} value={t.value} />
					))}
				</Tabs>
			</Box>
			{tabs ? tabs.map((c: any) => (
				<>
					{c.index === 2 ?
						(value === c.value &&
							<>
								<Grid container style={{ backgroundColor: '#f9f9f9', display: 'flex', justifyContent: 'space-between' }} className="card-grid">
									<Grid xs={8}>
										<CardContent>
											<Typography variant="h6">Matériels</Typography>
										</CardContent>
									</Grid>
									<Grid xs={2}>
										<CardActions>
											<Typography sx={{ pl: 2 }} variant="h6">Actions</Typography>
										</CardActions>
									</Grid>
									<Grid xs={2}>
										<CardActions>
											<Button variant="contained" startIcon={<AddCircleIcon />} size="medium"
												onClick={() => {
													setOpen(true)
												}}>
												AJouter
											</Button>
										</CardActions>
									</Grid>
								</Grid>
								<Divider />
							</>
						)
						: <></>}
					<TabPanel index={value} value={c.value}>
						<SimpleCard data={c.data} idClient={currentClientID}/>
					</TabPanel>
					{/* Modale Add */}
					<ModaleTypeMateriel
						title={"Ajout un matériel"}
						typeMaterielIdSelectionne={0}
						addTypeMateriel={addTypeMateriel}
						editTypeMateriel={editTypeMateriel}
						openModale={open}
						fonctionOpenModale={setOpen}
						idClient={currentClientID}
					/>
				</>
			)) : null}
		</div>
	);
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export default TabsPattern;
