import React from "react";
import { Controller } from "react-hook-form";
import {
	TextField,
	FormControl,
	InputAdornment,
	IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface TextFieldProps {
	name: string;
	control: any;
	label?: string;
	required?: boolean;
	rows?: string;
	multiline?: boolean;
	fullWidth?: boolean;
	error?: boolean;
	helperText?: string;
	type?: string;
}

const TextfieldPattern = ({
	name,
	control,
	label,
	required,
	multiline,
	rows,
	fullWidth,
	error,
	helperText,
	type,
}: TextFieldProps) => {
	const [showPassword, setShowPassword] = React.useState(
		type === "password" ? false : true
	);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	return (
		<FormControl fullWidth={fullWidth}>
			<Controller
				name={name}
				control={control}
				render={({ field: { onChange, value } }) => (
					<TextField
						autoComplete="false"
						type={showPassword ? "text" : "password"}
						required={required}
						onChange={onChange}
						value={value || ""}
						label={label}
						variant="outlined"
						className="item"
						rows={rows}
						multiline={multiline}
						fullWidth={fullWidth}
						error={error}
						helperText={helperText}
						InputProps={{
							endAdornment: (
								<>
									{type === "password" && (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={
													handleClickShowPassword
												}
												onMouseDown={
													handleMouseDownPassword
												}
												edge="end"
											>
												{showPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									)}
								</>
							),
						}}
					/>
				)}
			/>
		</FormControl>
	);
};

export default TextfieldPattern;
