import { Box, Button, Modal, Typography } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import React from "react";
import { useNavigate } from "react-router-dom";


interface IModaleAppointment {
    actionDeconnexion: Function;
    openModale: boolean;
}

const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    p: 4,
};

function ModaleAppointment({
    openModale,
    actionDeconnexion,
}: IModaleAppointment) {
    const navigate = useNavigate();
    return (
        <div>
            <Modal
                open={openModale}
                // onClose={() => fonctionOpenModale(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        component="h2"
                        sx={{ fontWeight: 'bold', textAlign: 'center', mb: '5%' }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <TaskAltIcon color="success" fontSize="large" style={{ marginRight: '10px' }} />
                            <span>Prise de rendez-vous confirmée</span>
                        </div>
                    </Typography>

                    <Typography
                        sx={{ display: 'flex', textAlign: 'justify', m: '2%' }}
                    >Votre rendez-vous a bien été validé. Vous allez recevoir un mail avec votre créneau horraire pour le rendez-vous. 
                    </Typography>
                    <Typography
                        sx={{ display: 'flex', textAlign: 'justify', m: '2%' }}
                    >   En cas d'indisponibilité, vous pouvez à tout moment vous reconnecter sur votre compte pour changer votre 
                        rendez-vous et prendre un nouveau créneau parmi les créneaux disponibles.
                    </Typography>
                    <Box sx={{ textAlign: "center", marginTop: 3 }}>
                        <Button variant="contained" onClick={() => { actionDeconnexion(navigate) }}>
                            Se déconnecter
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default ModaleAppointment;
