import { sr } from "date-fns/locale";
import { TYPES } from "../actions/actionTypes";
import { ICommande, CommandesAction } from "../Interfaces/typeCommandes";

const initialState = {}

const commandesReducer = (state = initialState, action: CommandesAction) => {
  switch (action.type) {
    case TYPES.COMMANDE.GET:
      return { ...state, action }
    default:
      return state
  }
}

export default commandesReducer