import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import allocales from "@fullcalendar/core/locales-all";
import "./PlanningFullCalendar.scss";
import { ICreneau } from "../../redux/Interfaces/typeCreneaux";
import { ITechnicien } from "../../redux/Interfaces/typeTechniciens";
import { calculDuree } from "../../services/CalculDebutFinJournee";
import ModaleConfirmRecap from "../ModalePattern/ModaleConfirmRecap/ModaleConfirmRecap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { crenauxActions } from "../../redux/actions/creneauxActions";
import { useNavigate } from "react-router-dom";
import LiensInternes from "../../services/LiensInternes";
import { IRdv } from "../../redux/Interfaces/typeRdv";
import { rdvAction } from "../../redux/actions/rdvActions";
import tippy from "tippy.js";

const PlanningVisuelPeriodeFullCalendar = ({
	listOfCreneaux,
	periodesInterventionSelectionnee,
	duree,
	deletable,
}: any) => {
	const dayjs = require("dayjs");
	const duration = require("dayjs/plugin/duration");
	dayjs.extend(duration);

	const [events, setEvents] = useState([]);
	const [slotDuration, setSlotDuration] = useState<any>("01:30:00");
	useEffect(() => {
		if (
			periodesInterventionSelectionnee?.typeIntervention?.duree !==
			undefined
		) {
			setSlotDuration(
				calculDuree(
					periodesInterventionSelectionnee?.typeIntervention?.duree
				)
			);
		}
		if (duree !== undefined) {
			setSlotDuration(calculDuree(duree));
		}
	}, [periodesInterventionSelectionnee, duree]);

	//CALCUL PLAGE HORAIRE
	const [plageHoraire, setPlageHoraire] = useState<any>({
		heureDebut: "06:00",
		heureFin: "18:00",
	});

	const [listOfCreneauxTech, setListOfCreneauxTech] = React.useState<
		ICreneau[]
	>([]);
	const [listOfRdv, setListOfrdv] = React.useState<IRdv[]>([]);

	useEffect(() => {
		periodesInterventionSelectionnee !== undefined &&
			setListOfCreneauxTech(
				listOfCreneaux?.filter(
					(creneau: ICreneau) => creneau.techniciens.length > 0
				)
			);
		duree !== undefined && setListOfrdv(listOfCreneaux);
	}, [listOfCreneaux]);

	const renderEventContent = (eventContent: any) => {
		const rdvValide = listOfRdv.filter((rdv: any) => rdv.valider === true);
		const rdvAbsent = listOfRdv.filter((rdv: any) => rdv.absenceClient === true);

		eventContent.backgroundColor = "hsl(18.75, 100%, 68.63%)";
		eventContent.borderColor = "hsl(18.75, 100%, 68.63%)";

		rdvValide.map((rdv: any) => {
			if (parseInt(rdv.id) === parseInt(eventContent.event._def.extendedProps.idRdv)) {
				eventContent.backgroundColor = "#5cb85c";
				eventContent.borderColor = "#5cb85c";
			}
		});

		rdvAbsent.map((rdv: any) => {
			if (parseInt(rdv.id) === parseInt(eventContent.event._def.extendedProps.idRdv)) {
				eventContent.backgroundColor = "#d9534f";
				eventContent.borderColor = "#d9534f";
			}
		});

		return (
			<b>
				{eventContent?.timeText}h :{" "}
				{(eventContent?.event._def.title).toLowerCase()}
			</b>
		);
	};

	const calendarRef = useRef<FullCalendar>(null!);
	let calendarAPI: any;

	useEffect(() => {
		// if (!showPlanning) {
		calendarAPI = calendarRef?.current?.getApi();
		// }
	}, [events]);

	let initialEvents;
	if (periodesInterventionSelectionnee !== undefined) {
		initialEvents = listOfCreneauxTech?.map((creneauReserve: any) => {
			let title = "";
			creneauReserve?.techniciens?.map((tech: ITechnicien) => {
				title = title + tech.nom + " " + tech.prenom + " ";
			});
			return {
				title: title,
				start: creneauReserve.dateDebut,
				end: creneauReserve.dateFin,
				idCreneau: creneauReserve.id.toString(),
			};
		});
	}
	if (duree !== undefined) {
		initialEvents = listOfRdv?.map((rdv: any) => {
			let title =
				rdv.creneauHoraire.lieu.nom +
				" " +
				rdv.userClient.nom.toUpperCase() +
				" " +
				rdv.userClient.prenom.toUpperCase();
			return {
				title: title,
				start: rdv.creneauHoraire.dateDebut,
				end: rdv.creneauHoraire.dateFin,
				idRdv: rdv.id.toString(),
			};
		});
	}

	//FONCTIONS MODALE
	const [open, setOpen] = React.useState(false);
	const [data, setData] = React.useState({});
	const dispatch = useDispatch();
	const { deleteTechnicien } = bindActionCreators(crenauxActions, dispatch);
	const { rdvHonorer, validerAbsenceClient } = bindActionCreators(rdvAction, dispatch);

	//ON CLICK
	function handleEventClick(clickInfo: any) {
		if (periodesInterventionSelectionnee !== undefined) {
			setData(parseInt(clickInfo.event._def.extendedProps.idCreneau));
			setOpen(true);
		}
		if (duree !== undefined) {
			const rdv = listOfRdv.filter(
				(rdv: any) => rdv.id == clickInfo.event._def.extendedProps.idRdv
			);
			if (rdv[0].valider !== true && rdv[0].absenceClient !== true) {
				setData(rdv)
				setOpen(true);
			}
		}
	}

	//BOUTON ADD TECH
	const navigate = useNavigate();

	//TOOLTIP
	const tooltip = (info: any) => {
		const index = listOfCreneaux.findIndex(
			(creneau:any) => parseInt(creneau.id) === parseInt(info.event._def.extendedProps.idRdv)
		  );

		if (duree !== undefined) {
			let content =
				listOfCreneaux.length > 0
					? " <p>" +
					listOfCreneaux[index]?.creneauHoraire?.lieu?.nom +
					"</p>" +
					"<p>" +
					listOfCreneaux[index]?.creneauHoraire?.lieu?.adresse +
					"</p>"
					: "";
			tippy(info.el, {
				allowHTML: true,
				interactive: true,
				content: content,
				trigger: "mouseenter",
			});
		}
	}


	return (
		<div
			style={{
				background: "white",
				margin: "20px",
				padding: "40px",
				borderRadius: "20px",
				width: "100%",
			}}
		>
			<FullCalendar
				ref={calendarRef}
				plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
				headerToolbar={{
					left:
						deletable &&
						periodesInterventionSelectionnee &&
						"addTech",
					center: "title",
					right: "prev,next",
				}}
				customButtons={{
					addTech: {
						text: "Ajouter des techniciens",
						click: function () {
							navigate(
								LiensInternes.ADMIN.BASE +
								LiensInternes.ADMIN.ASSIGNATION_TECH +
								"/" +
								periodesInterventionSelectionnee.id
							);
						},
					},
				}}
				timeZone={"local"}
				locales={allocales}
				locale={"FR"}
				height={
					periodesInterventionSelectionnee !== undefined ? 700 : 1000
				}
				expandRows={true}
				buttonText={{
					day: "Jour",
					prev: "Précédent",
					next: "Suivant",
					month: "Mois",
					week: "Semaine",
				}}
				initialView="timeGridWeek"
				validRange={() => {
					let currentDate = new Date();
					return {
						start: new Date(
							periodesInterventionSelectionnee?.dateDebut
						),
						end: new Date(
							periodesInterventionSelectionnee?.dateFin
						),
					};
				}}
				selectable={false}
				editable={false}
				dayHeaderFormat={{
					week: "long",
					day: "numeric",
					month: "long",
				}}
				weekends={false}
				eventOverlap={false}
				selectOverlap={false}
				allDaySlot={false}
				slotDuration={slotDuration}
				slotMinTime={plageHoraire.heureDebut}
				slotMaxTime={plageHoraire.heureFin}
				events={initialEvents}
				eventContent={renderEventContent}
				eventClick={deletable && handleEventClick}
				eventDidMount={tooltip}
			/>

			{open && (
				<ModaleConfirmRecap
					title={
						periodesInterventionSelectionnee
							? "Supprimer ce créneau ?"
							: "Statut du rendez-vous"
					}
					data={data}
					actionValider={periodesInterventionSelectionnee ? deleteTechnicien : rdvHonorer}
					openModale={open}
					fonctionOpenModale={setOpen}
					actionAbsenceClient={validerAbsenceClient}
				/>
			)}
		</div>
	);
};

export default PlanningVisuelPeriodeFullCalendar;
