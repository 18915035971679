import { Client } from "../Interfaces/typeClient";
import { CurrentClientAction, CurrentClientDispatch } from "../Interfaces/typeCurrentClient";
import { TYPES } from "./actionTypes";

const addCurrentClient = (client: Client) => {
  return (dispatch: CurrentClientDispatch) => {
    const action: CurrentClientAction = {
      type: TYPES.CURRENT_CLIENT.ADD,
      client: client,
    }
    //DISPATCH ACTION
    dispatch(action)
  }
};

export const currentClientAction = {
  addCurrentClient
} 