import {
	AppBar,
	Avatar,
	Divider,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Toolbar,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { loggedActions } from "../../../redux/actions/loggedActions";
import { IRedux } from "../../../redux/reducers";
import { navigateCustom } from "../../../services/Navigate";
import MenuIcon from "@mui/icons-material/Menu";
import Logout from "@mui/icons-material/Logout";
import LiensInternes from "../../../services/LiensInternes";
import Logo from "../../../style/img/logo-reel-it.png";

const drawerWidth = 280;

function NavBarHeader() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { deconnexion } = bindActionCreators(loggedActions, dispatch);

	const role: string[] = useSelector(
		(state: IRedux) => state?.userReduxState.role
	);
	const [roleState, setRoleState] = useState<string>();
	useEffect(() => {
		setRoleState(role ? role[0] : "");
	}, [role]);

	const userInfo = useSelector((state: IRedux) => state?.userReduxState);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [mobileOpen, setMobileOpen] = useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	return (
		<AppBar
			position="fixed"
			sx={{
				width: {
					sm:
						roleState === "AdminComNetWork" ||
						roleState === "ComNetWorkSupervisor" ||
						roleState === "ClientSupervisor"
							? `calc(100% - ${drawerWidth}px)`
							: "100%",
				},
				ml: { sm: `${drawerWidth}px` },
				background: "lightgray",
				// display: role === "AdminComNetWork" && "flex",
				// justifyContent: role === "AdminComNetWork" && "center"
			}}
		>
			<Toolbar>
				{/* MOBILE VERSION */}
				{roleState === "AdminComNetWork" ||
					(roleState === "ComNetWorkSupervisor" && (
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{
								mr: 2,
								display: { sm: "none" },
								justifyContent: "center",
							}}
						>
							<MenuIcon />
						</IconButton>
					))}
				{/* DESKTOP VERSION */}
				<Typography
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						width: "100%",
						mx:
							!role?.includes("AdminComNetWork") ||
							!role?.includes("ComNetWorkSupervisor")
								? "auto"
								: "0",
						cursor: "pointer",
					}}
				>
					<img
						src={Logo}
						width={170}
						onClick={() => navigateCustom(role, navigate)}
					/>

					<Tooltip title="Paramètres de compte">
						<IconButton
							onClick={handleClick}
							size="small"
							sx={{ ml: 2 }}
							aria-controls={open ? "account-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={open ? "true" : undefined}
						>
							<Avatar
								sx={{
									width: 40,
									height: 40,
									fontSize: "17px",
								}}
							>
								{(userInfo?.prenom).charAt(0)}
								{(userInfo?.nom).charAt(0)}
							</Avatar>
						</IconButton>
					</Tooltip>
				</Typography>
			</Toolbar>

			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: "visible",
						filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
						mt: 1.5,
						"& .MuiAvatar-root": {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
						},
						"&:before": {
							content: '""',
							display: "block",
							position: "absolute",
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: "background.paper",
							transform: "translateY(-50%) rotate(45deg)",
							zIndex: 0,
						},
					},
				}}
				transformOrigin={{
					horizontal: "right",
					vertical: "top",
				}}
				anchorOrigin={{
					horizontal: "right",
					vertical: "bottom",
				}}
			>
				<NavLink
					to={LiensInternes.PROFIL}
					className={({ isActive }) =>
						["link_nav", isActive ? "activeMenuHaut" : null]
							.filter(Boolean)
							.join(" ")
					}
				>
					<MenuItem>
						<Avatar /> Mon profil
					</MenuItem>
				</NavLink>
				<Divider />
				<MenuItem
					onClick={() => {
						deconnexion(navigate);
					}}
				>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					Se déconnecter
				</MenuItem>
			</Menu>
		</AppBar>
	);
}

export default NavBarHeader;
