import { Box, Divider, Drawer, List, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InsertChartIcon from "@mui/icons-material/InsertChartOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import InfoIcon from "@mui/icons-material/Info";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { useSelector } from "react-redux";
import { IRedux } from "../../../redux/reducers";
import "./NavBar.scss";
import LiensInternes from "../../../services/LiensInternes";
import ListItemCustom from "./ListItemCustom";
import NavBarClient from "../NavBarClient/NavBarClient";
import { ListItemNavBar } from "../../../model/ListItemNavBar";
import BuildIcon from '@mui/icons-material/Build';

export default function NavBarTableauDeBord() {
	const drawerWidth = 280;

	const [mobileOpen, setMobileOpen] = useState(false);

	const role: string[] = useSelector(
		(state: IRedux) => state?.userReduxState.role
	);
	const [roleState, setRoleState] = useState<string>();
	useEffect(() => {
		setRoleState(role ? role[0] : "");
	}, [role]);
	const userInfo = useSelector((state: IRedux) => state?.userReduxState);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const currentClient = useSelector((state: IRedux) => state?.currentClient);

	const listeItems: ListItemNavBar[] = [
		{
			url:
				LiensInternes.ADMIN.BASE +
				LiensInternes.ADMIN.INFORMATIONS_CLIENT,
			nom: "Informations client",
			logo: <InfoIcon />,
			roleNav: "superviseur",
		},
		{
			url: LiensInternes.ADMIN.BASE + LiensInternes.ADMIN.IMPORT_FICHIER,
			nom: "Import d'un fichier client",
			logo: <FileUploadIcon />,
			roleNav: "superviseur",
		},
		{
			url:
				LiensInternes.ADMIN.BASE +
				LiensInternes.ADMIN.TYPES_INTER,
			nom: "Ajouter / Modifier un type d'intervention",
			logo: <BuildIcon />,
			roleNav: "superviseur",
		},
		{
			url: LiensInternes.ADMIN.BASE + LiensInternes.ADMIN.CREATION_RDV,
			nom: "Création d'une période d'intervention",
			logo: <CalendarMonthIcon />,
			roleNav: "superviseur",
		},
		{
			url:
				LiensInternes.ADMIN.BASE +
				LiensInternes.ADMIN.ASSIGNATION_TECH +
				"/0",
			nom: "Assignation des techniciens à un planning",
			logo: <PushPinIcon />,
			roleNav: "superviseur",
		},
		{
			url:
				LiensInternes.ADMIN.BASE +
				LiensInternes.ADMIN.VALIDATION_PERIODE_INTER,
			nom: "Valider les périodes d'intervention",
			logo: <ListAltIcon />,
			roleNav: "superviseur",
		},
		{
			url:
				LiensInternes.ADMIN.BASE +
				LiensInternes.ADMIN.RECHERCHER_USER_CLIENT,
			nom: "Rerchercher les utilisateurs clients",
			logo: <PeopleAltIcon />,
			roleNav: "superviseur",
		},
		{
			url: LiensInternes.ADMIN.BASE + LiensInternes.ADMIN.LISTE_CLIENT,
			nom: "Liste des clients",
			logo: <ListAltIcon />,
			roleNav: "admin",
		},
		{
			url: LiensInternes.ADMIN.BASE + LiensInternes.ADMIN.USER_COM,
			nom: "User ComnetWork",
			logo: <ListAltIcon />,
			roleNav: "admin",
		},
		{
			url: LiensInternes.ADMIN.BASE + LiensInternes.ADMIN.TECHNICIEN,
			nom: "Techniciens",
			logo: <ListAltIcon />,
			roleNav: "admin",
		},
		{
			url:
				LiensInternes.SUPERVISEUR_CLIENT.BASE +
				LiensInternes.SUPERVISEUR_CLIENT.RDV_LIEU,
			nom: "Rendez-vous par lieu",
			logo: <CalendarMonthIcon />,
			roleNav: "superviseurClient",
		},
		{
			url:
				LiensInternes.SUPERVISEUR_CLIENT.BASE +
				LiensInternes.SUPERVISEUR_CLIENT.CSV_ADD_LIEU,
			nom: "Import lieux par csv",
			logo: <FileUploadIcon />,
			roleNav: "superviseurClient",
		},
	];

	const drawer = (
		<div style={{ background: "rgba(255, 255, 255, 0.04)" }}>
			<Toolbar />
			<Divider />
			<h4 style={{ textAlign: "center" }}>
				{userInfo?.prenom} {userInfo?.nom}
			</h4>
			<h5
				style={{
					textAlign: "center",
					paddingLeft: "10px",
					paddingRight: "10px",
				}}
			>
				Connecté(e) en tant que - {roleState}
			</h5>
			<Divider />
			{roleState !== "ClientSupervisor" && <NavBarClient />}
			<Divider />
			<List>
				{currentClient.id !== 0 && (
					<>
						{/* DASHBOARD SUPERVISEUR COM + ADMIN */}
						{(role.includes("ComNetWorkSupervisor") ||
							role.includes("AdminComNetWork")) && (
							<ListItemCustom
								url={
									LiensInternes.ADMIN.BASE +
									LiensInternes.ADMIN.TABLEAU_DE_BORD
								}
								nom="Tableau de bord"
								logo={<InsertChartIcon />}
								roleNav="superviseur"
							/>
						)}

						<Typography className={"typography-nav-title"}>
							{(role.includes("ComNetWorkSupervisor") ||
								role.includes("AdminComNetWork")) &&
								"Superviseur Client"}
						</Typography>

						{/* DASHBOARD SUPERVISEUR CLIENT */}
						{role.includes("ClientSupervisor") && (
							<ListItemCustom
								url={
									LiensInternes.SUPERVISEUR_CLIENT.BASE +
									LiensInternes.SUPERVISEUR_CLIENT
										.TABLEAU_DE_BORD
								}
								nom="Tableau de bord"
								logo={<InsertChartIcon />}
								roleNav="superviseurClient"
							/>
						)}

						{listeItems.map((list: ListItemNavBar, key: number) => {
							if (
								(role.includes("ClientSupervisor") ||
									role.includes("AdminComNetWork") ||
									role.includes("ComNetWorkSupervisor")) &&
								list.roleNav === "superviseurClient"
							) {
								return (
									<ListItemCustom
										key={key}
										url={list.url}
										nom={list.nom}
										logo={list.logo}
										roleNav={list.roleNav}
									/>
								);
							}
						})}
						<Typography className={"typography-nav-title"}>
							{(role.includes("AdminComNetWork") ||
								role.includes("ComNetWorkSupervisor")) &&
								"Superviseur Com"}
						</Typography>

						{listeItems.map((list: ListItemNavBar, key: number) => {
							if (
								(role.includes("ComNetWorkSupervisor") ||
									role.includes("AdminComNetWork")) &&
								list.roleNav === "superviseur"
							) {
								return (
									<ListItemCustom
										key={key}
										url={list.url}
										nom={list.nom}
										logo={list.logo}
										roleNav={list.roleNav}
									/>
								);
							}
						})}
					</>
				)}

				<Typography className={"typography-nav-title"}>
					{role.includes("AdminComNetWork") && "Admin"}
				</Typography>
				{listeItems.map((list: ListItemNavBar, key: number) => {
					if (
						role.includes("AdminComNetWork") &&
						list.roleNav === "admin"
					) {
						return (
							<ListItemCustom
								key={key}
								url={list.url}
								nom={list.nom}
								logo={list.logo}
								roleNav={list.roleNav}
							/>
						);
					}
				})}
			</List>
		</div>
	);

	return (
		<Box
			component="nav"
			sx={{
				width: { sm: drawerWidth },
				flexShrink: { sm: 0 },
			}}
			aria-label="mailbox folders"
		>
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Drawer
				variant="temporary"
				open={mobileOpen}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: "block", sm: "none" },
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						width: drawerWidth,
					},
				}}
			>
				{drawer}
			</Drawer>
			<Drawer
				variant="permanent"
				sx={{
					display: { xs: "none", sm: "block" },
					"& .MuiDrawer-paper": {
						boxSizing: "border-box",
						width: drawerWidth,
					},
				}}
				open
			>
				{drawer}
			</Drawer>
		</Box>
	);
}
