import axios from "axios";
import { TYPES } from "../../redux/actions/actionTypes";
import { store } from "../../redux/store";
import { refreshToken } from "./refreshFunction";
let baseUrl = process.env.REACT_APP_URL_BACK;
let frontUrl = process.env.REACT_APP_URL_FRONT;

const CustomAxios = axios.create({});

CustomAxios.interceptors.request.use(
	(request) => {
		let token = localStorage.getItem("accessToken");
		request.headers = {
			Authorization: `Bearer ${token}`,
		};
		const actionLoading = {
			type: TYPES.LOADING,
			loading: true,
		};
		store.dispatch(actionLoading);
		return request;
	},
	(error) => {
		return Promise.reject(error);
	}
);

CustomAxios.interceptors.response.use(
	(response) => {
		const actionLoading = {
			type: TYPES.LOADING,
			loading: false,
		};
		store.dispatch(actionLoading);
		//SI C'EST OK
		if (
			(response.config.method === "post" ||
				response.config.method === "put") &&
			response.data.statusCode !== 403
		) {
			const errorDispatch = {
				error: "Vos données ont bien été enregistrées",
				status: response.status,
			};
			const actionError = {
				type: TYPES.ERROR.SET,
				error: errorDispatch,
			};
			store.dispatch(actionError);
		}
		return response;
	},
	(error) => {
		const originalRequest = error.config;
		const status = error.response ? error.response.status : null;
		if (localStorage.getItem("enCours") === "true") {
			setTimeout(function () {
				CustomAxios(originalRequest);
			}, 1000);
			return;
		}

		if (
			status === 422 &&
			(localStorage.getItem("refreshToken") !== undefined || null)
		) {
			const uuid = localStorage.getItem("refreshToken");
			localStorage.setItem("enCours", "true");
			//APPEL SUR LA ROUTE DU REFRESH
			return refreshToken(uuid, originalRequest, CustomAxios);
		}
		if (status === 400) {
			window.location.href = frontUrl;
		}
		if (status === 409) {
			const errorDispatch = {
				error: "Une erreur est survenue",
				status: status,
			};
			const actionError = {
				type: TYPES.ERROR.SET,
				error: errorDispatch,
			};
			store.dispatch(actionError);
		}
		return Promise.resolve(error);
	}
);

export default CustomAxios;
