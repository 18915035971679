import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./Dashboard.scss";
import InterventionsByType from "../components/dashboard/InterventionsByType";
import InterventionsByDate from "../components/dashboard/InterventionsByDate";
import { useTranslation } from "react-i18next";
import "../services/traduction/i18n";
import { IRedux } from "../redux/reducers";
import { bindActionCreators } from "redux";
import { satistiquesAction } from "../redux/actions/StatistiquesActions";
import CardDashboard from "../components/dashboard/CardDashboard";
import LineChartNbRdvPrisParJour from "../components/dashboard/LineChartNbRdvPrisParJour";
import LiensInternes from "../services/LiensInternes";

const Dashboard = () => {
	const { t } = useTranslation();

	const stat = useSelector(
		(state: IRedux) => state.statistiquesState.tableaudeBord
	);
	const [display, setDisplay] = React.useState<boolean>(false);
	useEffect(() => {
		if (stat) {
			if (Object.keys(stat).length !== 0) {
				setDisplay(true);
			}
		}
	}, [stat]);

	const navigate = useNavigate();

	const loggedReduxState = useSelector(
		(state: any) => state.loggedReduxState
	);
	const currentClient = useSelector((state: IRedux) => state.currentClient);
	const role: string[] = useSelector(
		(state: IRedux) => state.userReduxState.role
	);

	useEffect(() => {
		if (loggedReduxState === null) {
			navigate("/");
		}
		if (currentClient.id === 0) {
			navigate(
				LiensInternes.ADMIN.BASE + LiensInternes.ADMIN.CHOIX_CLIENT
			);
		}
	}, []);

	//GET STAT
	const dispatch = useDispatch();
	const { getStatistiques } = bindActionCreators(satistiquesAction, dispatch);

	useEffect(() => {
		if (currentClient.id !== 0) {
			getStatistiques(currentClient.id);
		}
	}, [currentClient]);

	return (
		<>
			{display && (
				<div className="page-container">
					<h1 className="h1-title">
						{t("DASHBOARD.title")} {currentClient.nom}
					</h1>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "10px",
						}}
					>
						<div style={{ display: "flex", gap: "10px" }}>
							<div style={{ flex: 1 }}>
								<CardDashboard
									title="Pourcentage de comptes activés"
									data={
										Math.round(
											stat.pourcentageClientActive
										) + "%"
									}
									couleur="#60CCEC"
								/>
							</div>
							<div style={{ flex: 1 }}>
								<CardDashboard
									title="Rendez-vous prévus aujourd'hui"
									data={stat.nbRdvToday?.toString()}
									couleur="#A1C740"
								/>
							</div>
							<div style={{ flex: 1 }}>
								<CardDashboard
									title="Rendez-vous honorés aujourd'hui"
									data={stat.nbRdvHonoreToday?.toString()}
									couleur="#E75C38"
								/>
							</div>
							<div style={{ flex: 1 }}>
								<CardDashboard
									title="Nombre de rdv libres"
									data={stat?.creneauxLibres?.toString()}
									couleur="#FFCA08"
								/>
							</div>
						</div>
						<div style={{ display: "flex", gap: "10px" }}>
							<div style={{ flex: 2 }}>
								{role.includes("ComNetWorkSupervisor") ||
								role.includes("AdminComNetWork") ? (
									<LineChartNbRdvPrisParJour />
								) : (
									<InterventionsByDate />
								)}
							</div>
							<div style={{ flex: 1 }}>
								<InterventionsByType />
							</div>
						</div>
						{(role.includes("ComNetWorkSupervisor") ||
							role.includes("AdminComNetWork")) && (
							<InterventionsByDate />
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Dashboard;
